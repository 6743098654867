// @ts-check

/** @typedef {(entryId: number) => void} PonylogEntryDeleteButtonDeletedCallback */

/**
 * @typedef {Object} PonylogEntryDeleteButtonProps
 * @prop {number} ponylogEntryId
 * @prop {PonylogEntryDeleteButtonDeletedCallback} onDelete
 * @prop {string} additionalButtonStyles
 * @prop {boolean} [disabled = false]
 */

import React from "react";
import { ActionButton, useApi } from "react-base";
import { ponylogEntryDeleteRequest } from "../api";

/**
 * 
 * @param {PonylogEntryDeleteButtonProps} param0 
 * @returns 
 */
export default function PonylogDeleteButton({
    ponylogEntryId,
    onDelete,
    additionalButtonStyles,
    disabled = false,
}) {
    const fetchFromApi = useApi();

    /**
     * 
     * @param {number} id 
     * @param {import("react-base/dist/components/action-button").ActionButtonClickHandlerCallback} callback 
     */
    async function clickHandler(id, callback) {
        const response = await fetchFromApi(ponylogEntryDeleteRequest(id));
        if (response.isOk) {
            onDelete(id);
        }
        callback();
    }

    return (
        <div className="control">
            <ActionButton
                additionalButtonStyles={additionalButtonStyles}
                onClick={(callback) => clickHandler(ponylogEntryId, callback)}
                useConfirmationModal
                confirmLabel="Delete"
                confirmButtonClassName="is-danger"
                disabled={disabled}
            >Delete</ActionButton>
        </div>
    );
}