// @ts-check

import React from "react";
import { ActionButton, useApi } from "react-base";
import toast from "react-hot-toast";

/**
 * @template FEM_READ
 * @callback ApiButtonSuccessHandler
 * @param {FEM_READ} femEntity
 * @returns {Promise<void> | void}
 */

/**
 * @template FEM_WRITE
 * @callback ApiButtonRequestFunction
 * @param {FEM_WRITE} femEntity
 * @return {Request}
 */

/**
 * @template FEM_READ, FEM_WRITE
 * @typedef {Object} ApiButtonProps
 * @prop {ApiButtonRequestFunction<FEM_WRITE>} request
 * @prop {string} label
 * @prop {string} [additionalButtonStyles]
 * @prop {ApiButtonSuccessHandler<FEM_READ>} [onSuccess]
 * @prop {FEM_WRITE} femEntity
 * @prop {boolean} [useConfirmationModal=false]
 */

/**
 * 
 * @template FEM_READ, FEM_WRITE
 * @param {ApiButtonProps<FEM_READ, FEM_WRITE>} param0 
 * @returns {React.JSX.Element}
 */
export default function ApiButton({
    label,
    additionalButtonStyles,
    request,
    onSuccess,
    femEntity,
    useConfirmationModal,
}) {
    const fetchFromApi = useApi();

    /**
     * 
     * @param {FEM_WRITE} femEntity 
     * @param {import("react-base/dist/components/action-button").ActionButtonClickHandlerCallback} callback 
     */
    async function handleClick(femEntity, callback) {
        const response = await fetchFromApi(request(femEntity));
        if (response.isOk) {
            if (onSuccess) {
                if (response.data) {
                    if (response.data.entity) {
                        onSuccess(response.data.entity);
                    } else if (response.data.collection) {
                        onSuccess(response.data.collection);
                    }
                } else {
                    // @ts-ignore
                    onSuccess(femEntity);
                }
            }
        } else {
            toast.error(
                <>
                    <ul>
                        {(response.data?.errors ?? []).map((/** @type {string} */ error) => (
                            <li>{error}</li>
                        ))}
                    </ul>
                </>
            );
        }
        callback();
    }

    return (
        <div className="control">
            <ActionButton
                additionalButtonStyles={additionalButtonStyles}
                onClick={(callback) => handleClick(femEntity, callback)}
                useConfirmationModal={useConfirmationModal}
            >{label}</ActionButton>
        </div>
    )
}