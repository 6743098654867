// @ts-check

import React, { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import EquineEditButton from "../components/equine-edit-button";
import paths from "../paths";
import EquineDeleteButton from "../components/equine-delete-button";
import RelationshipsList from "../components/relationships-list";
import EquineUndeleteButton from "../components/equine-undelete-button";
import {
    serviceEquineRelationshipCreateRequest,
    serviceEquineRelationshipDeleteRequest,
    serviceProviderEquineRelationshipCreateRequest,
    serviceProviderEquineRelationshipDeleteRequest,
    serviceProviderEquineRelationshipRequestRequest } from "../api";
import { path } from "react-base";
import ServiceProviderEquineRelationshipRequestForm from "../components/service-provider-equine-relationship-request-form";
import { addToEntityListState, dateToString } from "../helpers/others";
import TableWithButtons from "../components/general-purpose/table-with-buttons";
import LinkWithModal from "../components/general-purpose/link-with-modal";
import TextContent from "../components/general-purpose/text-content";
import ApiButtonWithModal from "../components/general-purpose/api-button-with-modal";
import ApiButton from "../components/general-purpose/api-button";
import Property from "../components/general-purpose/property";
import ServiceProviderConnectionEquineRelationshipForm from "../components/service-provider-connection-equine-relationship-form";

/** 
 * @typedef {Object} EquineProfileProps
 */

export default function EquineProfile() {
    const navigate = useNavigate();
    const apiResponse = /** @type {import("../router").EquineProfileLoaderData} */ (useLoaderData());
    const {entity, other_data: otherData} = apiResponse;
    const [equine, setEquine] = useState(/** @type {import("../api").FemEquineRead} */ (entity));
    const [serviceProviderRelationshipRequests, setServiceProviderRelationshipRequests] = useState(
        /** @type {Array<import("../api").FemServiceProviderEquineRelationshipRead>} */
        (otherData.service_provider_equine_relationship_requests ?? [])
    );
    const [serviceProviders, setServiceProviders] = useState(
        /** @type {Array<import("../api").FemServiceProviderEquineRelationshipRead>} */ (otherData.service_providers)
    );
    const [pastServiceProviders, setPastServiceProviders] = useState(
        /** @type {Array<import("../api").FemServiceProviderEquineRelationshipRead>} */
        (otherData.past_service_providers)
    );
    const [currentServices, setCurrentServices] = useState(
        /** @type {Array<import("../api").FemServiceEquineRelationshipRead>} */ (otherData.current_services)
    );
    const [availableServices, setAvailableServices] = useState(
        /** @type {Array<import("../api").FemServiceRead>} */ (otherData.available_services)
    );
    const [pendingServices, setPendingServices] = useState(
        /** @type {Array<import("../api").FemServiceEquineRelationshipRead>} */ (otherData.pending_service_requests)
    );
    const [pastServices, setPastServices] = useState(
        /** @type {Array<import("../api").FemServiceEquineRelationshipRead>} */ (otherData.past_services)
    );

    const name = (equine.nickname ? equine.nickname : (equine.name ? equine.name : equine.usef_name));
    const {available_roles: allRelationships} = otherData;

    /**
     * 
     * @param {Array<import("../api").FemPersonEquineRelationshipForRoleAssignmentRead>} updatedRelationships 
     */
    function handleUserRelationshipsChange(updatedRelationships) {
        const currentUserRelationship =
            updatedRelationships.find((rel) => rel.person.is_current_user);
        if (currentUserRelationship === undefined) {
            navigate(paths.equines.all, {replace: true});
        } else {
            console.log("ping")
            setEquine((equine) => ({
                ...equine,
                person_relationships: updatedRelationships,
                current_user_roles: currentUserRelationship.roles,
            }));
        }
    }

    /**
     * 
     * @param {boolean} isDeleted 
     * @returns {void}
     */
    function setDeletedStatus(isDeleted) {
        setEquine((oldVal) => ({
            ...oldVal,
            is_deleted: isDeleted,
        }));
    }

    return (
        <>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">{name}{equine.is_deleted ? <>&nbsp;<span className="is-size-6">(deleted)</span></> : ""}</h1>
                    </div>
                </div>
                <div className="level-right">
                    <div className="field has-addons">
                        <div className="control">
                            {/* <ActionButton>Edit</ActionButton> */}
                            <EquineEditButton
                                allRelationships={allRelationships}
                                equine={equine}
                                setEquine={setEquine}
                            />
                        </div>
                        <div className="control">
                            {equine.is_deleted
                            ? <EquineUndeleteButton equineId={equine.id} undeletedCallback={() => setDeletedStatus(false)} />
                            : <EquineDeleteButton equineId={equine.id} deletedCallback={() => setDeletedStatus(true)} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="box">
                <Property label="Barn Name" value={equine.nickname ?? ""} />
                {/* <Property label="Name" value={equine.name} /> */}
                <Property label="Show Name" value={equine.usef_name} />
                <Property label="Born" value={equine.birth_year.toString()} />
                <Property label="USEF Number" value={equine.usef_number} />
                <Property label="USHJA Number" value={equine.ushja_number} />
                <Property label="FEI Id" value={equine.fei_id} />
                <Property label="Microchip Number" value={equine.microchip_number} />
                <Property label="Insurance Carrier" value={equine.insurance_carrier} />
                <Property label="Insurance Policy No" value={equine.insurance_policy_number} />
                <Property label="Allergies" value={equine.allergies} />
                <Property label="Last shoeing date"
                    value={equine.last_shoeing_date ? dateToString(equine.last_shoeing_date) : undefined} />
            </div>

            <div className="box">
                <h2 className="subtitle is-5 mb-0">Related People</h2>
                <RelationshipsList
                    personEquineRelationships={equine.person_relationships}
                    allRelationships={allRelationships}
                    equineId={equine.id}
                    isEquineDeleted={equine.is_deleted}
                    onPersonRelationshipsChange={handleUserRelationshipsChange}
                />
            </div>

            {pendingServices.length > 0
                ?
                    <div className="box">
                        <TableWithButtons
                            entities={pendingServices}
                            setEntities={setPendingServices}
                            emptyListText="No pending service requests."
                            title={<h2 className="subtitle is-5 mb-0">Pending Service Requests</h2>}
                            tableHeaderElements={
                                <>
                                    <th>Name</th>
                                    <th>Service Provider</th>
                                </>
                            }
                            oneRowTableDataElements={(serviceRelationship) => (
                                <>
                                    <td>{serviceRelationship.service.name}</td>
                                    <td>{serviceRelationship.service.service_provider.name}</td>
                                </>
                            )}
                            deleteButton={{
                                deleteRequest: serviceEquineRelationshipDeleteRequest,
                                label: "Retract",
                                onDelete: (entity) => {
                                    addToEntityListState(
                                        entity.service,
                                        setAvailableServices,
                                        {side: "front", allowDuplicates: (a,b) => a.id === b.id});
                                    addToEntityListState(entity, setPastServices, {side: "front"})
                                },
                            }}
                        /> 
                    </div>
                : <></>
            }

            <div className="box">
                <TableWithButtons
                    entities={currentServices}
                    setEntities={setCurrentServices}
                    emptyListText="No services are associated with this horse."
                    title={<h2 className="subtitle is-5 mb-0">Current Services</h2>}
                    tableHeaderElements={
                        <>
                            <th>Name</th>
                            <th>Service Provider</th>
                            <th>Since</th>
                        </>
                    }
                    oneRowTableDataElements={(serviceRelationship) => (
                        <>
                            <td>{serviceRelationship.service.name}</td>
                            <td>{serviceRelationship.service.service_provider.name}</td>
                            <td>{serviceRelationship.startedAt ? dateToString(serviceRelationship.startedAt) : "?"}</td>
                        </>
                    )}
                    deleteButton={{
                        label: "End",
                        deleteRequest: serviceEquineRelationshipDeleteRequest,
                        onDelete: (entity) =>
                            addToEntityListState(
                                /** @type {import("../api").FemServiceEquineRelationshipRead} */ ({
                                    ...entity,
                                    endedAt: new Date().getTime(),
                                }),
                                setPastServices, {side: "front", limit: 10}),
                    }} 
                />
            </div>

            <div className="box">
                <TableWithButtons
                    entities={availableServices}
                    setEntities={setAvailableServices}
                    emptyListText={"No services for this horse or all available services are already requested."}
                    title={<h2 className="subtitle is-5 mb-0">Available Services</h2>}
                    tableHeaderElements={
                        <>
                            <th>Name</th>
                            <th>Service Provider</th>
                            <th>Description</th>
                        </>
                    }
                    oneRowTableDataElements={(service) => (
                        <>
                            <td>{service.name}</td>
                            <td>{service.service_provider.name}</td>
                            <td>{service.description}</td>
                        </>
                    )}
                    rowButtons={[
                        {
                            type: "delete",
                            elementFn: ({entity, onClickHandler, additionalButtonStyles}) => (
                                <ApiButton
                                    additionalButtonStyles={additionalButtonStyles}
                                    femEntity={{ service_id: entity.id, equine_id: equine.id, }}
                                    label={entity.service_provider.is_connection ? "Add" : "Request"}
                                    request={serviceEquineRelationshipCreateRequest}
                                    onSuccess={
                                        /** @type {(femResponse: import("../api").FemServiceEquineRelationshipRead) => void} */
                                        (femResponse) => {
                                            onClickHandler(femResponse.service);
                                            if (femResponse.service.service_provider.is_connection || femResponse.startedAt !== undefined) {
                                                addToEntityListState(femResponse, setCurrentServices, {side: "front"});
                                            } else {
                                                addToEntityListState(femResponse, setPendingServices, {side: "front"});
                                            }
                                        }
                                    }
                                />
                            )
                        }
                    ]}
                />
            </div>

            {serviceProviderRelationshipRequests.length === 0
                ? <></>
                :
                    <div className="box">
                        <TableWithButtons
                            entities={serviceProviderRelationshipRequests}
                            setEntities={setServiceProviderRelationshipRequests}
                            emptyListText="There are no open requests to service providers."
                            title={<h2 className="subtitle is-5 mb-0">Service provider onboarding requests</h2>}
                            tableHeaderElements={
                                <>
                                    <th>Name</th>
                                    <th>Requested on</th>
                                </>
                            }
                            oneRowTableDataElements={(serviceProviderEquine) => (
                                <>
                                    <td>{serviceProviderEquine.service_provider.name}</td>
                                    <td>
                                        {serviceProviderEquine.customerApproval
                                            ? dateToString(serviceProviderEquine.customerApproval.approvedAt)
                                            : "Unknown"}
                                    </td>
                                </>
                            )}
                            deleteButton={{
                                deleteRequest: serviceProviderEquineRelationshipDeleteRequest,
                                label: "Retract",
                                onDelete: (entity) => addToEntityListState(
                                    /** @type {import("../api").FemServiceProviderEquineRelationshipRead} */
                                    ({
                                        ...entity,
                                        deleted: {
                                            deletedAt: (new Date()).getTime(),
                                            user: {id: -1, display_name: "you"}
                                        }
                                    }), setPastServiceProviders, {side: "front", limit: 10}),
                            }}
                            viewButton={{
                                label: "Details",
                                entityProfilePath: (id) => path(paths.serviceProviderEquineRelationships.profile, {id}),
                            }}
                        />
                    </div>
            }

            <div className="box">
                <TableWithButtons
                    entities={serviceProviders}
                    setEntities={setServiceProviders}
                    emptyListText="No service providers are associated with this horse."
                    title={<h2 className="subtitle is-5 mb-0">Associated Service Providers</h2>}
                    tableHeaderElements={
                        <>
                            <th>Name</th>
                            <th>Service Provider Approval</th>
                        </>
                    }
                    oneRowTableDataElements={(serviceProviderEquine) => (
                        <>
                            <td>{serviceProviderEquine.service_provider.name}</td>
                            <td>
                                {serviceProviderEquine.service_provider.is_connection
                                    ? "Not needed."
                                    : (
                                        serviceProviderEquine.serviceProviderApproval === null
                                            ? "Pending"
                                            :
                                                <>
                                                    Approved on {dateToString(serviceProviderEquine.serviceProviderApproval.approvedAt)}
                                                    by {serviceProviderEquine.serviceProviderApproval.user.display_name}
                                                    {serviceProviderEquine.response_note && serviceProviderEquine.response_note.length > 0
                                                        ? 
                                                            <>&nbsp;(<LinkWithModal
                                                                content={
                                                                    <> 
                                                                        <p><strong>Service provider's response:</strong></p>
                                                                        <TextContent content={serviceProviderEquine.response_note} />
                                                                    </>
                                                                }
                                                                linkContent="note"
                                                            />)</>
                                                        : ""
                                                    }
                                                </>
                                    )
                                }
                            </td>
                        </>
                    )}
                    viewButton={{
                        label: "Details",
                        entityProfilePath: (id) => path(paths.serviceProviderEquineRelationships.profile, {id}),
                    }}
                    tableButtons={[
                        ({additionalButtonStyles}) => 
                            <ApiButtonWithModal
                                request={serviceProviderEquineRelationshipRequestRequest}
                                label="Request Onboarding"
                                additionalButtonStyles={["is-fullwidth is-small", additionalButtonStyles ?? ''].join(" ")}
                                onSuccess={(/** @type {import("../api").FemServiceProviderEquineRelationshipRead} */ entity) => {
                                    if (entity.service_provider.is_connection || entity.serviceProviderApproval !== null) {
                                        addToEntityListState(
                                            entity, setServiceProviders, { allowDuplicates: (a, b) => a.id === b.id });
                                    } else {
                                        addToEntityListState(
                                            entity,
                                            setServiceProviderRelationshipRequests,
                                            { allowDuplicates: (a, b) => a.id === b.id });
                                    }
                                }}
                                modal={(submitHandler) =>
                                    <ServiceProviderEquineRelationshipRequestForm
                                        equineId={equine.id}
                                        submitButtonLabel="Request"
                                        handleSubmit={(_original, edited) => submitHandler(edited)}
                                    />
                                }
                            />,
                        ({additionalButtonStyles}) =>
                            <ApiButtonWithModal
                                additionalButtonStyles={["is-small", additionalButtonStyles ?? ''].join(" ")}
                                request={serviceProviderEquineRelationshipCreateRequest}
                                label="Add"
                                onSuccess={(entity) =>
                                    addToEntityListState(
                                        entity, setServiceProviders, {allowDuplicates: (a,b) => a.id === b.id})
                                }
                                modal={(submitHandler) =>
                                    <ServiceProviderConnectionEquineRelationshipForm
                                        equineId={equine.id}
                                        submitButtonLabel="Add"
                                        handleSubmit={(_original, edited) => submitHandler(edited)}
                                    />
                                }
                            />
                    ]}
                    deleteButton={{
                        label: "End",
                        deleteRequest: serviceProviderEquineRelationshipDeleteRequest,
                        onDelete: (entity) => {
                            setAvailableServices((oldServices) =>
                                oldServices.filter((service) =>
                                    service.service_provider.id !== entity.service_provider.id));
                            addToEntityListState(entity, setPastServiceProviders, {side: "front", limit: 10});
                        },
                    }}
                />
            </div>

            <div className="box">
                <TableWithButtons
                    entities={pastServices}
                    setEntities={setPastServices}
                    emptyListText="No past services associated with this horse."
                    title={<h2 className="subtitle is-5 mb-0">Past Services</h2>}
                    tableHeaderElements={
                        <>
                            <th>Name</th>
                            <th>Service Provider</th>
                            <th>Requested</th>
                            <th>Started</th>
                            <th>Ended</th>
                        </>
                    }
                    oneRowTableDataElements={(serviceRelationship) => (
                        <>
                            <td>{serviceRelationship.service.name}</td>
                            <td>{serviceRelationship.service.service_provider.name}</td>
                            <td>{dateToString(serviceRelationship.requestedAt)}</td>
                            <td>{serviceRelationship.startedAt ? dateToString(serviceRelationship.startedAt) : "-"}</td>
                            <td>{serviceRelationship.endedAt ? dateToString(serviceRelationship.endedAt) : "-"}</td>
                        </>
                    )}
                />
            </div>

            <div className="box">
                <TableWithButtons
                    entities={pastServiceProviders}
                    setEntities={setPastServiceProviders}
                    emptyListText="No past service providers associated with this horse."
                    title={<h2 className="subtitle is-5 mb-0">Past Service Providers</h2>}
                    tableHeaderElements={
                        <>
                            <th>Name</th>
                            <th>Status</th>
                        </>
                    }
                    oneRowTableDataElements={(serviceProviderEquine) => (
                        <>
                            <td>{serviceProviderEquine.service_provider.name}</td>
                            <td>{serviceProviderEquine.service_provider.is_connection
                                ? `Ended${serviceProviderEquine.deleted ? ` on ${dateToString(serviceProviderEquine.deleted.deletedAt)}` : ""}`
                                : serviceProviderEquine.serviceProviderRejection === null
                                    ? (serviceProviderEquine.serviceProviderApproval === null
                                        ? `Request retracted${serviceProviderEquine.deleted ? ` on ${dateToString(serviceProviderEquine.deleted.deletedAt)}` : ""}`
                                        : `Service provider removed${serviceProviderEquine.deleted ? ` on ${dateToString(serviceProviderEquine.deleted.deletedAt)}` : ""}`
                                    )
                                    : <>
                                        Rejected on {dateToString(serviceProviderEquine.serviceProviderRejection.rejectedAt)}
                                        by {serviceProviderEquine.serviceProviderRejection.user.display_name} 
                                        {serviceProviderEquine.response_note && serviceProviderEquine.response_note.length > 0
                                            ? 
                                                <>&nbsp;(<LinkWithModal
                                                    content={
                                                        <> 
                                                            <p><strong>Service provider's response:</strong></p>
                                                            <TextContent content={serviceProviderEquine.response_note} />
                                                        </>
                                                    }
                                                    linkContent="note"
                                                />)</>
                                            : ""
                                        }
                                    </>}
                            </td>
                        </>
                    )}
                    viewButton={{
                        label: "Details",
                        entityProfilePath: (id) => path(paths.serviceProviderEquineRelationships.profile, {id}),
                    }}
                />
            </div>
        </>
    );
}