// @ts-check

import React, { useState } from "react";
import { Modal } from "react-base";

/**
 * @typedef {Object} LinkWithModalProps
 * @prop {React.JSX.Element} content
 * @prop {React.JSX.Element | string} linkContent
 */

/**
 * 
 * @param {LinkWithModalProps} param0
 * @returns 
 */
export default function LinkWithModal({
    content,
    linkContent,
}) {
    const [isModalShowing, setIsModalShowing] = useState(false);

    return (
        <>
            <Modal isShowing={isModalShowing} setIsShowing={setIsModalShowing}>
                {content}
            </Modal>
            <a onClick={() => setIsModalShowing(true)}>{linkContent}</a>
        </>
    );
}