// @ts-check

import React, { useState } from "react";
import { Modal, useApi } from "react-base";

/**
 * @template FEM
 * @callback ApiButtonWithModalSuccessHandler 
 * @param {FEM} femEntity
 * @returns {Promise<void> | void}
 */

/**
 * @template FEM_ORIGINAL, FEM_WRITE
 * @callback ApiButtonWithModalRequestFunction
 * @param {FEM_WRITE} data
 * @param {FEM_ORIGINAL} [femEntity]
 * @return {Request}
 */

/**
 * @template FEM_ORIGINAL, FEM_READ, FEM_WRITE
 * @typedef {Object} ApiButtonWithModalButtonProps2
 * @prop {string} label
 * @prop {ApiButtonWithModalRequestFunction<FEM_ORIGINAL, FEM_WRITE>} request
 * @prop {string} [additionalButtonStyles]
 * @prop {FEM_ORIGINAL} [femEntity]
 * @prop {ApiButtonWithModalSuccessHandler<FEM_READ>} [onSuccess]
 * @prop {(triggerFunction: (femWriteEntity: FEM_WRITE) => Promise<import("../../api").FemApiErrorResponse | null>) => (React.JSX.Element | Array<React.JSX.Element>)} modal
 */

/**
 * 
 * @template FEM_ORIGINAL, FEM_READ, FEM_WRITE
 * @param {ApiButtonWithModalButtonProps2<FEM_ORIGINAL, FEM_READ, FEM_WRITE>} param0 
 * @returns {React.JSX.Element}
 */
export default function ApiButtonWithModal({
    label,
    request,
    additionalButtonStyles,
    femEntity,
    onSuccess,
    modal
}) {
    const [isModalShowing, setIsModalShowing] = useState(false);
    const fetchFromApi = useApi();

    /**
     * 
     * @param {FEM_WRITE} data
     * @param {FEM_ORIGINAL} [femEntity]
     * @returns {Promise<import("../../api").FemApiErrorResponse | null>}
     */
    async function makeApiQuery(data, femEntity = undefined) {
        const response = await fetchFromApi(request(data, femEntity));
        if (response.isOk) {
            if (response.data?.entity && onSuccess) {
                onSuccess(response.data.entity);
            }
            setIsModalShowing(false);
            return null;
        } else {
            return response.data;
        }
    }

    return (
        <>
            <Modal isShowing={isModalShowing} setIsShowing={setIsModalShowing}>
                {modal((femWriteEntity) => makeApiQuery(femWriteEntity, femEntity))}
            </Modal>
            <div className="control">
                <button
                    className={["button", additionalButtonStyles].join(" ")}
                    onClick={() => setIsModalShowing(true)}
                >{label}</button>
            </div>

        </>
    )
}