// @ts-check

import React, { useState } from "react";
import { Modal } from "react-base";
import PonylogShareForm from "./ponylog-share-form";

/**
 * @callback PonylogShareButtonSaveHandler
 * @param {Array<import("../api").FemPersonMention>} sharedWith
 * @returns {void | Promise<void>}
 */
/**
 * @typedef {Object} PonylogShareButtonProps
 * @prop {string} [label = "Share"]
 * @prop {string} [additionalButtonStyles]
 * @prop {boolean} [disabled = false]
 * @prop {import("../api").FemPonylogEntryRead} entry
 * @prop {import("../api").FemRoleToDisplayNameMapping} allAvailableRoles
 * @prop {PonylogShareButtonSaveHandler} [onSave]
 */

/**
 * 
 * @param {PonylogShareButtonProps} param0 
 * @returns {React.JSX.Element}
 */
export default function PonylogShareButton({
    label = "Share",
    additionalButtonStyles,
    disabled = false,
    entry,
    allAvailableRoles,
    onSave,
}) {
    const [isModalShowing, setIsModalShowing] = useState(false);

    /** @type {import("./ponylog-share-form").PonylogShareFormSaveHandler} */
    async function saveHandler(sharedWith) {
        setIsModalShowing(false);
        if (onSave) {
            await onSave(sharedWith);
        }
    }

    return (
        <>
            <Modal isShowing={isModalShowing} setIsShowing={setIsModalShowing}>
                <PonylogShareForm
                    entry={entry}
                    allAvailableRoles={allAvailableRoles}
                    onSave={saveHandler}
                />
            </Modal>
            <div className="control">
                <button
                    className={["button", additionalButtonStyles].join(" ")}
                    onClick={() => setIsModalShowing(true)}
                    disabled={disabled}
                >{label}</button>
            </div>
        </>
    );
}