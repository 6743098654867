// @ts-check

import React from 'react';
import { Link } from 'react-router-dom';
import paths from '../paths';

export default function TermsOfUse() {
    const siteName = "EquineKeeper";
    const companyName = "Hasufel LLC";
    const effectiveDate = "14 July 2024";
    
    function PrivacyPolicyLink() {
        return (
            <Link to={paths.privacyPolicy}>Privacy Policy</Link>
        );
    }

    return (
        <>
            <h1>Terms of Use</h1>

            <p>Welcome to {siteName}!</p>

            <p>These Terms of Use (or "Terms") govern your use of {siteName}, 
                except where we expressly state that separate terms (and not 
                these) apply, and provide information about the {siteName} 
                Service (the "Service"), outlined below. When you create an 
                {siteName} account or use {siteName}, you agree to these terms.
            </p>

            <p>The {siteName} Service is provided to you by {companyName}. 
                These Terms of Use therefore constitute an agreement between 
                you and {companyName}.</p>

            <p>ARBITRATION NOTICE: YOU AGREE THAT DISPUTES BETWEEN YOU AND US 
                WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU 
                WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR 
                CLASS-WIDE ARBITRATION. WE EXPLAIN SOME EXCEPTIONS AND HOW YOU 
                CAN OPT OUT OF ARBITRATION BELOW.</p>

            <h2>The {siteName} Service</h2>

            <p>We agree to provide you with the {siteName} Service. The 
                Service is made up of the following aspects:</p>
            
            <ul>
                <li><strong>Opportunities to create, connect, communicate, discover, 
                    share, and coordinate your equestrian activities.</strong>
                </li>
                <li>
                    <strong>Fostering a safe environment.</strong> We have 
                    people and systems that work to combat abuse and violations 
                    of our Terms and policies, as well as harmful and deceptive 
                    behavior. We use all the information we have -including your 
                    information- to try to keep our platform secure. We also 
                    may share information about misuse or harmful content with 
                    law enforcement. Learn more in the <PrivacyPolicyLink/>.
                </li>
                <li>
                    <strong>Developing and using technologies that help us 
                    consistently serve our growing community.</strong> 
                    Organizing and analyzing information for our growing 
                    community is central to our Service. A big part of our 
                    Service is creating and using cutting-edge technologies 
                    that help us personalize, protect, and improve our Service 
                    on an incredibly large scale for a broad global community. 
                    Technologies like artificial intelligence and machine 
                    learning give us the power to apply complex processes 
                    across our Service. Automated technologies also help us 
                    ensure the functionality and integrity of our Service.
                </li>
                <li>
                    <strong>Ensuring access to our Service.</strong>
                    To operate our global Service, we must store and transfer 
                    data across our systems around the world, including outside 
                    of your country of residence. The use of this global 
                    infrastructure is necessary and essential to provide our 
                    Service. This infrastructure may be owned or operated by 
                    {companyName}, or any other operator {companyName} acquires 
                    infrastructure services from.
                </li>
                <li>
                    <strong>Connecting you with brands, products, and services 
                    in ways you care about.</strong> We may use data from 
                    {siteName}, as well as from third-party partners, to show 
                    you ads, offers, and other sponsored content that we 
                    believe will be meaningful to you. And we try to make that 
                    content as relevant as all your other experiences on 
                    {siteName}.
                </li>
                <li>
                    <strong>Research and innovation.</strong>
                    We use the information we have to study our Service and 
                    we may collaborate with others on research to make our 
                    Service better and contribute to the well-being of our 
                    community.
                </li>
            </ul>

{/* How Our Service Is Funded
Instead of paying to use Instagram, by using the Service covered by these Terms, you acknowledge that we can show you ads that businesses and organizations pay us to promote on and off the Meta Company Products. We use your personal data, such as information about your activity and interests, to show you ads that are more relevant to you.
We show you relevant and useful ads without telling advertisers who you are. We don’t sell your personal data. We allow advertisers to tell us things like their business goal and the kind of audience they want to see their ads. We then show their ad to people who might be interested.
We also provide advertisers with reports about the performance of their ads to help them understand how people are interacting with their content on and off Instagram. For example, we provide general demographic and interest information to advertisers to help them better understand their audience. We don’t share information that directly identifies you (information such as your name or email address that by itself can be used to contact you or identifies who you are) unless you give us specific permission. Learn more about how Instagram ads work here.
You may see branded content on Instagram posted by account holders who promote products or services based on a commercial relationship with the business partner mentioned in their content. You can learn more about this here. */}

            <h2>The Privacy Policy</h2>
            <p>Providing our Service requires collecting and using your 
                information. The <PrivacyPolicyLink/> explains how we collect, 
                use, and share information. You must agree to the Privacy 
                Policy to use {siteName}.</p>

            <h2>Your Commitments</h2>
            <p>In return for our commitment to provide the Service, we require 
                you to make the below commitments to us.</p>

            <p><strong>Who Can Use {siteName}.</strong> We want our Service to 
            be as open and inclusive as possible, but we also want it to be 
            safe, secure, and in accordance with the law. So, we need you to 
            commit to a few restrictions in order to be part of the {siteName} 
            community.</p>

            <ul>
                <li>
                    You must be at least 13 years old.
                </li>
                <li>
                    You must not be prohibited from receiving any aspect of our 
                    Service under applicable laws or engaging in payments 
                    related Services if you are on an applicable denied party 
                    listing.
                </li>
                <li>
                    We must not have previously disabled your account for 
                    violation of law or any of our policies.
                </li>
                <li>
                    You must not be a convicted sex offender.
                </li>
            </ul>

            <p><strong>How You Can't Use {siteName}.</strong> Providing a safe and 
            open Service for a broad community requires that we all do our part.
            </p>

            <ul>
                <li>
                    <strong>You can't impersonate others or provide inaccurate 
                    information.</strong>
                </li>
                <li>
                    <strong>You don't have to disclose your identity on {siteName}, but 
                    you must provide us with accurate and up to date 
                    information (including registration information), which may 
                    include providing personal data. Also, you may not 
                    impersonate someone or something you aren't, and you can't 
                    create an account for someone else unless you have their 
                    express permission.</strong>
                </li>
                <li>
                    <strong>You can't do anything unlawful, misleading, or fraudulent 
                    or for an illegal or unauthorized purpose.</strong>
                </li>
                <li>
                    <strong>You can't violate (or help or encourage others to violate) 
                    these Terms or our policies.</strong>
                </li>
                <li>
                    <strong>You can't do anything to interfere with or impair the 
                    intended operation of the Service.</strong> This includes 
                    misusing any reporting, dispute, or appeals channel, such 
                    as by making fraudulent or groundless reports or appeals.
                </li>
                <li>
                    <strong>Prohibited Content.</strong> Certain content 
                    may not be promoted in the content you create or share. We
                    prohibit promotion of the following:

                    <ul>
                        <li>
                            Illegal products or services
                        </li>
                        <li>
                            Tobacco products, vaporizers, electronic 
                            cigarettes, or any other products that simulate 
                            smoking
                        </li>
                        <li>
                            Drugs and drug-related products for people, 
                            including illegal or recreational drugs
                        </li>
                        <li>
                            Unsafe products and supplements for people.
                            Unsafe supplements include but are not limited to 
                            anabolic steroids, chitosan, comfrey, 
                            dehydroepiandrosterone, ephedra, and human growth 
                            hormones.
                        </li>
                        <li>
                            Weapons, ammunition or explosives.
                        </li>
                        <li>
                            Any content must not promote firearms (including 
                            firearms parts, ammunition, paintball guns and bb 
                            guns), firearm silencers or suppressors, weapons 
                            (including pepper spray, non-culinary 
                            knives/blades/spears, tasers, nunchucks, batons, or 
                            weapons intended for self-defense), or fireworks or 
                            explosives.
                        </li>
                        <li>
                            Adult products or services, except for family 
                            planning and contraception.
                        </li>
                        <li>
                            Content must not promote the sale or use of adult 
                            products or services, except for posts for family 
                            planning and contraception.
                        </li>
                        <li>
                            Payday loans, paycheck advances and bail bonds
                        </li>
                        <li>
                            Initial coin offerings, binary options or contract 
                            for difference trading
                        </li>
                        <li>
                            Controversial political or social issues or crises 
                            in an exploitative manner for commercial purposes
                        </li>
                        <li>
                            State lotteries
                        </li>
                        <li>
                            Negative portrayal of voting or census 
                            participation in the United States and/or advising 
                            users not to vote or participate in a census.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>You can't attempt to create accounts or access or collect 
                    information in unauthorized ways.</strong>
                    This includes creating accounts or collecting information 
                    in an automated way without our express permission.
                </li>
                <li>
                    <strong>You can’t sell, license, or purchase any account or data 
                    obtained from us or our Service.</strong>
                    This includes attempts to buy, sell, or transfer any aspect 
                    of your account (including your username); solicit, 
                    collect, or use login credentials or badges of other users; 
                    or request or collect {siteName} usernames, passwords, or 
                    misappropriate access tokens.
                </li>
                <li>
                    <strong>You can't post someone else’s private or 
                    confidential information without permission or do anything 
                    that violates someone else's rights, including intellectual 
                    property rights (e.g., copyright infringement, trademark 
                    infringement, counterfeit, or pirated goods).</strong>
                    You may use someone else's works under exceptions or 
                    limitations to copyright and related rights under 
                    applicable law. You represent you own or have obtained all 
                    necessary rights to the content you post or share.
                </li>
                <li>
                    <strong>You can’t modify, translate, create derivative 
                    works of, or reverse engineer our products or their 
                    components.</strong>
                </li>
                <li>
                    <strong>You can't use a domain name or URL in your username without 
                    our prior written consent.</strong>
                </li>
            </ul>

            <p><strong>Permissions You Give to Us.</strong> As part of our 
            agreement, you also give us permissions that we need to provide the 
            Service.</p>

            <ul>
                <li>
                    <strong>We do not claim ownership of your content, but you 
                    grant us a license to use it.</strong>
                    Nothing is changing about your rights in your content. We 
                    do not claim ownership of your content that you post on or 
                    through the Service and you are free to share your content 
                    with anyone else, wherever you want. However, we need 
                    certain legal permissions from you (known as a “license”) 
                    to provide the Service. When you share, post, or upload 
                    content that is covered by intellectual property rights 
                    (like photos or videos) on or in connection with our 
                    Service, you hereby grant to us a non-exclusive, 
                    royalty-free, transferable, sub-licensable, worldwide 
                    license to host, use, distribute, modify, run, copy, 
                    publicly perform or display, translate, and create 
                    derivative works of your content (consistent with your 
                    privacy and application settings). This license will end 
                    when your content is deleted from our systems. You can 
                    delete content individually or all at once by deleting your 
                    account. To learn more about how we use information, and 
                    how to control or delete your content, review 
                    the <PrivacyPolicyLink/>.
                </li>
                <li>
                    You agree that we can download and install updates to the 
                    Service on your device.
                </li>
            </ul>

            <h2>Additional Rights We Retain</h2>
            <ul>
                <li>
                    If you select a username or similar identifier for your 
                    account, we may change it if we believe it is appropriate 
                    or necessary (for example, if it infringes someone's 
                    intellectual property or impersonates another user).
                </li>
                <li>
                    If you use content covered by intellectual property rights 
                    that we have and make available in our Service (for 
                    example, images, designs, videos, or sounds we provide that 
                    you add to content you create or share), we retain all 
                    rights to our content (but not yours).
                </li>
                <li>
                    You can only use our intellectual property and trademarks 
                    or similar marks with our prior written permission.
                </li>
                <li>
                    You must obtain written permission from us or under an open 
                    source license to modify, create derivative works of, 
                    decompile, or otherwise attempt to extract source code from 
                    us.
                </li>
            </ul>

            <h2>Content Removal and Disabling or Terminating Your Account</h2>

            <ul>
                <li>
                    We can remove any content or information you share on the 
                    Service if we believe that it violates these Terms of Use, 
                    our policies, or we are permitted or required to do so by 
                    law. We can refuse to provide or stop providing all or part 
                    of the Service to you (including terminating or disabling 
                    your access to any other products by {companyName}) 
                    immediately to protect our community or services, or if you 
                    create risk or legal exposure for us, violate these Terms 
                    of Use or our policies, if you repeatedly infringe other 
                    people's intellectual property rights, or where we are 
                    permitted or required to do so by law. We can also 
                    terminate or change the Service, remove or block content or 
                    information shared on our Service, or stop providing all or 
                    part of the Service if we determine that doing so is 
                    reasonably necessary to avoid or mitigate adverse legal or 
                    regulatory impacts on us. If you believe your account has 
                    been terminated in error, or you want to disable or 
                    permanently delete your account, consult our Help Center. 
                    When you request to delete content or your account, the 
                    deletion process will automatically begin no more than 30 
                    days after your request. It may take up to 90 days to 
                    delete content after the deletion process begins. While the 
                    deletion process for such content is being undertaken, the 
                    content is no longer visible to other users, but remains 
                    subject to these Terms of Use and our Privacy Policy. After 
                    the content is deleted, it may take us up to another 90 
                    days to remove it from backups and disaster recovery 
                    systems.
                </li>
                <li>
                    Content will not be deleted within 90 days of the account 
                    deletion or content deletion process beginning in the 
                    following situations:
                    <ul>
                        <li>
                            where your content has been used by others in 
                            accordance with this license and they have not 
                            deleted it (in which case this license will 
                            continue to apply until that content is deleted); or
                        </li>
                        <li>
                            where deletion within 90 days is not possible due 
                            to technical limitations of our systems, in which 
                            case, we will complete the deletion as soon as 
                            technically feasible; or
                        </li>
                        <li>
                            where deletion would restrict our ability to:
                            <ul>
                                <li>
                                    investigate or identify illegal activity or 
                                    violations of our terms and policies (for 
                                    example, to identify or investigate misuse of 
                                    our products or systems);
                                </li>
                                <li>
                                    protect the safety and security of our 
                                    products, systems, and users;
                                </li>
                                <li>
                                    comply with a legal obligation, such as the 
                                    preservation of evidence; or
                                </li>
                                <li>
                                    comply with a request of a judicial or 
                                    administrative authority, law enforcement, 
                                    or a government agency;
                                </li>
                                <li>
                                    in which case, the content will be retained 
                                    for no longer than is necessary for the 
                                    purposes for which it has been retained 
                                    (the exact duration will vary on a 
                                    case-by-case basis).
                                </li>
                            </ul>
                        </li>
                        <li>
                            If you delete or we disable your account, these 
                            Terms shall terminate as an agreement between 
                            you and us, but this section and the section 
                            below called "Our Agreement and What Happens if 
                            We Disagree" will still apply even after your 
                            account is terminated, disabled, or deleted.
                        </li>
                    </ul>
                </li>
            </ul>

            <h2>Our Agreement and What Happens if We Disagree</h2>

            <h3>Our Agreement</h3>
            <ul>
                <li>
                    If any aspect of this agreement is unenforceable, the rest 
                    will remain in effect.
                </li>
                <li>
                    Any amendment or waiver to our agreement must be in writing 
                    and signed by us. If we fail to enforce any aspect of this 
                    agreement, it will not be a waiver.
                </li>
                <li>
                    We reserve all rights not expressly granted to you.
                </li>
            </ul>

            <h3>Who Has Rights Under this Agreement</h3>

            <ul>
                <li>
                    Our past, present, and future affiliates and agents can invoke our 
                    rights under this agreement in the event they become involved in a 
                    dispute. Otherwise, this agreement does not give rights to any 
                    third parties.
                </li>
                <li>
                    You cannot transfer your rights or obligations under this 
                    agreement without our consent.
                </li>
                <li>
                    Our rights and obligations can be assigned to others. For 
                    example, this could occur if our ownership changes (as in a 
                    merger, acquisition, or sale of assets) or by law.
                </li>
            </ul>

            <h3>Who Is Responsible if Something Happens</h3>

            <ul>
                <li>
                    Our Service is provided "as is," and we can't guarantee it 
                    will be safe and secure or will work perfectly all the 
                    time. TO THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL 
                    WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE 
                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A 
                    PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
                </li>
                <li>
                    We also don’t control what people and others do or say, and 
                    we aren’t responsible for their (or your) actions or 
                    conduct (whether online or offline) or content (including 
                    unlawful or objectionable content). We also aren’t 
                    responsible for services and features offered by other 
                    people or companies, even if you access them through our 
                    Service.
                </li>
                <li>
                    Our responsibility for anything that happens on the Service 
                    (also called "liability") is limited as much as the law 
                    will allow. If there is an issue with our Service, we can't 
                    know what all the possible impacts might be. You agree that 
                    we won't be responsible ("liable") for any lost profits, 
                    revenues, information, or data, or consequential, special, 
                    indirect, exemplary, punitive, or incidental damages 
                    arising out of or related to these Terms, even if we know 
                    they are possible. This includes when we delete your 
                    content, information, or account. Our aggregate liability 
                    arising out of or relating to these Terms will not exceed 
                    the greater of $100 or the amount you have paid us in the 
                    past twelve months.
                </li>
                <li>
                    You agree to defend (at our request), indemnify and hold us 
                    harmless from and against any claims, liabilities, damages, 
                    losses, and expenses, including without limitation, 
                    reasonable attorney's fees and costs, arising out of or in 
                    any way connected with these Terms or your use of the 
                    Service. You will cooperate as required by us in the 
                    defense of any claim. We reserve the right to assume the 
                    exclusive defense and control of any matter subject to 
                    indemnification by you, and you will not in any event 
                    settle any claim without our prior written consent.
                </li>
            </ul>

            <h3> How We Will Handle Disputes</h3>

            <ul>
                <li>
                    Except as provided below, <strong>you and we agree that any cause of 
                    action, legal claim, or dispute between you and us arising out of 
                    or related to these Terms or {siteName} ("claim(s)") must be 
                    resolved by arbitration on an individual basis. Class actions and 
                    class arbitrations are not permitted;</strong> you and we may bring a claim 
                    only on your own behalf and cannot seek relief that would affect 
                    other {siteName} users. If there is a final judicial determination 
                    that any particular claim (or a request for particular relief) 
                    cannot be arbitrated in accordance with this provision's 
                    limitations, then only that claim (or only that request for relief) 
                    may be brought in court. All other claims (or requests for relief) 
                    remain subject to this provision.
                </li>
                <li>
                    Instead of using arbitration, you or we can bring claims in 
                    your local "small claims" court, if the rules of that court 
                    will allow it. If you don't bring your claims in small 
                    claims court (or if you or we appeal a small claims court 
                    judgment to a court of general jurisdiction), then the 
                    claims must be resolved by binding, individual arbitration. 
                    The American Arbitration Association will administer all 
                    arbitrations under its Consumer Arbitration Rules. You and 
                    we expressly waive a trial by jury.<br />
                    The following claims don't have to be arbitrated and may be 
                    brought in court: disputes related to intellectual property 
                    (like copyrights and trademarks), or efforts to interfere 
                    with the Service or engage with the Service in unauthorized 
                    ways (for example, automated ways). In addition, issues 
                    relating to the scope and enforceability of the arbitration 
                    provision are for a court to decide.<br />
                    This arbitration provision is governed by the Federal 
                    Arbitration Act.<br />
                    You can opt out of this provision within 30 days of the 
                    date that you agreed to these Terms. To opt out, you must 
                    send your name, residence address, username, email address 
                    or phone number you use for your {siteName} account, and a 
                    clear statement that you want to opt out of this 
                    arbitration agreement, and you must send them throught the
                    feedback form in the {siteName} Service.
                </li>
                <li>
                    Before you commence arbitration of a claim, you must 
                    provide us with a written Notice of Dispute that includes 
                    your name, residence address, username, email address or 
                    phone number you use for your {siteName} account, a 
                    detailed description of the dispute, and the relief you 
                    seek. Any Notice of Dispute you send to us should be 
                    submitted through the feedback form in the {siteName} 
                    Service. Before we commence arbitration, we will send you a 
                    Notice of Dispute to the email address you use with your 
                    {siteName} account, or other appropriate means. If we are 
                    unable to resolve a dispute within thirty (30) days after 
                    the Notice of Dispute is received, you or we may commence 
                    arbitration.
                </li>
                <li>
                    The costs and fees of arbitration shall be allocated in 
                    accordance with the arbitration provider's rules, including 
                    rules regarding frivolous or improper claims.
                </li>
                <li>
                    For any claim that is not arbitrated or resolved in small 
                    claims court, you agree that it will be resolved 
                    exclusively in the U.S. District Court for the Northern 
                    District of California or a state court located in Contra 
                    Costa County. You also agree to submit to the personal 
                    jurisdiction of either of these courts for the purpose of 
                    litigating any such claim.
                </li>
                <li>
                    The laws of the State of California, to the extent not 
                    preempted by or inconsistent with federal law, will govern 
                    these Terms and any claim, without regard to conflict of 
                    law provisions.
                </li>
            </ul>

            <h3>Unsolicited Material</h3>

            <p>We always appreciate feedback or other suggestions, but may use 
            them without any restrictions or obligation to compensate you for 
            them, and are under no obligation to keep them confidential.</p>

            <h2>Updating These Terms</h2>

            We may change our Service and policies, and we may need to make 
            changes to these Terms so that they accurately reflect our Service 
            and policies. Unless otherwise required by law, we will notify you 
            (for example, through our Service) before we make changes to these 
            Terms and give you an opportunity to review them before they go 
            into effect. Then, if you continue to use the Service, you will be 
            bound by the updated Terms. If you do not want to agree to these or 
            any updated Terms, you can delete your account.

            <p>Effective Date: {effectiveDate}</p>
        </>
    );
}