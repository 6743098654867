// @ts-check

/**
 * @callback EquineFormSubmitHandler 
 * @param {import("../api").FemEquineRead | null | undefined} original
 * @param {import("../api").FemEquineWrite} edited
 * @returns {Promise<import("../../../api/src/fem-types").FemApiErrorResponse | null>}
 */
/**
 * @typedef {Object} EquineFormProps
 * @prop {import("../api").FemEquineRead | null} [equine]
 * @prop {string} [submitButtonLabel]
 * @prop {EquineFormSubmitHandler} handleSubmit
 * @prop {import("../api").FemRoleToDisplayNameMapping} allRelationships
 */

import React, { useState } from "react";
import { ActionButton, BulmaLevel, useErrors } from "react-base";
import RelationshipCheckboxes, { roleStatesFromRolesList } from "./relationships-checkboxes";
import { checkValue, dateField, setAndCheck, textField } from "../helpers/others";

/**
 * 
 * @param {EquineFormProps} param0 
 */
export default function EquineForm({
        equine = undefined,
        submitButtonLabel = "Add",
        allRelationships,
        handleSubmit}) {

    const ERR_RELATIONSHIPS = 'relationships';
    const ERR_RELATIONSHIPS_AT_LEAST_ONE = 'at-least-one';
    const ERR_NAMES = 'names';
    const ERR_NAMES_AT_LEAST_ONE = 'at-least-one';
    const ERR_API = 'api';
    const ERR_API_SUBMIT_FAILED = 'failure';
    const ERR_BIRTH_YEAR = 'birth-year';
    const ERR_BIRTH_YEAR_NOT_NUMBER = 'not-integer';
    const ERR_BIRTH_YEAR_NOT_IN_RANGE = 'not-in-range';
    const ERR_FIELD_USEF_NUMBER = 'usef-number';
    const ERR_USEF_NUMBER_CONTAINS_NON_DIGITS = 'contains-non-digits';
    const ERR_FIELD_USHJA_NUMBER = 'ushja-number';
    const ERR_USHJA_NUMBER_CONTAINS_NON_DIGITS = 'contains-non-digits';
    const ERR_FIELD_FEI_ID = 'fei-id';
    const ERR_FEI_ID_FORMAT = 'format';
    const ERR_FIELD_MICROCHIP_NUMBER = 'microchip-number';
    const ERR_MICROCHIP_NUMBER_CONTAINS_NON_DIGITS = 'contains-non-digits';
    const ERR_FIELD_INSURANCE_CARRIER = 'insurance-carrier';
    const ERR_INSURANCE_CARRIER_TOO_LONG = 'too-long';
    const ERR_FIELD_INSURANCE_POLICY_NUMBER = 'insurance-policy-number';
    const ERR_INSURANCE_POLICY_NUMBER_TOO_LONG = 'too-long';
    const ERR_FIELD_ALLERGIES = 'allergies';
    const ERR_ALLERGIES_TOO_LONG = 'too-long';
    const ERR_FIELD_LAST_SHOEING_DATE = 'last-shoeing-date';
    const ERR_LAST_SHOEING_DATE_IN_FUTURE = 'in-future';

    const ALLERGIES_MAX_LENGTH = 100;
    const INSURANCE_CARRIER_MAX_LENGTH = 100;
    const INSURANCE_POLICY_NUMBER_MAX_LENGTH = 100;

    const AGE_LIMIT = 62;
    const OPTIONAL_NUMBER_REGEX = /^\d*$/;
    const OPTIONAL_FEI_ID_REGEX = /^(?:[0-9]{3,3}[a-zA-Z]{2,2}[0-9]{2,2})?$/;

    const [nickname, setNickname] = useState(equine?.nickname ?? '');
    const [usefName, setUsefName] = useState(equine?.usef_name ?? '');
    const [usefNumber, setUsefNumber] = useState(equine?.usef_number ?? '');
    const [ushjaNumber, setUshjaNumber] = useState(equine?.ushja_number ?? '');
    const [feiId, setFeiId] = useState(equine?.fei_id ?? '');
    const [microchipNumber, setMicrochipNumber] = useState(equine?.microchip_number ?? '');
    const [insuranceCarrier, setInsuranceCarrier] = useState(equine?.insurance_carrier ?? '');
    const [insurancePolicyNumber, setInsurancePolicyNumber] = useState(equine?.insurance_policy_number ?? '');
    const [allergies, setAllergies] = useState(equine?.allergies ?? '');
    const [birthYear, setBirthYear] = useState(equine?.birth_year.toString() ?? '');
    const [lastShoeingDate, setLastShoeingDate] = useState(equine?.last_shoeing_date ? equine.last_shoeing_date : null)
    const {setError, resetError, renderErrors} = useErrors();
    const [isValid, setIsValid] = useState(true);

    const [roleStates, setRoleStates] =
        useState(roleStatesFromRolesList(allRelationships, equine?.current_user_roles || []));

    /**
     * 
     * @param {string} currentYear 
     * @returns {boolean}
     */
    function checkBirthYear(currentYear) {
        const yearNumber = currentYear === "" ? -1 : Number(currentYear);
        const isInteger = currentYear !== "" && !Number.isNaN(yearNumber) && Number.isInteger(yearNumber);
        const nowYear = new Date().getFullYear();

        return checkValue([
            {
                successCondition: isInteger,
                errFieldName: ERR_BIRTH_YEAR,
                errConditionName: ERR_BIRTH_YEAR_NOT_NUMBER,
                errMessage: `Birth year is required and must be an integer.`,
            },
            {
                errorCondition: 
                    isInteger
                        && (yearNumber < 0
                            || (yearNumber > AGE_LIMIT && yearNumber < nowYear - AGE_LIMIT)
                            || yearNumber > nowYear),
                errFieldName: ERR_BIRTH_YEAR,
                errConditionName: ERR_BIRTH_YEAR_NOT_IN_RANGE,
                errMessage: `As birth year, enter either an age between 0 and ${AGE_LIMIT}, or a year between ${nowYear - AGE_LIMIT} and ${nowYear}.`,
            },
        ], setError, resetError);
    }

    /**
     * 
     * @param {string} currentYear 
     * @returns {void}
     */
    function checkAndChangeBirthYearOnBlur(currentYear) {
        const isYearValid = checkBirthYear(currentYear);
        if (isYearValid) {
            const year = Number(currentYear);
            if (year <= AGE_LIMIT) {
                const nowYear = new Date().getFullYear();
                setBirthYear((nowYear - year).toString());
            }
        }
    }

    /**
     * @typedef {Object} CheckValidityProps
     * @prop {import("./relationships-checkboxes").RoleStates} [currentRoleStates = roleStates]
     * @prop {Array<string>} [currentNames = [nickname, name, usefName]]
     * @prop {string} [currentNickname = nickname]
     * @prop {string} [currentUsefName = usefName]
     * @prop {string} [currentBirthYear = birthYear]
     * @prop {string} [currentUsefNumber = usefNumber]
     * @prop {string} [currentUshjaNumber = ushjaNumber]
     * @prop {string} [currentFeiId = feiId]
     * @prop {string} [currentMicrochipNumber = microchipNumber]
     * @prop {string} [currentInsuranceCarrier = insuranceCarrier]
     * @prop {string} [currentInsurancePolicyNumber = insurancePolicyNumber]
     * @prop {string} [currentAllergies = allergies]
     * @prop {number | null} [currentLastShoeingDate = lastShoeingDate]
     */
    /**
     * 
     * @param {CheckValidityProps} [param0 = {}]
     * @returns {boolean}
     */
    function checkValidity({
        currentRoleStates = roleStates,
        currentNickname = nickname,
        currentUsefName = usefName,
        currentBirthYear = birthYear,
        currentUsefNumber = usefNumber,
        currentUshjaNumber = ushjaNumber,
        currentFeiId = feiId,
        currentMicrochipNumber = microchipNumber,
        currentInsuranceCarrier = insuranceCarrier,
        currentInsurancePolicyNumber = insurancePolicyNumber,
        currentAllergies = allergies,
        currentLastShoeingDate = lastShoeingDate,
    } = {}) {
        const checks = [
            // roleStates
            checkValue({
                    errorCondition: 
                        /** @type {Array<import("../api").FemRole>} */ (Object.keys(currentRoleStates))
                            .filter((role) => currentRoleStates[role] === "checked")
                            .length === 0,
                    errFieldName: ERR_RELATIONSHIPS,
                    errConditionName: ERR_RELATIONSHIPS_AT_LEAST_ONE,
                    errMessage: "You must select at least one relationship.",
                }, setError, resetError),

            // nickname and usefName
            checkValue({
                    errorCondition: currentNickname === "" && currentUsefName === "",
                    errFieldName: ERR_NAMES,
                    errConditionName: ERR_NAMES_AT_LEAST_ONE,
                    errMessage: "At least one of barn name or show name must be non-empty.",
                }, setError, resetError),

            checkBirthYear(currentBirthYear),

            // usefNumber
            checkValue({
                    value: currentUsefNumber,
                    successCondition: OPTIONAL_NUMBER_REGEX,
                    errFieldName: ERR_FIELD_USEF_NUMBER,
                    errConditionName: ERR_USEF_NUMBER_CONTAINS_NON_DIGITS,
                    errMessage: "USEF number should contain only digits.",
                }, setError, resetError),

            // ushjaNumber
            checkValue(
                {
                    value: currentUshjaNumber,
                    successCondition: OPTIONAL_NUMBER_REGEX,
                    errFieldName: ERR_FIELD_USHJA_NUMBER,
                    errConditionName: ERR_USHJA_NUMBER_CONTAINS_NON_DIGITS,
                    errMessage: "USHJA number should contain only digits.",
                }, setError, resetError),
            
            // feiId
            checkValue(
                {
                    value: currentFeiId,
                    successCondition: OPTIONAL_FEI_ID_REGEX,
                    errFieldName: ERR_FIELD_FEI_ID,
                    errConditionName: ERR_FEI_ID_FORMAT,
                    errMessage: "FEI id is expected to be 3-digits, 2-letters, 2-digits, e.g.: 100AB12",
                }, setError, resetError),

            // microchipNumber
            checkValue(
                {
                    value: currentMicrochipNumber,
                    successCondition: OPTIONAL_NUMBER_REGEX,
                    errFieldName: ERR_FIELD_MICROCHIP_NUMBER,
                    errConditionName: ERR_MICROCHIP_NUMBER_CONTAINS_NON_DIGITS,
                    errMessage: "Microchip number should contain only digits.",
                }, setError, resetError),

            // insurance carrier
            checkValue(
                {
                    errorCondition: currentInsuranceCarrier.length > INSURANCE_CARRIER_MAX_LENGTH,
                    errFieldName: ERR_FIELD_INSURANCE_CARRIER,
                    errConditionName: ERR_INSURANCE_CARRIER_TOO_LONG,
                    errMessage:
                        `Insurance carrier name should not be longer than ${INSURANCE_CARRIER_MAX_LENGTH} characters.`,
                }, setError, resetError),
            
            // insurance policy number
             checkValue(
                {
                    errorCondition: currentInsurancePolicyNumber.length > INSURANCE_POLICY_NUMBER_MAX_LENGTH,
                    errFieldName: ERR_FIELD_INSURANCE_POLICY_NUMBER,
                    errConditionName: ERR_INSURANCE_POLICY_NUMBER_TOO_LONG,
                    errMessage:
                        "Insurance policy number should not be longer than "
                        + `${INSURANCE_POLICY_NUMBER_MAX_LENGTH} characters.`,
                }, setError, resetError),

            // allergies
            checkValue(
                {
                    errorCondition: currentAllergies.length > ALLERGIES_MAX_LENGTH,
                    errFieldName: ERR_FIELD_ALLERGIES,
                    errConditionName: ERR_ALLERGIES_TOO_LONG,
                    errMessage: `Allergies should not be longer than ${ALLERGIES_MAX_LENGTH} characters.`,
                }, setError, resetError),
            
             // last shoeing date
            checkValue(
                {
                    errorCondition: currentLastShoeingDate !== null && currentLastShoeingDate > new Date().getTime(),
                    errFieldName: ERR_FIELD_LAST_SHOEING_DATE,
                    errConditionName: ERR_LAST_SHOEING_DATE_IN_FUTURE,
                    errMessage: `Last shoeing date cannot be in the future.`,
                }, setError, resetError),
        ]
        const validity = (checks.find((v) => v === false) === undefined);
        setIsValid(validity);
        return validity;
    }

    function anyChange() {
        resetError(ERR_API, ERR_API_SUBMIT_FAILED);
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleFormButtonClick(callback) {
        if (checkValidity()) {
            /** @type {import("../api").FemEquineWrite} */
            const editedEquine = {
                nickname: nickname,
                // name: name,
                usef_name: usefName,
                birth_year: Number(birthYear),
                current_user_roles:
                    /** @type {Array<import("../api").FemRole>} */ (Object.keys(roleStates))
                        .filter((role) => roleStates[role] === "checked"),
            }

            if (!(usefNumber === "" && equine?.usef_number === undefined)) {
                editedEquine.usef_number = usefNumber;
            }
            if (!(ushjaNumber === "" && equine?.ushja_number === undefined)) {
                editedEquine.ushja_number = ushjaNumber;
            }
            if (!(feiId === "" && equine?.fei_id === undefined)) {
                editedEquine.fei_id = feiId;
            }
            if (!(microchipNumber === "" && equine?.microchip_number === undefined)) {
                editedEquine.microchip_number = microchipNumber;
            }
            if (!(insuranceCarrier === "" && equine?.insurance_carrier === undefined)) {
                editedEquine.insurance_carrier = insuranceCarrier;
            }
            if (!(insurancePolicyNumber === "" && equine?.insurance_policy_number === undefined)) {
                editedEquine.insurance_policy_number = insurancePolicyNumber;
            }
            if (!(allergies === "" && equine?.allergies === undefined)) {
                editedEquine.allergies = allergies;
            }
            if (!(lastShoeingDate === null && equine?.last_shoeing_date === undefined)) {
                editedEquine.last_shoeing_date = lastShoeingDate;
            }
            const errorResponse = await handleSubmit(equine, editedEquine);

            if (errorResponse) {
                setError(ERR_API, ERR_API_SUBMIT_FAILED, errorResponse.errors);
            }
        }

        callback();
    }

    function renderBirthdate() {
        return (
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Born</label>
                </div>
                <div className="field-body">
                    <div className="control is-expanded">
                        <input
                            className="input"
                            type="text"
                            placeholder="Year (or age)"
                            value={birthYear}
                            onChange={(event) =>
                                setAndCheck(
                                    event.target.value, setBirthYear, "currentBirthYear", checkValidity, anyChange)}
                            onBlur={(e) => checkAndChangeBirthYearOnBlur(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    /** @type {React.Dispatch<React.SetStateAction<import("./relationships-checkboxes").RoleStates>>} */
    function checkAndSetRoleStates(param) {
        setRoleStates((oldVal) => {
            const newVal = (param instanceof Function ? param(oldVal) : param);
            checkValidity({currentRoleStates: newVal});
            return newVal;
        });
    }

    /**
     * 
     * @param {import("../api").FemEquineRead | null | undefined} equine 
     * @returns {import("../api").FemPersonEquineRelationshipForRoleAssignmentRead | undefined}
     */
    function currentUserRelationship(equine) {
        return equine?.person_relationships.find((rel) => rel.person.is_current_user);
    }

    return (
        <form className="has-text-left">
            {textField({
                label: "Barn name",
                placeholder: "Barn name, nickname, or a short name",
                value: nickname,
                onChange: (event) =>
                    setAndCheck(event.target.value, setNickname, "currentNickname", checkValidity, anyChange),
            })}
            {textField({
                label: "Show name",
                placeholder: "Show name (e.g. USEF name)",
                value: usefName,
                onChange: (event) =>
                    setAndCheck(event.target.value, setUsefName, "currentUsefName", checkValidity, anyChange),
            })}
            {renderBirthdate()}
            {textField({
                label: "USEF number",
                placeholder: "USEF number",
                value: usefNumber,
                onChange: (event) =>
                    setAndCheck(event.target.value, setUsefNumber, "currentUsefNumber", checkValidity, anyChange),
            })}
            {textField({
                label: "USHJA number",
                placeholder: "USHJA number",
                value: ushjaNumber,
                onChange: (event) =>
                    setAndCheck(event.target.value, setUshjaNumber, "currentUshjaNumber", checkValidity, anyChange),
            })}
            {textField({
                label: "FEI id",
                placeholder: "FEI id, e.g. 100AB12",
                value: feiId,
                onChange: (event) => 
                    setAndCheck(event.target.value, setFeiId, "currentFeiId", checkValidity, anyChange),
            })}
            {textField({
                label: "Microchip number",
                placeholder: "Microchip number",
                value: microchipNumber,
                onChange: (event) =>
                    setAndCheck(
                        event.target.value, setMicrochipNumber, "currentMicrochipNumber", checkValidity, anyChange),
            })}
            {textField({
                label: "Insurance carrier",
                placeholder: "Name of insurance carrier",
                value: insuranceCarrier,
                onChange: (event) =>
                    setAndCheck(
                        event.target.value, setInsuranceCarrier, "currentInsuranceCarrier", checkValidity, anyChange),
            })}
            {textField({
                label: "Insurance policy no",
                placeholder: "Insurance policy number or id",
                value: insurancePolicyNumber,
                onChange: (event) =>
                    setAndCheck(
                        event.target.value,
                        setInsurancePolicyNumber,
                        "currentInsurancePolicyNumber",
                        checkValidity,
                        anyChange),
            })}
            {textField({
                label: "Allergies",
                placeholder: "Horse's allergies",
                value: allergies,
                onChange: (event) =>
                    setAndCheck(event.target.value, setAllergies, "currentAllergies", checkValidity, anyChange),
            })}
            {dateField({
                label: "Last shoeing date",
                placeholder: "Last shoeing date",
                value: lastShoeingDate === null ? undefined : new Date(lastShoeingDate),
                onChange: (newValue) =>
                    setAndCheck(
                        newValue ? newValue.getTime() : null,
                        setLastShoeingDate,
                        "currentLastShoeingDate",
                        checkValidity,
                        anyChange),
                isEmptyAllowed: true,
            })}

            <RelationshipCheckboxes
                allRelationships={allRelationships}
                label="Your relationship to this equine:"
                rolesThatCanBeChecked={equine ? new Set(currentUserRelationship(equine)?.creatable_roles) : undefined}
                rolesThatCanBeUnchecked={equine ? new Set(currentUserRelationship(equine)?.removable_roles) : undefined}
                roleStates={roleStates}
                setRoleStates={checkAndSetRoleStates}
            />
            {renderErrors()}
            <BulmaLevel
                right={[(
                    <ActionButton disabled={!isValid} onClick={handleFormButtonClick}>{submitButtonLabel}</ActionButton>
                )]}
            />
        </form>
    );
}
