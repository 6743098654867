// @ts-check

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiButton from "./api-button";
import ApiButtonWithModal from "./api-button-with-modal";

/**
 * @template {{id: number}} FEM_READ
 * @template FEM_WRITE_CREATE
 * @callback CreateForm
 * @param {{handleSubmit: ((original: FEM_READ | null | undefined, edited: FEM_WRITE_CREATE) => Promise<import("../../api").FemApiErrorResponse | null>)}} param0
 * @returns {React.JSX.Element}
 */

/**
 * @template {{id: number}} FEM_READ
 * @template FEM_WRITE_UPDATE
 * @callback UpdateForm
 * @param {{entity: FEM_READ, handleSubmit: ((original: FEM_READ | null | undefined, edited: FEM_WRITE_UPDATE) => Promise<import("../../api").FemApiErrorResponse | null>)}} param0
 * @returns {React.JSX.Element}
 */

/**
 * @template {{id: number}} FEM_READ
 * @template FEM_WRITE_CREATE, FEM_WRITE_UPDATE
 * @typedef {Object} DeletableItemsListProps
 * @prop {Array<FEM_READ>} entities
 * @prop {Array<FEM_READ>} [deletedEntities=[]]
 * @prop {(id: number) => string} entityProfilePath
 * @prop {React.JSX.Element} [title]
 * @prop {React.JSX.Element} [deletedListTitle]
 * @prop {React.JSX.Element} tableHeaderElements
 * @prop {(entity: FEM_READ) => React.JSX.Element} oneRowTableDataElements
 * @prop {React.JSX.Element} deletedEntitiesTableHeaderElements
 * @prop {(entity: FEM_READ) => React.JSX.Element} oneRowDeletedEntitiesTableDataElement
 * @prop {string} [createButtonLabel="Create"]
 * @prop {string} [editButtonLabel="Edit"]
 * @prop {string} [deleteButtonLabel="Edit"]
 * @prop {string} [purgeButtonLabel="Purge"]
 * @prop {string} [undeleteButtonLabel="Undelete"]
 * @prop {(data: FEM_WRITE_CREATE) => Request} [createRequest]
 * @prop {(id: number, data: FEM_WRITE_UPDATE) => Request} [updateRequest]
 * @prop {(id: number) => Request} deleteRequest
 * @prop {(id: number) => Request} [purgeRequest]
 * @prop {(id: number) => Request} [undeleteRequest]
 * @prop {CreateForm<FEM_READ, FEM_WRITE_CREATE>} CreateForm
 * @prop {UpdateForm<FEM_READ, FEM_WRITE_UPDATE>} UpdateForm
 * @prop {string} emptyEntityListText
 */

/**
 * 
 * @template {{id: number}} FEM_READ
 * @template FEM_WRITE_CREATE, FEM_WRITE_UPDATE
 * @param {DeletableItemsListProps<FEM_READ, FEM_WRITE_CREATE, FEM_WRITE_UPDATE>} param0 
 * @returns {React.JSX.Element}
 */
export default function DeletableItemsList({
    entities: entitiesProp,
    deletedEntities: deletedEntitiesProp = [],
    entityProfilePath,
    title,
    deletedListTitle,
    tableHeaderElements,
    oneRowTableDataElements,
    deletedEntitiesTableHeaderElements,
    oneRowDeletedEntitiesTableDataElement,
    createButtonLabel = "Create",
    editButtonLabel = "Edit",
    deleteButtonLabel = "Delete",
    purgeButtonLabel = "Purge",
    undeleteButtonLabel = "Undelete",
    createRequest,
    updateRequest,
    deleteRequest,
    purgeRequest,
    undeleteRequest,
    CreateForm,
    UpdateForm,
    emptyEntityListText,
}) {
    const [entities, setEntities] = useState(entitiesProp);
    const [deletedEntities, setDeletedEntities] = useState(deletedEntitiesProp);

    const navigate = useNavigate();

    /**
     * 
     * @param {number} id
     */
    function navigateToEntityHome(id) {
        navigate(entityProfilePath(id));
    }

    /**
     * 
     * @param {FEM_READ} entity 
     * @param {React.Dispatch<React.SetStateAction<Array<FEM_READ>>>} setStateFunction
     */
    function addToEntityListState(entity, setStateFunction) {
        setStateFunction((oldVal) => {
            const newVal = [...oldVal];
            newVal.push(entity);
            return newVal;
        });
    }

    /**
     * 
     * @param {FEM_READ} entity 
     * @param {React.Dispatch<React.SetStateAction<Array<FEM_READ>>>} setStateFunction
     */
    function removeFromEntityListState(entity, setStateFunction) {
        setStateFunction((oldVal) => {
            const index = oldVal.findIndex((e) => e.id === entity.id);
            if (index !== -1) {
                const newVal = oldVal.slice(0, index).concat(oldVal.slice(index+1));
                return newVal;
            } else {
                return oldVal;
            }
        });
    }

    /** @type {import("./api-button-with-modal").ApiButtonWithModalSuccessHandler<FEM_READ>} */
    function handleUpdate(entity) {
        setEntities((oldVal) => {
            const index = oldVal.findIndex((sp) => sp.id === entity.id);
            if (index !== -1) {
                const newVal = [...oldVal];
                newVal[index] = entity;
                return newVal;
            } else {
                return oldVal;
            }
        });
    }

    /** @type {import("./api-button-with-modal").ApiButtonWithModalSuccessHandler<FEM_READ>} */
    function handleCreate(entity) {
        addToEntityListState(entity, setEntities);
    }

    /** @type {import("./api-button").ApiButtonSuccessHandler<FEM_READ>} */
    function handleUndelete(entity) {
        removeFromEntityListState(entity, setDeletedEntities);
        addToEntityListState(entity, setEntities);
    }

    /** @type {import("./api-button").ApiButtonSuccessHandler<FEM_READ>} */
    function handleDelete(entity) {
        removeFromEntityListState(entity, setEntities);
        addToEntityListState(entity, setDeletedEntities);
    }

    /** @type {import("./api-button").ApiButtonSuccessHandler<FEM_READ>} */
    function handlePurge(entity) {
        removeFromEntityListState(entity, setDeletedEntities);
    }
    
    return (
        <>
            {title}

            <table className="table is-striped is-fullwidth">
                <thead>
                    {tableHeaderElements}
                    <th className="is-narrow has-text-right">
                        <div className="field">
                            <div className="control">
                                {createRequest
                                    ?
                                        <ApiButtonWithModal
                                            request={(/** @type {FEM_WRITE_CREATE} */ data) => createRequest(data)}
                                            label={createButtonLabel}
                                            additionalButtonStyles="is-fullwidth"
                                            onSuccess={handleCreate}
                                            modal={(submitHandler) => (
                                                <CreateForm handleSubmit={(_original, edited) => submitHandler(edited)} />
                                            )}
                                        />
                                    : <></>
                                }
                            </div>
                        </div>
                    </th>
                </thead>
                <tbody>
                    {entities.length === 0
                        ? 
                            <tr>
                                <td colSpan={3}>
                                    <div className="notification has-text-centered">
                                        {emptyEntityListText}
                                    </div>
                                </td>
                            </tr>
                        : entities.map((entity) => (
                            <tr>
                                {oneRowTableDataElements(entity)}
                                <td className="is-narrow has-text-right">
                                    <div className="field has-addons">
                                        <div className="control">
                                            <button className="button is-small" onClick={() => navigateToEntityHome(entity.id)}>View</button>
                                        </div>
                                        {updateRequest
                                            ?
                                                <ApiButtonWithModal
                                                    additionalButtonStyles="is-small"
                                                    femEntity={entity}
                                                    request={(/** @type {FEM_WRITE_UPDATE} */ data) => updateRequest(entity.id, data)}
                                                    label={editButtonLabel}
                                                    onSuccess={handleUpdate}
                                                    modal={(submitHandler) => (
                                                        <UpdateForm
                                                            entity={entity}
                                                            handleSubmit={(_original, edited) => submitHandler(edited)} />
                                                    )}
                                                />
                                            : <></>
                                        }
                                        <ApiButton
                                            additionalButtonStyles="is-small"
                                            femEntity={entity}
                                            request={(sp) => deleteRequest(sp.id)}
                                            label={deleteButtonLabel}
                                            onSuccess={handleDelete}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {deletedEntities.length === 0
            ? <></>
            : (
                <>
                    {deletedListTitle ?? <></>}
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            {deletedEntitiesTableHeaderElements}
                            {purgeRequest || undeleteRequest
                                ?  <th className="is-narrow has-text-right"></th>
                                : ""
                            }
                        </thead>
                        <tbody>
                            {deletedEntities.map(
                                (/** @type {FEM_READ} */ deletedEntity) => (
                                    <tr>
                                        {oneRowDeletedEntitiesTableDataElement(deletedEntity)}
                                        {purgeRequest || undeleteRequest
                                            ?
                                                <td className="is-narrow has-text-right">
                                                    <div className="field has-addons">
                                                        {purgeRequest
                                                            ?
                                                                <ApiButton
                                                                    additionalButtonStyles="is-small"
                                                                    femEntity={deletedEntity}
                                                                    request={(sp) => purgeRequest(sp.id)}
                                                                    label={purgeButtonLabel}
                                                                    onSuccess={handlePurge}
                                                                    useConfirmationModal={true}
                                                                />
                                                            : ""
                                                        }
                                                        {undeleteRequest
                                                            ?
                                                                <ApiButton
                                                                    additionalButtonStyles="is-small"
                                                                    femEntity={deletedEntity}
                                                                    request={(sp) => undeleteRequest(sp.id)}
                                                                    label={undeleteButtonLabel}
                                                                    onSuccess={handleUndelete}
                                                                />
                                                            : ""
                                                        }
                                                    </div>
                                                </td>
                                            : ""
                                        }
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </>
            )}
        </>
    );
}