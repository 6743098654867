// @ts-check

import React, { useState } from "react";
import ApiButtonWithModal from "./api-button-with-modal";
import { useNavigate } from "react-router-dom";
import ApiButton from "./api-button";
import { addToEntityListState, removeFromEntityListState } from "../../helpers/others";

/**
 * @template {Object} ID
 * @template {{id: ID}} FEM_READ
 * @template FEM_WRITE_CREATE
 * @callback CreateForm
 * @param {{handleSubmit: ((original: FEM_READ | null | undefined, edited: FEM_WRITE_CREATE) => Promise<import("../../api").FemApiErrorResponse | null>)}} param0
 * @returns {React.JSX.Element}
 */

/**
 * @template {Object} ID
 * @template {{id: ID}} FEM_READ
 * @template FEM_WRITE_UPDATE
 * @callback UpdateForm
 * @param {{entity: FEM_READ, handleSubmit: ((original: FEM_READ | null | undefined, edited: FEM_WRITE_UPDATE) => Promise<import("../../api").FemApiErrorResponse | null>)}} param0
 * @returns {React.JSX.Element}
 */

/**
 * @template FEM_READ
 * @typedef {"to top" | "to bottom" | ((entities: Array<FEM_READ>, newEntity: FEM_READ) => number)} AddToListBehavior
 */

/**
 * @template {Object} ID
 * @template {{id: ID}} FEM_READ
 * @template FEM_WRITE_CREATE
 * @typedef {Object} CreateButtonProps
 * @prop {string} [label="Create"]
 * @prop {(data: FEM_WRITE_CREATE) => Request} createRequest
 * @prop {CreateForm<ID, FEM_READ, FEM_WRITE_CREATE>} CreateForm
 * @prop {AddToListBehavior<FEM_READ>} [addBefore = "to bottom"]
 */

/**
 * @template {Object} ID
 * @template {{id: ID}} FEM_READ
 * @template FEM_WRITE_UPDATE
 * @typedef {Object} EditButtonProps
 * @prop {string} [label="Edit"]
 * @prop {(id: ID, data: FEM_WRITE_UPDATE) => Request} updateRequest
 * @prop {UpdateForm<ID, FEM_READ, FEM_WRITE_UPDATE>} UpdateForm
 * @prop {boolean | ((entity: FEM_READ) => boolean)} [isShown = true]
 */

/**
 * @template {Object} ID
 * @template {{id: ID}} FEM_READ
 * @typedef {Object} DeleteButtonProps
 * @prop {string} [label="Delete"]
 * @prop {(id: ID) => Request} deleteRequest
 * @prop {(entity: FEM_READ) => void} [onDelete]
 * @prop {boolean | ((entity: FEM_READ) => boolean)} [isShown = true]
 */

/**
 * @template {Object} ID
 * @typedef {Object} ViewButtonProps
 * @prop {string} [label="View"]
 * @prop {(id: ID) => string} entityProfilePath
 */

/**
 * @template {Object} ID
 * @template {{id: ID}} FEM_READ
 * @template FEM_WRITE_CREATE, FEM_WRITE_UPDATE
 * @typedef {Object} TableWithButtonsProps
 * @prop {Array<FEM_READ>} entities
 * @prop {React.Dispatch<React.SetStateAction<Array<FEM_READ>>>} [setEntities]
 * @prop {React.JSX.Element} [title]
 * @prop {React.JSX.Element} tableHeaderElements
 * @prop {(entity: FEM_READ) => React.JSX.Element} oneRowTableDataElements
 * @prop {string} emptyListText
 * @prop {Array<(param0: {createHandler: ((createdEntity: FEM_READ, addToListBehavior: AddToListBehavior<FEM_READ> | undefined) => Promise<void> | void), additionalButtonStyles?: string}) => React.JSX.Element>} [tableButtons]
 * @prop {Array<{type: "delete" | "update" | "view", elementFn: ((param0: {entity: FEM_READ, onClickHandler: ((entity: FEM_READ) => Promise<void> | void), additionalButtonStyles?: string}) => React.JSX.Element)}>} [rowButtons]
 * @prop {CreateButtonProps<ID, FEM_READ, FEM_WRITE_CREATE>} [createButton]
 * @prop {EditButtonProps<ID, FEM_READ, FEM_WRITE_UPDATE>} [editButton]
 * @prop {DeleteButtonProps<ID, FEM_READ>} [deleteButton]
 * @prop {ViewButtonProps<ID>} [viewButton]
 */

/**
 * @template {Object} ID
 * @template {{id: ID}} FEM_READ
 * @template FEM_WRITE_CREATE, FEM_WRITE_UPDATE
 * @param {TableWithButtonsProps<ID, FEM_READ, FEM_WRITE_CREATE, FEM_WRITE_UPDATE>} param0 
 * @returns {React.JSX.Element}
 */
export default function TableWithButtons({
    entities: entitiesProp,
    setEntities: setEntitiesProp,
    title,
    tableHeaderElements,
    oneRowTableDataElements,
    emptyListText,
    tableButtons,
    rowButtons,
    createButton,
    editButton,
    deleteButton,
    viewButton,
}) {
    // we always create the internal state, but only use it if setEntities prop is not provided,
    // because react does not like conditional state creation
    let [entities, setEntities] = useState(entitiesProp);
    if (setEntitiesProp) {
        [entities, setEntities] = [entitiesProp, setEntitiesProp];
    }

    const navigate = useNavigate();

    /**
     * 
     * @param {FEM_READ} entity 
     * @param {AddToListBehavior<FEM_READ>} [addToListBehavior="to bottom"]
     */
    function handleCreate(entity, addToListBehavior="to bottom") {
        if (addToListBehavior === undefined || addToListBehavior === "to bottom") {
            addToEntityListState(entity, setEntities);
        } else if (addToListBehavior === "to top") {
            addToEntityListState(entity, setEntities, {side: "front"});
        } else if (addToListBehavior instanceof Function) {
            const insertBeforePosFunction = addToListBehavior;
            setEntities((oldVal) => {
                const newVal = [...oldVal];
                const insertBeforePos = insertBeforePosFunction(oldVal, entity)
                newVal.splice(insertBeforePos === -1 ? oldVal.length : insertBeforePos, 0, entity);
                return newVal;
            });
        }
    }

    /** @type {import("./api-button-with-modal").ApiButtonWithModalSuccessHandler<FEM_READ>} */
    function handleUpdate(entity) {
        setEntities((oldVal) => {
            const index = oldVal.findIndex((sp) => sp.id === entity.id);
            if (index !== -1) {
                const newVal = [...oldVal];
                newVal[index] = entity;
                return newVal;
            } else {
                return oldVal;
            }
        });
    }

    /** @type {import("./api-button").ApiButtonSuccessHandler<FEM_READ>} */
    function handleDelete(entity) {
        removeFromEntityListState(entity, setEntities);
        if (deleteButton && deleteButton.onDelete) {
            deleteButton.onDelete(entity)
        }
    }

    const includeButtonsColumn = (
        createButton || editButton || deleteButton || viewButton
        || (tableButtons && tableButtons.length > 0)
        || (rowButtons && rowButtons.length > 0));
    
    function renderTableButtons() {
        if (createButton !== undefined || (tableButtons !== undefined && tableButtons.length > 0)) {
            return (
                <div className="field has-addons has-addons-fullwidth">
                    {createButton
                        ?
                            <ApiButtonWithModal
                                request={
                                    (/** @type {FEM_WRITE_CREATE} */ data) =>
                                        createButton.createRequest(data)}
                                label={createButton.label ?? "Create"}
                                additionalButtonStyles="is-size-7-mobile is-fullwidth"
                                onSuccess={(femEntity) => handleCreate(femEntity, createButton.addBefore)}
                                modal={(submitHandler) => (
                                    <createButton.CreateForm
                                        handleSubmit={(_original, edited) => submitHandler(edited)}
                                    />
                                )}
                            />
                        : <></>
                    }
                    {tableButtons && tableButtons.length > 0
                        ? tableButtons.map((fn) => fn({createHandler: handleCreate, additionalButtonStyles: "is-size-7-mobile is-fullwidth"}))
                        : <></>
                    }
                </div>
            )
        } else {
            return <></>;
        }
    }
    
    if (entities.length === 0) {
        if (createButton !== undefined || (tableButtons !== undefined && tableButtons.length > 0)) {
            return (
                <>
                    {title}
                    <div className="columns">
                        <div className="column is-fullwidth">
                            <div className="notification has-text-centered mt-3">
                                {emptyListText}
                            </div>
                        </div>
                        <div className="column is-narrow">
                            {renderTableButtons()}
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {title}
                    <div className="notification has-text-centered mt-3">
                        {emptyListText}
                    </div>
                </>
            );
        }
    } else {
        return (
            <>
                {title}
                <table className="table is-striped is-fullwidth">
                    <thead>
                        {tableHeaderElements}
                        {includeButtonsColumn
                            ?
                                // <th className="table-with-buttons-button-column-header">
                                <th className="is-narrow, has-text-right">
                                    {renderTableButtons()}
                                </th>
                            : <></>
                        }
                    </thead>
                    <tbody>
                        {entities.map((entity) => (
                            <tr key={entity.id.toString()}>
                                {oneRowTableDataElements(entity)}
                                {(viewButton || editButton || deleteButton || (rowButtons && rowButtons.length > 0))
                                    ?
                                        <td className="is-narrow has-text-right">
                                            <div className={`field is-fullwidth${[viewButton, editButton, deleteButton].filter((v) => v !== undefined).length + (rowButtons?.length ?? 0) > 1 ? " has-addons has-addons-fullwidth": ""}`}>
                                                {viewButton
                                                    ?
                                                        <div className="control is-fullwidth">
                                                            <button
                                                                className="button is-fullwidth is-small"
                                                                onClick={() =>
                                                                    navigate(viewButton.entityProfilePath(entity.id))}
                                                            >{viewButton.label ?? "View"}</button>
                                                        </div>
                                                    : <></>
                                                }
                                                {editButton && (editButton.isShown === undefined || (editButton.isShown instanceof Function ? editButton.isShown(entity) : editButton.isShown))
                                                    ?
                                                        <ApiButtonWithModal
                                                            femEntity={entity}
                                                            additionalButtonStyles="is-fullwidth is-small"
                                                            request={(/** @type {FEM_WRITE_UPDATE} */ data) =>
                                                                editButton.updateRequest(entity.id, data)}
                                                            label={editButton.label ?? "Edit"}
                                                            onSuccess={handleUpdate}
                                                            modal={(submitHandler) => (
                                                                <editButton.UpdateForm
                                                                    entity={entity}
                                                                    handleSubmit={(_original, edited) => submitHandler(edited)} />
                                                            )}
                                                        />
                                                    : <></>
                                                }
                                                {deleteButton && (deleteButton.isShown === undefined || (deleteButton.isShown instanceof Function ? deleteButton.isShown(entity) : deleteButton.isShown))
                                                    ?
                                                        <ApiButton
                                                            femEntity={entity}
                                                            additionalButtonStyles="is-fullwidth is-small"
                                                            request={(sp) => deleteButton.deleteRequest(sp.id)}
                                                            label={deleteButton.label ?? "Delete"}
                                                            onSuccess={handleDelete}
                                                        />
                                                    : <></>
                                                }
                                                {rowButtons && rowButtons.length > 0
                                                    ? rowButtons.map(({type, elementFn}) => elementFn({
                                                        onClickHandler: {
                                                            "delete": handleDelete,
                                                            "update": handleUpdate,
                                                            "view": () => {},
                                                        }[type],
                                                        entity,
                                                        additionalButtonStyles: "is-small",
                                                    }))
                                                    : <></>}
                                            </div>
                                        </td>
                                    : <></>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }
}