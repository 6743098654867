// @ts-check

import React from "react";

/**
 * 
 * @param {{label: string, value?: string | null} & React.PropsWithChildren} param0 
 * @returns 
 */
export default function Property({label, value, children}) {
    if ((value === undefined || value === "") && children === undefined) {
        return <></>;
    } else {
        return (
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">{label}</label>
                </div>
                <div className="field-body">
                    <p className="control is-expanded">{value ? value : children}</p>
                </div>
            </div>
        );
    }
}
