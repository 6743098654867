// @ts-check

import { paths as basePaths } from 'react-base';

const paths = {
    base: basePaths,
    equines: {
        all: "/equines",
        profile: "/equines/:equineId",
    },
    profile: "/profile",
    ponylog: "/ponylog",
    managedServiceProviders: {
        all: "/managed-service-providers",
        profile: "/managed-service-providers/:serviceProviderId",
        services: {
            profile: "/managed-service-providers/:serviceProviderId/services/:serviceId",
        },
    },
    serviceProviderEquineRelationships: {
        profile: "/service-provider-equine-relationships/:id",
    },
    connections: {
        all: "/connections",
    },
    invitations: {
        all: "/invitation",
    },
    contactServiceProviders: {
        all: "/contact-service-providers",
        profile: "/contact-service-providers/:serviceProviderId",
    },
    persons: {
        profile: "/persons/:id",
    },
    termsOfUse: "/terms-of-use",
    privacyPolicy: "/privacy-policy",
}

paths.base.appHome = paths.ponylog;
paths.base.signupLandingPage = paths.profile;
paths.base.termsOfUse = paths.termsOfUse;
paths.base.privacyPolicy = paths.privacyPolicy;

export default paths;
