// @ts-check

import React, { useRef, useState } from "react";
import { ActionButton, BulmaLevel, BulmaSelect, BulmaTextArea, DatePicker, useErrors } from "react-base";
import { horseNameToDisplay } from "../helpers/horse";
import { ponylogImageDownloadUrl, ponylogImageUploadRequest } from "../api";
import toast from "react-hot-toast";

/**
 * @callback PonyLogFormSubmitHandler 
 * @param {import("../api").FemPonylogEntryRead | null | undefined} original
 * @param {import("../api").FemPonylogEntryWrite} edited
 * @returns {Promise<import("../../../api/src/fem-types").FemApiErrorResponse | null>}
 */

/**
 * @typedef {Object} PonyLogFormProps
 * @prop {Array<import("../api").FemEquineMentionWithUserRelationshipsRead>} allHorses
 * @prop {Array<import("../api").FemEquineMentionWithUserRelationshipsRead>} [allDeletedHorses=[]]
 * @prop {number} [initialSelectedHorse]
 * @prop {import("../api").FemPonylogEntryRead} [entry = undefined]
 * @prop {string} [submitButtonLabel = "Save"]
 * @prop {PonyLogFormSubmitHandler} handleSubmit
 */

/**
 * 
 * @param {PonyLogFormProps} param0 
 * @returns {React.JSX.Element}
 */
export default function PonyLogForm({
    allHorses,
    allDeletedHorses = [],
    initialSelectedHorse,
    entry = undefined,
    submitButtonLabel = "Save",
    handleSubmit
}) {
    const TEXT_WARNING_LENGTH = 1800;
    const TEXT_LIMIT = 2000;

    const ERR_API = 'api';
    const ERR_API_SUBMIT_FAILED = 'failure';

    const {setError} = useErrors();

    const [selectedHorseId, setSelectedHorseId] = useState(
        entry ? entry.equine.id.toString() : (initialSelectedHorse ? initialSelectedHorse.toString() : allHorses[0]?.id.toString()));
    const [uploadedFileNames, setUploadedFileNames] = useState(/** @type {Array<import("../api").FemImageId>} */ (entry?.images ?? []));
    const [uploadingImage, setUploadingImage] = useState(false);
    const horseSelectOptions = 
        allHorses.map((horse) => /** @type {[string, string]} */ ([horse.id.toString(), horseNameToDisplay(horse)]))
        .concat(allDeletedHorses.map(
            (horse) => /** @type {[string, string]} */ ([horse.id.toString(), horseNameToDisplay(horse)])));

    // we use a reference to get content to avoid re-rendering the whole form when the text area input changes
    // the only other way seems to be keeping state both in the text area component and here, so this helps us avoid duplication
    const contentTextAreaRef = useRef(/** @type {HTMLTextAreaElement | null} */ (null));

    const [date, setDate] = useState(entry?.date ? new Date(entry.date) : new Date());

    /**
     * 
     * @param {import("../api").FemImageId} fileName 
     */
    function addFileName(fileName) {
        setUploadedFileNames((oldVal) => [...oldVal, fileName]);
    }

    /**
     * 
     * @param {import("../api").FemImageId} fileName
     * @returns {void}
     */
    function removeFileName(fileName) {
        setUploadedFileNames((oldVal) => {
            const index = oldVal.indexOf(fileName);
            if (index !== -1) {
                const newVal = oldVal.slice(0, index).concat(oldVal.slice(index+1));
                return newVal;
            } else {
                return oldVal;
            }
        });
    }

    /**
     * 
     * @param {import("../api").FemImageId} fileName 
     * @returns {void}
     */
    function addFileName(fileName) {
        setUploadedFileNames((oldVal) => [...oldVal, fileName]);
    }

    /**
     * Get content from the enclosed text area in the form.
     * @returns {string}
     */
    function getContent() {
        return contentTextAreaRef.current?.value ?? "";
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleFormButtonClick(callback) {
        const content = getContent();
        if (content === "" && uploadedFileNames.length === 0) {
            toast("You must add content or images.");
        } else {
            /** @type {import("../api").FemPonylogEntryWrite} */
            const editedData = {
                equine_id: Number(selectedHorseId),
                content: content,
                date: date.getTime(),
                images: uploadedFileNames,
            };
            const errorResponse = await handleSubmit(entry, editedData);
            if (errorResponse) {
                toast.error("Error saving the entry.");
                setError(ERR_API, ERR_API_SUBMIT_FAILED, errorResponse.errors);
            }
        }
        callback();
    }

    /**
     * 
     * @param {React.ChangeEvent<HTMLInputElement>} event 
     */
    async function handleImage(event) {
        if (event.target.files && event.target.files.length > 0) {
            const fileName = window.crypto.randomUUID();
            const request = ponylogImageUploadRequest(fileName, event.target.files[0]);
            event.target.value = "";
            setUploadingImage(true); // TODO: implement cancellation of any running queries
            const response = await fetch(request);
            if (response.ok) {
                addFileName(fileName);
            } else {
                toast.error("Error uploading the image.");
            }
            setUploadingImage(false);
        }
    }

    /**
     * 
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event 
     * @param {import("../api").FemImageId} fileName
     * @returns {Promise<void>}
     */
    async function handleImageDeleteClick(event, fileName) {
        event.stopPropagation();
        // TODO: signal the API to mark the image as "to be removed". That can make cleanup easier.
        removeFileName(fileName);
    }

    return (
        <form className="has-text-left">
            <BulmaLevel
                left={[
                    <DatePicker id="entrydate" value={date} onChange={(newValue) => setDate(newValue ?? new Date())} placeholder="Entry date"/>
                ]}
                right={[
                    <div className="field">
                        <BulmaSelect value={selectedHorseId}
                            onChange={(e) => setSelectedHorseId(e.target.value)}
                            values={horseSelectOptions} />
                    </div>
                ]}
            />
            <div className="field">
                <BulmaTextArea 
                    contentReference={contentTextAreaRef}
                    initialText={entry?.content}
                    rows={10}
                    placeholder="You can write your log here."
                    warningLimit={TEXT_WARNING_LENGTH}
                    characterLimit={TEXT_LIMIT}
                />
            </div>
            {uploadedFileNames.map((fileName) => (
                <div key={fileName} className="field">
                    <div className="columns is-mobile">
                        <div className="column has-text-centered">
                            <img src={ponylogImageDownloadUrl(fileName)} />
                        </div>
                        <div className="column is-narrow">
                            <button className="delete" onClick={(event) => handleImageDeleteClick(event, fileName)}></button>
                        </div>
                    </div>
                </div>
            ))}
            <div className="field">
                <div className="file is-boxed is-fullwidth">
                    <label className="file-label">
                        <input className="file-input"
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            disabled={uploadingImage}
                        />
                        <span className="file-cta has-text-centered">
                            <span className="file-label">{uploadingImage ? "… please wait … uploading …" : "Add an image…"}</span>
                        </span>
                    </label>
                </div>
            </div>
            <BulmaLevel
                right={[
                    <ActionButton
                        onClick={handleFormButtonClick}
                        disabled={uploadingImage}
                    >{submitButtonLabel}</ActionButton>
                ]}
            />
        </form>
    );
}