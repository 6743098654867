// @ts-check

import React from "react";
import { BulmaSelect, DatePicker } from "react-base";

/**
 * If a value in the given array matches, remove and return a new array without
 * the item. If no value matches, return the same array.
 * 
 * This function is intended to be used with updating react states where the
 * state value type is an array.
 * 
 * @template T
 * @param {T | ((value: T) => boolean)} valueOrFindFunction 
 * @param {Array<T>} array 
 * @returns {Array<T>}
 */
export function removeAndReturnNewArrayIfRemoved(valueOrFindFunction, array) {
    const index = (valueOrFindFunction instanceof Function
        ? array.findIndex(valueOrFindFunction)
        : array.findIndex((val) => val === valueOrFindFunction)
    );
    if (index === -1) {
        // item not found, return the same array
        return array;
    } else {
        // item found, return a new array without the item
        return array.slice(0, index).concat(array.slice(index+1, array.length));
    }
}

/**
 * @typedef {Object} FieldProps
 * @prop {string} label
 * @prop {React.JSX.Element | Array<React.JSX.Element>} children
 */
/**
 * 
 * @param {FieldProps} param0 
 * @returns {React.JSX.Element}
 */
export function field({
    label,
    children
}) {
    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="control">
                {children}
            </div>
        </div>
    );
}

/**
 * @typedef {Object} SelectFieldProps
 * @prop {string} label
 * @prop {string} value
 * @prop {Array<[string, React.JSX.Element | string]>} values
 * @prop {import("react").ChangeEventHandler<HTMLSelectElement>} onChange
 */
/**
 * 
 * @param {SelectFieldProps} param0 
 * @returns {React.JSX.Element}
 */
export function selectField({
    label,
    value,
    values,
    onChange,
}) {
    return field({
        label,
        children:
            <BulmaSelect
                value={value}
                onChange={onChange}
                values={values}
            />
    });
}

/**
 * @typedef {Object} TextFieldProps
 * @prop {string} label
 * @prop {string} [placeholder]
 * @prop {string} value
 * @prop {import("react").ChangeEventHandler<HTMLInputElement>} onChange
 * @prop {string} [id]
 * @prop {"text" | "email" | "tel"} [type = "text"]
 * @prop {React.MutableRefObject<HTMLInputElement | null>} [ref]
 * @prop {string} [pattern]
 * @prop {boolean} [disabled]
 */
/**
 * 
 * @param {TextFieldProps} param0 
 * @returns {React.JSX.Element}
 */
export function textField({
    label,
    placeholder,
    value,
    onChange,
    id,
    type = "text",
    ref,
    pattern,
    disabled,
}) {
    return field({
        label,
        children:
            <input
                ref={ref}
                id={id}
                className="input"
                disabled={disabled}
                type={type}
                autoComplete={type === "email" ? "email" : undefined}
                placeholder={placeholder ?? undefined}
                value={value}
                pattern={pattern}
                onChange={onChange}
            />
    });
}

/**
 * @typedef {Object} TextAreaFieldProps
 * @prop {string} label
 * @prop {string} [placeholder]
 * @prop {string} value
 * @prop {import("react").ChangeEventHandler<HTMLTextAreaElement>} onChange
 * @prop {string} [id]
 * @prop {React.MutableRefObject<HTMLTextAreaElement | null>} [ref]
 * @prop {boolean} [disabled]
 */
/**
 * 
 * @param {TextAreaFieldProps} param0 
 * @returns {React.JSX.Element}
 */
export function textAreaField({
    label,
    placeholder,
    value,
    onChange,
    id,
    ref,
    disabled,
}) {
    return field({
        label,
        children:
            <textarea
                ref={ref}
                id={id}
                className="textarea"
                disabled={disabled}
                placeholder={placeholder ?? undefined}
                value={value}
                onChange={onChange}
            />
    });
}

/**
 * @typedef {Object} DateFieldProps
 * @prop {string} label
 * @prop {string} [placeholder]
 * @prop {string} [id]
 * @prop {Date} [value]
 * @prop {(newValue: Date | null) => void | Promise<void>} onChange
 * @prop {boolean} [isEmptyAllowed=false]
 */
/**
 * 
 * @param {DateFieldProps} param0 
 * @returns {React.JSX.Element}
 */
export function dateField({
    label,
    placeholder,
    value,
    onChange,
    id,
    isEmptyAllowed = false,
}) {
    return field({
        label,
        children:
            <DatePicker
                id={id ?? "date_field"}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                isEmptyAllowed={isEmptyAllowed}
            />
    });
}


/**
 * @template T
 * @typedef {Object} CheckValueErrorConditionFunctionProps
 * @prop {(() => boolean) | boolean} errorCondition
 */
/**
 * @template T
 * @typedef {Object} CheckValueSuccessConditionFunctionProps
 * @prop {(() => boolean) | boolean} successCondition
 */
/**
 * @typedef {Object} CheckValueErrorData
 * @prop {string} errFieldName
 * @prop {string} errConditionName 
 * @prop {string} errMessage 
 */
/**
 * @typedef {Object} CheckValueErrorConditionRegexProps
 * @prop {string} value
 * @prop {RegExp} errorCondition
 */
/**
 * @typedef {Object} CheckValueSuccessConditionRegexProps
 * @prop {string} value
 * @prop {RegExp} successCondition
 */
/** @typedef {CheckValueErrorConditionRegexProps | CheckValueSuccessConditionRegexProps} CheckValueRegexProps */
/** 
 * @template T
 * @typedef {CheckValueErrorConditionFunctionProps<T> | CheckValueErrorConditionRegexProps} CheckValueErrorCondition
 */
/** 
 * @template T
 * @typedef {CheckValueSuccessConditionFunctionProps<T> | CheckValueSuccessConditionRegexProps} CheckValueSuccessCondition
 */
/**
 * @template T
 * @typedef {(CheckValueErrorCondition<T> | CheckValueSuccessCondition<T>) & CheckValueErrorData} CheckValueProps
 */
/**
 * Check the given value with checkFunction.
 * If checkFunction returns false, setError, if not, resetError with given params.
 * 
 * @template T
 * @param {CheckValueProps<T> | Array<CheckValueProps<T>>} checks 
 * @param {import("react-base/dist/hooks/use-errors").SetErrorFunction} setError
 * @param {import("react-base/dist/hooks/use-errors").ResetErrorFunction} resetError
 */
export function checkValue(checks, setError, resetError) {
    const checkDataArray = (checks instanceof Array) ? checks : [checks];

    let isValid = true;
    for (const checkData of checkDataArray) {
        const {errFieldName, errConditionName, errMessage} = checkData;
        let condition, modifier;
        if (Object.hasOwn(checkData, "errorCondition")) {
            condition = /** @type {CheckValueErrorCondition<T>} */ (checkData).errorCondition;
            modifier = (/** @type {boolean} */ value) => value;
        } else { // checkData has successCondition
            condition = /** @type {CheckValueSuccessCondition<T>} */ (checkData).successCondition;
            modifier = (/** @type {boolean} */ value) => !value;

        }
        let isError;
        if (condition instanceof RegExp) {
            const value = /** @type {CheckValueRegexProps} */ (checkData).value;
            isError = modifier(condition.test(value));
        } else { // check instanceof Function || check instanceof boolean
            isError = modifier(condition instanceof Function ? condition() : condition);
        }
        if (isError) {
            isValid = false;
            setError(errFieldName, errConditionName, errMessage);
        } else {
            resetError(errFieldName, errConditionName);
        }
    }
    return isValid;
}

/**
 * @template VALUE_TYPE, CHECK_VALIDITY_PROPS_TYPE
 * @param {VALUE_TYPE} value 
 * @param {(value: React.SetStateAction<VALUE_TYPE>) => void} setFunction
 * @param {undefined | keyof CHECK_VALIDITY_PROPS_TYPE} validityKey
 * @param {(checks?: CHECK_VALIDITY_PROPS_TYPE) => void} validityCheckFunction
 * @param {() => void} anyChangeCallback
 * @returns {void}
 */
export function setAndCheck(value, setFunction, validityKey, validityCheckFunction, anyChangeCallback) {
    anyChangeCallback();
    setFunction(value);
    validityCheckFunction(/** @type {CHECK_VALIDITY_PROPS_TYPE} */ (validityKey ? {[validityKey]: value} : {}));
}

/**
 * 
 * @param {number} msSinceEpoch 
 * @returns {string}
 */
export function dateToString(msSinceEpoch) {
    const retval = new Date(msSinceEpoch);
    return retval.toLocaleDateString("en-US");
}

/**
 * @template FEM
 * @typedef {Object} AddToEntityListStateOptions
 * @prop {"front" | "end"} [side]
 * @prop {number} [limit] Only works when side is "front"
 * @prop {true | ((a: FEM, b: FEM) => boolean)} [allowDuplicates] Either true, or an "equals" function
 */
/**
 * 
 * @template FEM
 * @param {FEM} entity 
 * @param {React.Dispatch<React.SetStateAction<Array<FEM>>>} setStateFunction
 * @param {AddToEntityListStateOptions<FEM>} [options = {side: "end", allowDuplicates: true}]
 */
export function addToEntityListState(entity, setStateFunction, options = {side: "end", allowDuplicates: true}) {
    setStateFunction((oldVal) => {
        if (options.allowDuplicates instanceof Function
            && oldVal.some((val) => /** @type {(a: FEM, b: FEM) => boolean} */ (options.allowDuplicates)(val, entity))
        ) {
            return oldVal;
        }
        const newVal = [];
        if (options.side === "end") {
            newVal.push(...(oldVal), entity);
        } else {
            newVal.push(
                entity,
                ...(options.limit && oldVal.length >= options.limit ? oldVal.slice(0, options.limit-1) : oldVal));
        }
        return newVal;
    });
}

/**
 * 
 * @template {{id: any}} FEM
 * @param {FEM} entity 
 * @param {React.Dispatch<React.SetStateAction<Array<FEM>>>} setStateFunction
 */
export function removeFromEntityListState(entity, setStateFunction) {
    setStateFunction((oldVal) => {
        const index = oldVal.findIndex((e) => e.id === entity.id);
        if (index !== -1) {
            const newVal = oldVal.slice(0, index).concat(oldVal.slice(index+1));
            return newVal;
        } else {
            return oldVal;
        }
    });
}
