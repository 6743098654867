// @ts-check

import React, { useRef, useState } from "react";
import { useErrors, ActionButton, BulmaLevel } from "react-base";
import toast from "react-hot-toast";
import { addToEntityListState, checkValue, field, removeFromEntityListState, selectField, setAndCheck, textField } from "../helpers/others";
import ApiSearchInput from "./general-purpose/api-search-input";
import { personsSearchRequest } from "../api";

/**
 * @typedef {Object} UserFormProps
 * @prop {import("../api").FemPersonRead} [person]
 * @prop {(original: import("../api").FemPersonRead | undefined, edited: import("../api").FemPersonWriteUpdate & import("../api").FemPersonWriteCreate) => Promise<import("../api").FemApiErrorResponse | null>} onSubmit
 * @prop {string} [submitButtonLabel="Save"]
 */

/**
 * 
 * @param {UserFormProps} param0 
 * @returns {React.JSX.Element}
 */
export default function PersonForm({
    person: personParam,
    onSubmit,
    submitButtonLabel = "Save",
}) {
    const person = (personParam ? personParam : /** @type {import("../api").FemPersonRead} */ ({}));

    const OPTIONAL_NUMBER_REGEX = /^\d*$/;
    const OPTIONAL_FEI_ID_REGEX = /^(?:[0-9]{3,3}[a-zA-Z]{2,2}[0-9]{2,2})?$/;
    const MAX_ALLERGIES_LENGTH = 100;
    const MAX_MEDICAL_INSURANCE_COMPANY_LENGTH = 100;
    const MAX_MEDICAL_INSURANCE_ID_LENGTH = 100;

    const ERROR_FIELD_DISPLAY_NAME = 'displayName';
    const ERROR_DISPLAY_NAME_TOO_LONG = 'length-long';
    const ERROR_DISPLAY_NAME_LENGTH_MESSAGE_TOO_LONG = 'Display name must be at most 32 characters';

    const ERROR_DISPLAY_NAME_TOO_SHORT = 'length-short';
    const ERROR_DISPLAY_NAME_LENGTH_MESSAGE_TOO_SHORT = 'Display name must be at least 2 characters';

    const ERROR_FIELD_FULLNAME = 'fullName';
    const ERROR_FULLNAME_LENGTH = 'length';
    const ERROR_FULLNAME_LENGTH_MESSAGE_TOO_LONG = 'Full name must be at most 100 characters';

    const ERROR_FIELD_EMAIL = 'email';
    const ERROR_EMAIL_FORMAT = 'format';
    const ERROR_EMAIL_FORMAT_MESSAGE_INVALID = 'Invalid email format.';

    const ERR_FIELD_USEF_NUMBER = 'usef-number';
    const ERR_USEF_NUMBER_CONTAINS_NON_DIGITS = 'contains-non-digits';

    const ERR_FIELD_USHJA_NUMBER = 'ushja-number';
    const ERR_USHJA_NUMBER_CONTAINS_NON_DIGITS = 'contains-non-digits';

    const ERR_FIELD_FEI_ID = 'fei-id';
    const ERR_FEI_ID_FORMAT = 'format';

    const ERR_FIELD_ALLERGIES = 'allergies';
    const ERR_ALLERGIES_TOO_LONG = 'too-long';

    const ERR_FIELD_AMAT_PROF_STATUS = 'amateur-professional-status';
    const ERR_AMAT_PROF_STATUS_NOT_IN_ALLOWED = 'not-in-allowed';

    const ERR_FIELD_MEDICAL_INSURANCE_COMPANY = 'medical-insurance-company';
    const ERR_MEDICAL_INSURANCE_COMPANY_TOO_LONG = 'too-long';

    const ERR_FIELD_MEDICAL_INSURANCE_ID = 'medical-insurance-id';
    const ERR_MEDICAL_INSURANCE_ID_TOO_LONG = 'too-long';

    const PHONE_REGEX_STR = "^[+().\\d\\- ]*$";
    const PHONE_REGEX = new RegExp(PHONE_REGEX_STR); // /^[\+()\.\d- ]*$/;
    const ERROR_FIELD_PHONE = 'phone';
    const ERROR_PHONE_FORMAT = 'format';
    const ERROR_PHONE_FORMAT_MESSAGE_INVALID = 'Only +, (, ), ., -, and digits are allowed in phone numbers.';

    const ERR_API = 'api';
    const ERR_API_SUBMIT_FAILED = 'failure';

    const [fullName, setFullName] = useState(person.full_name ?? '');
    const [displayName, setDisplayName] = useState(person.display_name);
    const [email, setEmail] = useState(person.email ?? '');
    const [usefNumber, setUsefNumber] = useState(person.usef_number ?? '');
    const [ushjaNumber, setUshjaNumber] = useState(person.ushja_number ?? '');
    const [feiId, setFeiId] = useState(person.fei_id ?? '');
    const [allergies, setAllergies] = useState(person.allergies ?? '');
    const [amateurProfessionalStatus, setAmateurProfessionalStatus] =
        useState(/** @type {string} */ (person.amateur_professional_status ?? ''));
    const [medicalInsuranceCompany, setMedicalInsuranceCompany] = useState(person.medical_insurance_company ?? '');
    const [medicalInsuranceId, setMedicalInsuranceId] = useState(person.medical_insurance_id ?? '');
    const [phone, setPhone] = useState(person.phone ?? "");
    const [selectedPersonForEmergencyContact, setSelectedPersonForEmergencyContact] =
        useState(/** @type {import("../api").FemPersonMention | null} */ (null))
    const [emergencyContacts, setEmergencyContacts] = useState(person.emergency_contacts ?? []);
    const {setError, resetError, renderErrors} = useErrors();

    const emailFieldRef = useRef(/** @type {HTMLInputElement | null} */ (null));
    const [isValid, setIsValid] = useState(true);

   /**
     * @typedef {Object} CheckValidityProps
     * @prop {string} [currentFullName = fullName]
     * @prop {string} [currentDisplayName = displayName]
     * @prop {string} [currentUsefNumber = usefNumber]
     * @prop {string} [currentUshjaNumber = ushjaNumber]
     * @prop {string} [currentFeiId = feiId]
     * @prop {string} [currentAllergies = allergies]
     * @prop {string} [currentAmateurProfessionalStatus = amateurProfessionalStatus]
     * @prop {string} [currentMedicalInsuranceCompany = medicalInsuranceCompany]
     * @prop {string} [currentMedicalInsuranceId = medicalInsuranceId]
     * @prop {string} [currentPhone = phone]
     * @prop {React.MutableRefObject<HTMLInputElement | null>} [currentEmailFieldRef = emailFieldRef]
     */
   /**
     * 
     * @param {CheckValidityProps} [param0 = {}]
     * @returns {boolean}
     */
    function checkValidity({
        currentFullName = fullName,
        currentDisplayName = displayName,
        currentEmailFieldRef = emailFieldRef,
        currentUsefNumber = usefNumber,
        currentUshjaNumber = ushjaNumber,
        currentFeiId = feiId,
        currentAllergies = allergies,
        currentAmateurProfessionalStatus = amateurProfessionalStatus,
        currentMedicalInsuranceCompany = medicalInsuranceCompany,
        currentMedicalInsuranceId = medicalInsuranceId,
        currentPhone = phone,
    } = {}) {

        const checks = [
            // fullName
            checkValue(
                {
                    successCondition: currentFullName === undefined || currentFullName.length <= 100,
                    errFieldName: ERROR_FIELD_FULLNAME,
                    errConditionName: ERROR_FULLNAME_LENGTH,
                    errMessage: ERROR_FULLNAME_LENGTH_MESSAGE_TOO_LONG,
                }, setError, resetError),

            // displayName
            checkValue([
                {
                    errorCondition: currentDisplayName !== undefined && currentDisplayName.length > 32,
                    errFieldName: ERROR_FIELD_DISPLAY_NAME,
                    errConditionName: ERROR_DISPLAY_NAME_TOO_LONG,
                    errMessage: ERROR_DISPLAY_NAME_LENGTH_MESSAGE_TOO_LONG
                },
                {
                    errorCondition: currentDisplayName === undefined || currentDisplayName.length < 2,
                    errFieldName: ERROR_FIELD_DISPLAY_NAME,
                    errConditionName: ERROR_DISPLAY_NAME_TOO_SHORT,
                    errMessage: ERROR_DISPLAY_NAME_LENGTH_MESSAGE_TOO_SHORT
                }
            ], setError, resetError),

            // email
            checkValue(
                {
                    successCondition: currentEmailFieldRef.current?.validity.valid ?? false,
                    errFieldName: ERROR_FIELD_EMAIL,
                    errConditionName: ERROR_EMAIL_FORMAT,
                    errMessage: ERROR_EMAIL_FORMAT_MESSAGE_INVALID,
                }, setError, resetError),

            // usefNumber
            checkValue({
                    value: currentUsefNumber,
                    successCondition: OPTIONAL_NUMBER_REGEX,
                    errFieldName: ERR_FIELD_USEF_NUMBER,
                    errConditionName: ERR_USEF_NUMBER_CONTAINS_NON_DIGITS,
                    errMessage: "USEF number should contain only digits.",
                }, setError, resetError),

            // ushjaNumber
            checkValue(
                {
                    value: currentUshjaNumber,
                    successCondition: OPTIONAL_NUMBER_REGEX,
                    errFieldName: ERR_FIELD_USHJA_NUMBER,
                    errConditionName: ERR_USHJA_NUMBER_CONTAINS_NON_DIGITS,
                    errMessage: "USHJA number should contain only digits.",
                }, setError, resetError),
            
            // feiId
            checkValue(
                {
                    value: currentFeiId,
                    successCondition: OPTIONAL_FEI_ID_REGEX,
                    errFieldName: ERR_FIELD_FEI_ID,
                    errConditionName: ERR_FEI_ID_FORMAT,
                    errMessage: "FEI id is expected to be 3-digits, 2-letters, 2-digits, e.g.: 100AB12",
                }, setError, resetError),

            // allergies
            checkValue(
                {
                    errorCondition: currentAllergies.length > MAX_ALLERGIES_LENGTH,
                    errFieldName: ERR_FIELD_ALLERGIES,
                    errConditionName: ERR_ALLERGIES_TOO_LONG,
                    errMessage: `Allergies should be shorter than ${MAX_ALLERGIES_LENGTH} characters.`,
                }, setError, resetError),

            // amateur/professional status
            checkValue(
                {
                    successCondition: ["", "amateur", "professional"].includes(currentAmateurProfessionalStatus),
                    errFieldName: ERR_FIELD_AMAT_PROF_STATUS,
                    errConditionName: ERR_AMAT_PROF_STATUS_NOT_IN_ALLOWED,
                    errMessage: `You must select on of the available options.`,
                }, setError, resetError),
        
            // medical insurance company
            checkValue(
                {
                    errorCondition: currentMedicalInsuranceCompany.length > MAX_MEDICAL_INSURANCE_COMPANY_LENGTH,
                    errFieldName: ERR_FIELD_MEDICAL_INSURANCE_COMPANY,
                    errConditionName: ERR_MEDICAL_INSURANCE_COMPANY_TOO_LONG,
                    errMessage: `Medical insurance company name should be shorter than ${MAX_MEDICAL_INSURANCE_COMPANY_LENGTH} characters.`,
                }, setError, resetError),

            // medical insurance id
            checkValue(
                {
                    errorCondition: currentMedicalInsuranceId.length > MAX_MEDICAL_INSURANCE_ID_LENGTH,
                    errFieldName: ERR_FIELD_MEDICAL_INSURANCE_ID,
                    errConditionName: ERR_MEDICAL_INSURANCE_ID_TOO_LONG,
                    errMessage: `Medical insurance company name should be shorter than ${MAX_MEDICAL_INSURANCE_ID_LENGTH} characters.`,
                }, setError, resetError),

            // phone
            checkValue({
                value: currentPhone,
                successCondition: PHONE_REGEX,
                errFieldName: ERROR_FIELD_PHONE,
                errConditionName: ERROR_PHONE_FORMAT,
                errMessage: ERROR_PHONE_FORMAT_MESSAGE_INVALID,
            }, setError, resetError),
        ]
        const validity = (checks.find((v) => v === false) === undefined);
        setIsValid(validity);
        return validity;
    }

    function anyChange() {
        resetError(ERR_API, ERR_API_SUBMIT_FAILED);
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleFormButtonClick(cb) {
        if (checkValidity()) {
            /** @type {import("../api").FemPersonWriteUpdate} */
            const newPerson = {
                full_name: fullName,
                display_name: displayName,
                email: email,
                usef_number: usefNumber,
                ushja_number: ushjaNumber,
                fei_id: feiId,
                allergies: allergies,
                // the type of checked by checkValidity, so this is safe as long as checkValidity is correct
                amateur_professional_status: /** @type {"amateur" | "professional" | ""} */ (amateurProfessionalStatus),
                medical_insurance_company: medicalInsuranceCompany,
                medical_insurance_id: medicalInsuranceId,
                phone,
                emergency_contact_person_ids: emergencyContacts.map((ec) => ec.id),
            }
            const errorOrNull = await onSubmit(personParam, newPerson);
            if (errorOrNull) {
                toast.error("Error saving person.")
                setError(ERR_API, ERR_API_SUBMIT_FAILED, errorOrNull.errors);
            }
        }
        cb();
    }

    /**
     * 
     * @param {import("../api").FemPersonMention | null} selectedPerson 
     */
    function handleAddSelectPersonForEmergencyContact(selectedPerson) {
        if (selectedPerson && !emergencyContacts.map((em) => em.id).includes(selectedPerson.id)) {
            addToEntityListState(selectedPerson, setEmergencyContacts);
        }
    }

    return (
        <form className="has-text-left">
            {textField({
                label: "Full name",
                placeholder: "Full name",
                value: fullName,
                onChange: (event) =>
                    setAndCheck(event.target.value, setFullName, "currentFullName", checkValidity, anyChange),
            })}

            {textField({
                label: "Display name",
                placeholder: "Display name",
                value: displayName,
                onChange: (event) =>
                    setAndCheck(event.target.value, setDisplayName, "currentDisplayName", checkValidity, anyChange),
            })}

            {textField({
                ref: emailFieldRef,
                type: "email",
                label: "Email",
                value: email,
                onChange: (event) =>
                    setAndCheck(event.target.value, setEmail, undefined, checkValidity, anyChange),
            })}

            {textField({
                type: "tel",
                label: "Phone",
                placeholder: "Phone",
                value: phone,
                pattern: PHONE_REGEX_STR,
                onChange: (event) =>
                    setAndCheck(event.target.value, setPhone, "currentPhone", checkValidity, anyChange),
            })}

            {field({
                label: "Emergency contacts",
                children:
                    <>
                        <div className="field has-addons">
                            <div className="control">
                                <ApiSearchInput
                                    value={selectedPersonForEmergencyContact}
                                    entityToLabel={(entity) => entity.display_name}
                                    searchRequest={(searchText) => personsSearchRequest({searchText})}
                                    onSelect={setSelectedPersonForEmergencyContact}
                                />
                                <button
                                    className="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleAddSelectPersonForEmergencyContact(selectedPersonForEmergencyContact)
                                    }}>Add</button>
                            </div>
                        </div>
                        <br/>
                        {emergencyContacts.length > 0
                            ?
                                <div className="tags">
                                    {emergencyContacts.map((contact) => 
                                        <span className="tag">
                                            {contact.display_name}
                                            <button
                                                className="delete is-small"
                                                onClick={() =>
                                                    removeFromEntityListState(contact, setEmergencyContacts)}/>
                                        </span>
                                    )}
                                </div>
                            : ""
                        }
                    </>
            })}

            {textField({
                label: "USEF number",
                placeholder: "USEF number",
                value: usefNumber,
                onChange: (event) =>
                    setAndCheck(event.target.value, setUsefNumber, "currentUsefNumber", checkValidity, anyChange),
            })}

            {textField({
                label: "USHJA number",
                placeholder: "USHJA number",
                value: ushjaNumber,
                onChange: (event) =>
                    setAndCheck(event.target.value, setUshjaNumber, "currentUshjaNumber", checkValidity, anyChange),
            })}
            {textField({
                label: "FEI id",
                placeholder: "FEI id, e.g. 100AB12",
                value: feiId,
                onChange: (event) => 
                    setAndCheck(event.target.value, setFeiId, "currentFeiId", checkValidity, anyChange),
            })}
            {textField({
                label: "Allergies",
                placeholder: "Allergies",
                value: allergies,
                onChange: (event) => 
                    setAndCheck(event.target.value, setAllergies, "currentAllergies", checkValidity, anyChange),
            })}
            {selectField({
                label: "Status",
                value: amateurProfessionalStatus,
                values: [
                    ["", "Unknown"],
                    ["amateur", "Amateur"],
                    ["professional", "Professional"],
                ],
                onChange: (event) =>
                    setAndCheck(
                        event.target.value,
                        setAmateurProfessionalStatus,
                        "currentAmateurProfessionalStatus",
                        checkValidity,
                        anyChange),
            })}
            {textField({
                label: "Medical insurance company",
                placeholder: "Name of the medical insurance company",
                value: medicalInsuranceCompany,
                onChange: (event) => 
                    setAndCheck(
                        event.target.value,
                        setMedicalInsuranceCompany,
                        "currentMedicalInsuranceCompany",
                        checkValidity,
                        anyChange),
            })}
            {textField({
                label: "Medical insurance id",
                placeholder: "Id for the medical insurance",
                value: medicalInsuranceId,
                onChange: (event) => 
                    setAndCheck(
                        event.target.value,
                        setMedicalInsuranceId,
                        "currentMedicalInsuranceId",
                        checkValidity,
                        anyChange),
            })}

            {renderErrors()}

            <BulmaLevel
                right={[
                    <ActionButton disabled={!isValid} onClick={handleFormButtonClick}>{submitButtonLabel}</ActionButton>
                ]}
            />
        </form>
    );
}
