// @ts-check

import React, { useEffect, useRef } from "react";

/** @typedef {"checked" | "unchecked"} RoleCheckboxDeterminateState */
/** @typedef {RoleCheckboxDeterminateState | "indeterminate"} RoleCheckboxState */
/**
 * @callback TriStateCheckboxChangeHandler 
 * @param {RoleCheckboxDeterminateState} newState
 * @returns {Promise<void> | void}
 */

/**
 * @typedef {Object} TriStateCheckboxProps
 * @prop {RoleCheckboxState} state
 * @prop {RoleCheckboxDeterminateState} [stateAfterIndeterminate = "checked"]
 * @prop {boolean} disabled
 * @prop {TriStateCheckboxChangeHandler} onChange
 */

/**
 * 
 * @param {TriStateCheckboxProps} param0 
 * @returns 
 */
export default function TriStateCheckbox({
    state,
    stateAfterIndeterminate = "checked",
    disabled,
    onChange,
}) {
    const ref = /** @type {React.MutableRefObject<HTMLInputElement>} */ (useRef());

    useEffect(() => {
        if (state === "indeterminate") {
            ref.current.indeterminate = true;
        } else {
            ref.current.indeterminate = false;
        }
    });
    
    return (
        <input
            ref={ref}
            type="checkbox"
            checked={state === "checked"
                ? true
                : (state === "unchecked"
                    ? false
                    : (stateAfterIndeterminate === "checked" ? true : false))}
            onChange={(e) => onChange(e.target.checked ? "checked" : "unchecked")}
            disabled={disabled}
        />
    );
}