// @ts-check

/**
 * @typedef {Object} RelationshipCreateOrEditButtonProps
 * @prop {string} [additionalButtonStyles]
 * @prop {string} [label="Add"]
 * @prop {import("../api").FemRoleToDisplayNameMapping} allRelationships
 * @prop {import("../api").FemRelationshipPersonSearchResultItem} [initialPerson]
 * @prop {number} equineId
 * @prop {(savedRelationship: import("../api").FemPersonEquineRelationshipForRoleAssignmentRead) => void | Promise<void>} [onRelationshipSaved]
 * @prop {(personId: number) => void | Promise<void>} onRelationshipRemoved
 * @prop {Array<import("../api").FemRole>} [initialRolesSelection=[]]
 * @prop {string} [submitButtonLabel="Add"]
 * @prop {boolean} [allowEmptyRolesSelection=false]
 */

import React, { useState } from "react";
import { Modal } from "react-base";
import RelationshipForm from "./relationship-form";

/**
 * 
 * @param {RelationshipCreateOrEditButtonProps} param0 
 * @returns {React.JSX.Element}
 */
export default function RelationshipCreateOrEditButton({
    additionalButtonStyles,
    label = "Add",
    allRelationships,
    initialPerson,
    equineId,
    onRelationshipSaved,
    onRelationshipRemoved,
    initialRolesSelection,
    submitButtonLabel = "Add",
    allowEmptyRolesSelection = false,
}) {
    const [isModalShowing, setIsModalShowing] = useState(false);

    /** @type {(savedRelationship: import("../api").FemPersonEquineRelationshipForRoleAssignmentRead) => void | Promise<void>} */
    function handleRelationshipSaved(savedRelationship) {
        setIsModalShowing(false);
        if (onRelationshipSaved) {
            onRelationshipSaved(savedRelationship);
        }
    }

    /** @type {(personId: number) => void | Promise<void>} */
    function handleRelationshipRemoved(personId) {
        setIsModalShowing(false);
        if (onRelationshipRemoved) {
            onRelationshipRemoved(personId);
        }
    }

    return (
        <>
            <Modal isShowing={isModalShowing} setIsShowing={setIsModalShowing}>
                <RelationshipForm
                    allRelationships={allRelationships}
                    equineId={equineId}
                    onRelationshipSaved={handleRelationshipSaved}
                    onRelationshipRemoved={handleRelationshipRemoved}
                    initialPerson={initialPerson}
                    initialRolesSelection={initialRolesSelection}
                    submitButtonLabel={submitButtonLabel}
                    allowEmptyRolesSelection={allowEmptyRolesSelection}
                />
            </Modal>
            <div className="control">
                <button className={["button", additionalButtonStyles].join(" ")} onClick={() => setIsModalShowing(true)}>{label}</button>
            </div>
        </>
    );
}