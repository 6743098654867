// @ts-check

import React, { useState } from "react";
import { Modal, useApi } from "react-base";
import ServiceProviderEquineRelationshipResponseForm from "./service-provider-equine-relationship-response-form";
import { serviceProviderEquineRelationshipApproveRequest, serviceProviderEquineRelationshipRejectRequest } from "../../api";

/**
 * @template FEM
 * @callback ResponseButtonActionHandler 
 * @param {FEM} femEntity
 * @returns {Promise<void> | void}
 */

/**
 * @template FEM
 * @typedef {Object} RespondButtonProps
 * @prop {string} [label="Respond"]
 * @prop {string} [additionalButtonStyles]
 * @prop {FEM} femEntity
 * @prop {ResponseButtonActionHandler<FEM>} onApprove
 * @prop {ResponseButtonActionHandler<FEM>} onReject
 */

/**
 * 
 * @param {RespondButtonProps<import("../../api").FemServiceProviderEquineRelationshipRead>} param0 
 * @returns {React.JSX.Element}
 */
export default function RespondButton({
    label="Respond",
    additionalButtonStyles,
    femEntity,
    onApprove,
    onReject,
}) {
    const fetchFromApi = useApi();
    const [isModalShowing, setIsModalShowing] = useState(false);

    /**
     * 
     * @param {Request} request 
     * @param {ResponseButtonActionHandler<import("../../api").FemServiceProviderEquineRelationshipRead>} callback 
     * @returns 
     */
    async function handleAcceptAndRejectHelper(request, callback) {
        const response = await fetchFromApi(request);
        if (response.isOk) {
            if (response.data?.entity) {
                callback(response.data.entity);
            }
            setIsModalShowing(false);
            return null;
        } else {
            return response.data;
        }

    }

    /** @type {import("./service-provider-equine-relationship-response-form").ServiceProviderEquineRelationshipResponseFormSubmitHandler} */
    async function handleAccept(serviceProviderEquineRelationshipId, data) {
        return handleAcceptAndRejectHelper(
            serviceProviderEquineRelationshipApproveRequest(serviceProviderEquineRelationshipId, data),
            onApprove,
        );
    }

    /** @type {import("./service-provider-equine-relationship-response-form").ServiceProviderEquineRelationshipResponseFormSubmitHandler} */
    async function handleReject(serviceProviderEquineRelationshipId, data) {
        return handleAcceptAndRejectHelper(
            serviceProviderEquineRelationshipRejectRequest(serviceProviderEquineRelationshipId, data),
            onReject,
        );
    }

    return (
        <>
            <Modal isShowing={isModalShowing} setIsShowing={setIsModalShowing}>
                <ServiceProviderEquineRelationshipResponseForm
                    entity={femEntity}
                    onAccept={handleAccept}
                    onReject={handleReject}
                />
            </Modal>
            <div className="control">
                <button
                    className={["button", additionalButtonStyles].join(" ")}
                    onClick={() => setIsModalShowing(true)}
                >{label}</button>
            </div>

        </>
    )
}
