// @ts-check

/** @typedef {(equine: import("../api").FemEquineRead) => void} EquineFormModalUpdateEquineCallback */
/**
 * @typedef {Object} EquineEditButtonProps
 * @prop {import("../api").FemEquineRead} equine
 * @prop {EquineFormModalUpdateEquineCallback} setEquine
 * @prop {import("../api").FemRoleToDisplayNameMapping} allRelationships
 * @prop {string} [additionalButtonStyles]
 */

import React, { useState } from "react";
import { useApi } from "react-base";
import { equineUpdateRequest } from "../api";
import EquineFormModal from "./equine-form-modal";

/**
 * 
 * @param {EquineEditButtonProps} param0 
 * @returns 
 */
export default function EquineEditButton({equine, setEquine, allRelationships, additionalButtonStyles}) {
    const [isModalShowing, setIsModalShowing] = useState(false);
    const fetchFromApi = useApi();

    /**
     * 
     * @param {number} equineId
     * @param {import("../api").FemEquineWrite} editedData
     * @returns {Promise<import("../../../api/src/fem-types").FemApiErrorResponse | null>}
     */
    async function updateEquine(equineId, editedData) {
        const response = await fetchFromApi(equineUpdateRequest(equineId, editedData));
        if (response.isOk) {
            if (response.data?.entity) {
                setEquine(response.data.entity);
            }
            setIsModalShowing(false);
            return null;
        } else {
            return response.data;
        }
    }

    /** @type {import("./equine-form").EquineFormSubmitHandler} */
    async function handleSubmit(original, edited) {
        if (original) {
            return updateEquine(original?.id, edited);
        } else {
            return {errors: ["Unexpected error."]};
        }
    }

    return (
        <>
            <EquineFormModal
                isShowing={isModalShowing}
                setIsShowing={setIsModalShowing}
                equine={equine}
                handleSubmit={handleSubmit}
                submitButtonLabel="Update"
                allRelationships={allRelationships}
            />
            <button
                className={["button", additionalButtonStyles ?? ''].join(" ")}
                onClick={() => setIsModalShowing(true)}
            >Edit</button>
        </>
    );
}