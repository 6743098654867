// @ts-check

import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import ApiButtonWithModal from "../components/general-purpose/api-button-with-modal";
import { serviceProviderConnectionUpdateRequest, serviceProviderServiceCreateRequest, serviceProviderServiceDeleteRequest, serviceProviderServiceUpdateRequest } from "../api";
import ServiceProviderConnectionForm from "../components/service-provider-connection-form";
import ProfileProperties from "../components/profile-properties";
import TableWithButtons from "../components/general-purpose/table-with-buttons";
import ManagedServiceProviderServiceForm from "../components/managed-service-provider-service-form";

/** @type {import("../components/profile-properties").ProfilePropertiesFieldsInfo<import("../api").FemServiceProviderConnectionRead>} */
const FIELDS_INFO = {
    id: null,
    name: { label: "Name" },
    company_name: { label: "Company name"},
    email: { label: "Email"},
    phone: { label: "Phone" },
    address: { label: "Address" },
};

/** @type {import("../components/profile-properties").ProfilePropertiesFieldDisplaySequence<import("../api").FemServiceProviderConnectionRead>} */
const FIELD_DISPLAY_SEQUENCE = [
    "name", "company_name", "email", "phone", "address"
];

export default function ServiceProviderConnectionProfile() {
    const apiData =
        /** @type {import("../api").FemServiceProviderConnectionsSuccessfulGetResponse} */ (useLoaderData());
    const [serviceProvider, setServiceProvider] = useState(apiData.entity);
    const [services, setServices] = useState(apiData.other_data.services);

    return (
        <>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">{serviceProvider.name}</h1>
                    </div>
                </div>
                <div className="level-right">
                    <div className="field">
                        <div className="control">
                            <ApiButtonWithModal
                                label="Edit"
                                request={(data) => serviceProviderConnectionUpdateRequest(serviceProvider.id, data)}
                                modal={(submitHandler) =>
                                    <ServiceProviderConnectionForm
                                        handleSubmit={(_original, edited) => submitHandler(edited)}
                                        entity={serviceProvider} />
                                }
                                onSuccess={setServiceProvider}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="box">
                <ProfileProperties entity={serviceProvider} fieldsToDisplay={FIELD_DISPLAY_SEQUENCE} fieldsInfo={FIELDS_INFO} />
            </div>

            <div className="box">
                <TableWithButtons
                    entities={services}
                    setEntities={setServices}
                    title={<h2>Services</h2>}
                    emptyListText="There are no services for this provider."
                    tableHeaderElements={
                        <>
                            <th className="pt-4">Name</th>
                            <th className="pt-4 is-hidden-touch">Description</th>
                        </>
                    }
                    oneRowTableDataElements={(entity) => (
                        <>
                            <td>
                                {entity.name}
                            </td>
                            <td className="is-hidden-touch">
                                {entity.description}
                            </td>
                        </>
                    )}
                    createButton={{
                        CreateForm: ({handleSubmit}) =>
                            <ManagedServiceProviderServiceForm handleSubmit={handleSubmit} />,
                        createRequest: (data) => serviceProviderServiceCreateRequest(serviceProvider.id, data),
                    }}
                    editButton={{
                        UpdateForm: ({entity, handleSubmit}) =>
                            <ManagedServiceProviderServiceForm handleSubmit={handleSubmit} entity={entity} />,
                        updateRequest: (id, data) =>
                            serviceProviderServiceUpdateRequest(serviceProvider.id, /** @type {number} */ (id), data),
                    }}
                    deleteButton={{
                        deleteRequest: (id) =>
                            serviceProviderServiceDeleteRequest(serviceProvider.id, /** @type {number} */ (id)),
                    }}
                />
            </div>
        </>
    );
}