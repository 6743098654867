// @ts-check

import { appConfig as appConfigBase } from "react-base";
import menu from "./menu.js";

/** @type {typeof appConfigBase & {appVisibleName: string, menu: import("react-base").Menu}} */
const appConfig = Object.assign(appConfigBase, {
    appVisibleName: "EquineKeeper",
    menu: menu,
    fullNameSupport: false,
    displayNameSupport: true,
});

export default appConfig;