// @ts-check

import React, { useState } from "react";
import { Modal } from "react-base";
import UserForm from "./user-form";

/**
 * @typedef {Object} UserEditButtonProps
 * @prop {import("../api").FemProfileRead} profile
 * @prop {(profile: import("../api").FemProfileRead) => void} setProfile
 */

/**
 * 
 * @param {UserEditButtonProps} param0 
 * @returns {React.JSX.Element}
 */
export default function UserEditButton({
    profile,
    setProfile,
}) {
    const [isModalShowing, setIsModalShowing] = useState(false);

    return (
        <>
            <Modal isShowing={isModalShowing} setIsShowing={setIsModalShowing}>
                <UserForm 
                    profile={profile}
                    setProfile={(profile) => { setIsModalShowing(false); setProfile(profile); }}
                />
            </Modal>
            <button className="button" onClick={() => setIsModalShowing(true)}>Edit</button>
        </>
    );
}