// @ts-check

import React from "react";
import EquineForm from "./equine-form";
import { Modal } from "react-base";

/**
 * @typedef {Object} EquineFormModalProps
 * @prop {boolean} isShowing
 * @prop {React.Dispatch<React.SetStateAction<boolean>>} setIsShowing
 * @prop {import("../api").FemEquineRead} [equine]
 * @prop {import("./equine-form").EquineFormSubmitHandler} handleSubmit
 * @prop {string} submitButtonLabel
 * @prop {import("../api").FemRoleToDisplayNameMapping} allRelationships
 */

/**
 * 
 * @param {EquineFormModalProps} param0
 * @returns 
 */
export default function EquineFormModal({isShowing, setIsShowing, equine = undefined, handleSubmit, submitButtonLabel, allRelationships}) {

    return (
        <Modal isShowing={isShowing} setIsShowing={setIsShowing}>
            <EquineForm 
                equine={equine}
                handleSubmit={handleSubmit} 
                submitButtonLabel={submitButtonLabel}
                allRelationships={allRelationships}
            />
        </Modal>
    );
}