// @ts-check

import React from "react";
import { Modal } from "react-base";
import PonyLogForm from "./ponylog-form";

/**
 * @typedef {Object} EquineFormModalProps
 * @prop {boolean} isShowing
 * @prop {React.Dispatch<React.SetStateAction<boolean>>} setIsShowing
 * @prop {number} [initialSelectedHorse]
 * @prop {import("../api").FemPonylogEntryRead} [entry]
 * @prop {import("./ponylog-form").PonyLogFormSubmitHandler} handleSubmit
 * @prop {string} submitButtonLabel
 * @prop {Array<import("../api").FemEquineMentionWithUserRelationshipsRead>} allHorses
 * @prop {Array<import("../api").FemEquineMentionWithUserRelationshipsRead>} [allDeletedHorses=[]]
 */

/**
 * 
 * @param {EquineFormModalProps} param0
 * @returns 
 */
export default function PonyLogFormModal({
    isShowing,
    setIsShowing,
    initialSelectedHorse,
    entry = undefined,
    handleSubmit,
    submitButtonLabel,
    allHorses,
    allDeletedHorses = [],
}) {
    return (
        <Modal isShowing={isShowing} setIsShowing={setIsShowing}>
            <PonyLogForm
                entry={entry}
                handleSubmit={handleSubmit} 
                submitButtonLabel={submitButtonLabel}
                allHorses={allHorses}
                allDeletedHorses={allDeletedHorses}
                initialSelectedHorse={initialSelectedHorse}
            />
        </Modal>
    );
}