// @ts-check

import React from "react";
import Property from "./general-purpose/property";

/**
 * @template {Object} FEM
 * @typedef {{[key in keyof FEM]: null | {label: string, type?: "string" | "user list"}}} ProfilePropertiesFieldsInfo
 */

/**
 * @template {Object} FEM
 * @typedef {Array<keyof FEM>} ProfilePropertiesFieldDisplaySequence
 */

/**
 * @template {Object} FEM
 * @typedef {Object} ProfilePropertiesProps
 * @prop {FEM} entity
 * @prop {ProfilePropertiesFieldDisplaySequence<FEM>} fieldsToDisplay
 * @prop {ProfilePropertiesFieldsInfo<FEM>} fieldsInfo
 */

/**
 * 
 * @template {Object} FEM
 * @param {ProfilePropertiesProps<FEM>} param0 
 * @returns 
 */
export default function ProfileProperties({entity, fieldsToDisplay, fieldsInfo}) {
    return fieldsToDisplay.map((femFieldName) => {
        const fieldInfo = fieldsInfo[femFieldName];
        if (fieldInfo) {
            switch (fieldInfo.type) {
                case "string":
                case undefined:
                    return <Property label={fieldInfo.label} value={/** @type {string} */ (entity[femFieldName])} />
                    break;
                case "user list":
                    const listVal = (/** @type {Array<import("../api").FemPersonMention>} */ (entity[femFieldName]))
                        .map((personMention) => personMention.display_name)
                        .join(", ");
                    return <Property label={fieldInfo.label} value={listVal} />
                    break;
                default:
                    // skip - unknown type
                    break;
            }
        }
    })
}
