// @ts-check

/** @typedef {(equineId: number) => void} EquineDeleteButtonDeletedCallback */

/**
 * @typedef {Object} EquineDeleteButtonProps
 * @prop {number} equineId
 * @prop {EquineDeleteButtonDeletedCallback} deletedCallback
 * @prop {string} [additionalButtonStyles]
 */

import React from "react";
import { ActionButton, useApi } from "react-base";
import { equineDeleteRequest } from "../api";
import toast from "react-hot-toast";

/**
 * 
 * @param {EquineDeleteButtonProps} param0 
 * @returns 
 */
export default function EquineDeleteButton({equineId, deletedCallback, additionalButtonStyles}) {
    const fetchFromApi = useApi();

    /**
     * 
     * @param {number} id 
     * @param {import("react-base/dist/components/action-button").ActionButtonClickHandlerCallback} callback 
     */
    async function deleteEquine(id, callback) {
        const response = await fetchFromApi(equineDeleteRequest(id));
        if (response.isOk) {
            deletedCallback(id);
        } else {
            toast.error(
                <>
                    <ul>
                        {(response.data?.errors ?? []).map((/** @type {string} */ error) => (
                            <li>{error}</li>
                        ))}
                    </ul>
                </>
            );
        }
        callback();
    }

    return (
        <ActionButton
            additionalButtonStyles={additionalButtonStyles}
            onClick={(callback) => deleteEquine(equineId, callback)}
        >Delete</ActionButton>
    );
}