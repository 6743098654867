// @ts-check

import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import TableWithButtons from "../components/general-purpose/table-with-buttons";
import ServiceProviderConnectionForm from "../components/service-provider-connection-form";
import { serviceProviderConnectionCreateRequest, serviceProviderConnectionDeleteRequest, serviceProviderConnectionUpdateRequest } from "../api";
import { path } from "react-base";
import paths from "../paths";

export default function ServiceProviderConnections() {
    const dataLoadedFromApi =
        /** @type {import("../api").FemServiceProviderConnectionsSuccessfulGetAllResponse} */ (useLoaderData());
    const [serviceProviders, setServiceProviders] = useState(dataLoadedFromApi.collection);

    return (
        <>
            <h1 className="title">Service Provider Connections</h1>
            <TableWithButtons
                entities={serviceProviders}
                setEntities={setServiceProviders}
                emptyListText="You don't have any service provider contacts."
                tableHeaderElements={
                    <>
                        <th>Name</th>
                    </>
                }
                oneRowTableDataElements={(entity) =>
                    <>
                        <td>{entity.name}</td>
                    </>
                }
                viewButton={{
                    entityProfilePath: (id) => path(paths.contactServiceProviders.profile, {serviceProviderId: id}),
                }}
                createButton={{
                    CreateForm: ({handleSubmit}) =>
                        <ServiceProviderConnectionForm handleSubmit={handleSubmit} />,
                    createRequest: serviceProviderConnectionCreateRequest,
                }}
                editButton={{
                    UpdateForm: ({handleSubmit, entity}) =>
                        <ServiceProviderConnectionForm handleSubmit={handleSubmit} entity={entity} />,
                    updateRequest: serviceProviderConnectionUpdateRequest,
                }}
                deleteButton={{
                    deleteRequest: serviceProviderConnectionDeleteRequest,
                }}
            />
        </>
    );
}