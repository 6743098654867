// @ts-check

/** @typedef {import('./api.js').FemApiSuccessfulCollectionResponseWithOtherData<import('./api.js').FemEquineRead, import('./api.js').FemEquinesCollectionOtherData>} EquinesLoaderData */
/** @typedef {import('./api.js').FemEquineProfileGetResponse} EquineProfileLoaderData */

import paths from './paths.js';

import ErrorPage from './error-page';
import MainPage from './routes/main-page';
import Equines from './routes/equines.js';
import { contactsGetAllRequest, equineGetRequest,
    equinesGetAllRequest,
    personGetRequest,
    ponylogEntriesGetAllRequest,
    profileGetRequest,
    serviceProviderConnectionGetRequest,
    serviceProviderConnectionsGetAllRequest,
    serviceProviderEquineRelationshipGetRequest,
    serviceProviderGetRequest,
    serviceProviderManagedGetAllRequest,
    serviceProviderServiceGetRequest, 
    userUserRelationshipsInvitationsRequest} from './api.js';
import { api } from 'react-base';
import { json, useParams } from 'react-router-dom';
import React from 'react';
import EquineProfile from './routes/equine-profile.js';
import UserProfile from './routes/user-profile.js';
import PonyLog, { ITEMS_PER_PAGE } from './routes/ponylog.js';
import TermsOfUse from './routes/terms-of-use.js';
import PrivacyPolicy from './routes/privacy-policy.js';
import ManagedServiceProviderProfile from './routes/managed-service-provider-profile.js';
import ManagedServiceProviders from './routes/managed-service-providers.js';
import ManagedServiceProviderServiceProfile from './routes/managed-service-provider-service-profile.js';
import ServiceProviderEquineRelationship from './routes/service-provider-equine-relationship.js';
import Contacts from './routes/contacts.js';
import PersonProfile from './routes/person-profile.js';
import ContactServiceProviders from './routes/service-provider-connections.js';
import ServiceProviderConnectionProfile from './routes/service-provider-connection-profile.js';
import Invitations from './routes/invitations.js';

// TODO: move this to react-base
/**
* 
* @param {Request} request 
* @returns {Promise<Response>}
*/
async function apiLoader(request) {
    const response = await api(request);
    if (!response.isOk) {
        throw "Server returned an error.";
    }
    if (response.data?.entity === undefined && response.data?.collection === undefined) {
        throw "Server returned a response without an entity or a collection."
    }
    return json(response.data || '', {status: response.status});
}

/**
 * 
 * @param {{component: (key: any) => React.JSX.Element, idParamName: string}} param0 
 * @returns 
 */
function RouteComponentWrapper({component: Component, idParamName}) {
    const params = useParams();
    return <Component key={params[idParamName]}/>;
}

export const unauthenticatedPaths = [
    {
        path: "/",
        element: <MainPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: paths.termsOfUse,
        element: <TermsOfUse />,
        errorElement: <ErrorPage />,
    },
    {
        path: paths.privacyPolicy,
        element: <PrivacyPolicy />,
        errorElement: <ErrorPage />,
    },
];

export const authenticatedPaths = [
    {
        path: paths.equines.all,
        element: <Equines />,
        loader: async () => {
            return await apiLoader(equinesGetAllRequest());
        },
        errorElement: <ErrorPage />,
    },
    {
        path: paths.equines.profile,
        element: <EquineProfile />,
        loader: async (/** @type {{params: {equineId: number}}} */ { params }) => {
            if (params.equineId === undefined || (Number.isNaN(Number(params.equineId)))) {
                throw "Id is required.";
            }
            return await apiLoader(equineGetRequest(params.equineId));
        },
        errorElement: <ErrorPage />,
    },
    {
        path: paths.ponylog,
        element: <PonyLog />,
        loader: async () => {
            return await apiLoader(ponylogEntriesGetAllRequest({count: ITEMS_PER_PAGE, offset: 0}));
            // return json(ponyLogMockData);
        },
        errorElement: <ErrorPage />,
    },
    {
        path: paths.profile,
        element: <UserProfile />,
        loader: async () => {
            return await apiLoader(profileGetRequest());
        },
        errorElement: <ErrorPage />,
    },
    {
        path: paths.managedServiceProviders.all,
        element: <ManagedServiceProviders />,
        loader: async () => {
            return await apiLoader(serviceProviderManagedGetAllRequest());
        },
        errorElement: <ErrorPage />,
    },
    {
        path: paths.managedServiceProviders.profile,
        element: <ManagedServiceProviderProfile />,
        loader: async (/** @type {{params: {serviceProviderId: number}}} */ { params }) => {
            if (params.serviceProviderId === undefined || (Number.isNaN(Number(params.serviceProviderId)))) {
                throw "Id is required.";
            }
            return await apiLoader(serviceProviderGetRequest(params.serviceProviderId));
        },
        errorElement: <ErrorPage />,
    },
    {
        path: paths.contactServiceProviders.all,
        element: <ContactServiceProviders />,
        loader: async () => { return await apiLoader(serviceProviderConnectionsGetAllRequest()); },
        errorElement: <ErrorPage />,
    },
    {
        path: paths.contactServiceProviders.profile,
        element: <ServiceProviderConnectionProfile/>,
        loader: async (/** @type {{params: {serviceProviderId: number}}} */ { params }) => {
            if (params.serviceProviderId === undefined || (Number.isNaN(Number(params.serviceProviderId)))) {
                throw "Id is required.";
            }
            return await apiLoader(serviceProviderConnectionGetRequest(params.serviceProviderId));
        },
        errorElement: <ErrorPage/>,
    },
    {
        path: paths.managedServiceProviders.services.profile,
        element: <ManagedServiceProviderServiceProfile />,
        loader: async (/** @type {{params: {serviceProviderId: number, serviceId: number}}} */ { params }) => {
            if (params.serviceProviderId === undefined || (Number.isNaN(Number(params.serviceProviderId)))) {
                throw "Service provider id is required.";
            }
            if (params.serviceId === undefined || (Number.isNaN(Number(params.serviceId)))) {
                throw "Service id is required.";
            }
            return await apiLoader(serviceProviderServiceGetRequest(params.serviceProviderId, params.serviceId));
        },
        errorElement: <ErrorPage />,
    },
    {
        path: paths.serviceProviderEquineRelationships.profile,
        element: <ServiceProviderEquineRelationship/>,
        loader: async (/** @type {{params: {id: number}}} */ { params }) => {
            if (params.id === undefined || (Number.isNaN(Number(params.id)))) {
                throw "Service provider equine relationship id is required.";
            }
            return await apiLoader(serviceProviderEquineRelationshipGetRequest(params.id));
        },
        errorElement: <ErrorPage />,
    },
    {
        path: paths.connections.all,
        element: <Contacts/>,
        loader: async () => await apiLoader(contactsGetAllRequest()),
        errorElement: <ErrorPage/>,
    },
    {
        path: paths.invitations.all,
        element: <Invitations/>,
        loader: async () => await apiLoader(userUserRelationshipsInvitationsRequest()),
        errorElement: <ErrorPage/>,
    },
    {
        path: paths.persons.profile,
        element: <RouteComponentWrapper component={PersonProfile} idParamName='id'/>,
        loader: async (/** @type {{params: {id: number}}} */ {params}) => {
            if (params.id === undefined || (Number.isNaN(Number(params.id)))) {
                throw "Person id is required.";
            }
            return await apiLoader(personGetRequest(params.id));
        },
        errorElement: <ErrorPage/>,
    },
];
