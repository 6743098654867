// @ts-check

import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import ProfileProperties from "../components/profile-properties";
import ApiButtonWithModal from "../components/general-purpose/api-button-with-modal";
import { serviceEquineRelationshipAcceptRequest, serviceEquineRelationshipDeleteRequest, serviceEquineRelationshipRejectRequest, serviceProviderServiceUpdateRequest } from "../api";
import ManagedServiceProviderServiceForm from "../components/managed-service-provider-service-form";
import Property from "../components/general-purpose/property";
import TableWithButtons from "../components/general-purpose/table-with-buttons";
import { addToEntityListState, dateToString } from "../helpers/others";
import ApiButton from "../components/general-purpose/api-button";

/** @type {import("../components/profile-properties").ProfilePropertiesFieldsInfo<import("../api").FemServiceRead>} */
const FIELDS_INFO = {
    id: null,
    service_provider: null,
    name: { label: "Name" },
    description: { label: "Description"},
};

/** @type {import("../components/profile-properties").ProfilePropertiesFieldDisplaySequence<import("../api").FemServiceRead>} */
const FIELD_DISPLAY_SEQUENCE = [
    "name", "description"
];

export default function ManagedServiceProviderServiceProfile() {
    const apiLoaderData = /** @type {import("../api").FemServiceSuccessfulGetResponse} */ (useLoaderData());
    const otherData = /** @type {import("../api").FemServiceGetResponseOtherData} */ (apiLoaderData.other_data);
    const [entity, setEntity] = useState(apiLoaderData.entity)
    const [serviceRequests, setServiceRequests] = useState(otherData.service_requests);
    const [servedHorses, setServedHorses] = useState(otherData.served_horses);
    const [pastHorses, setPastHorses] = useState(otherData.past_horses);

    return (
        <>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">{entity.name}</h1>
                    </div>
                </div>
                <div className="level-right">
                    <div className="field has-addons">
                        <ApiButtonWithModal
                            femEntity={entity}
                            request={(/** @type {import("../api").FemServiceWrite} */ data) => 
                                serviceProviderServiceUpdateRequest(entity.service_provider.id, entity.id, data)}
                            label="Edit"
                            onSuccess={setEntity}
                            modal={(submitHandler) => (
                                <ManagedServiceProviderServiceForm
                                    entity={entity}
                                    handleSubmit={(_original, edited) => submitHandler(edited)} />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="box">
                <Property label="Service Provider" value={entity.service_provider.name} />
                <ProfileProperties entity={entity} fieldsToDisplay={FIELD_DISPLAY_SEQUENCE} fieldsInfo={FIELDS_INFO} />
            </div>

            {serviceRequests.length > 0
                ?
                    <div className="box">
                        <TableWithButtons
                            entities={serviceRequests}
                            setEntities={setServiceRequests}
                            emptyListText="No service requests."
                            title={<h2>Service Requests</h2>}
                            tableHeaderElements={(
                                <>
                                    <th>Horse</th>
                                    <th>Service</th>
                                    <th>Requested</th>
                                </>
                            )}
                            oneRowTableDataElements={(serviceEquineRelationship) => (
                                <>
                                    <td>{serviceEquineRelationship.equine.nickname}</td>
                                    <td>{serviceEquineRelationship.service.name}</td>
                                    <td>{dateToString(serviceEquineRelationship.requestedAt)}</td>
                                </>
                            )}
                            rowButtons={[
                                {
                                    type: "delete",
                                    elementFn: ({entity: serviceProviderEquine, onClickHandler, additionalButtonStyles}) =>
                                        <ApiButton
                                            additionalButtonStyles={additionalButtonStyles}
                                            femEntity={{}}
                                            label="Accept"
                                            request={() => serviceEquineRelationshipAcceptRequest(serviceProviderEquine.id)}
                                            onSuccess={(updatedServiceProviderEquine) => {
                                                onClickHandler(updatedServiceProviderEquine);
                                                addToEntityListState(updatedServiceProviderEquine, setServedHorses, {side: "front"});
                                            }}
                                        />
                                },
                                {
                                    type: "delete",
                                    elementFn: ({entity: serviceProviderEquine, onClickHandler, additionalButtonStyles}) =>
                                        <ApiButton
                                            additionalButtonStyles={additionalButtonStyles}
                                            femEntity={{}}
                                            label="Reject"
                                            request={() => serviceEquineRelationshipRejectRequest(serviceProviderEquine.id)}
                                            onSuccess={(updatedServiceProviderEquine) => {
                                                onClickHandler(updatedServiceProviderEquine);
                                                addToEntityListState(updatedServiceProviderEquine, setPastHorses, {side: "front", limit: 10});
                                            }}
                                        />
                                },
                            ]}
                        />
                    </div>
                : <></>
            }

            <div className="box">
                <TableWithButtons
                    entities={servedHorses}
                    setEntities={setServedHorses}
                    emptyListText="No horses are currently served."
                    title={<h2>Horses being served</h2>}
                    tableHeaderElements={
                        <>
                            <th>Name</th>
                            <th>Since</th>
                        </>
                    }
                    oneRowTableDataElements={(serviceEquineRelationship) =>
                        <>
                            <td>{serviceEquineRelationship.equine.nickname}</td>
                            <td>
                                {serviceEquineRelationship.startedAt
                                    ? dateToString(serviceEquineRelationship.startedAt)
                                    : "?"}
                            </td>
                        </>
                    }
                    deleteButton={{
                        label: "End",
                        deleteRequest: serviceEquineRelationshipDeleteRequest,
                        onDelete: (entity) =>
                            addToEntityListState(
                                /** @type {import("../api").FemServiceEquineRelationshipRead} */ ({
                                    ...entity,
                                    endedAt: new Date().getTime(),
                                }),
                                setPastHorses, {side: "front", limit: 10}),
                    }}
                />
            </div>

            <div className="box">
                <TableWithButtons
                    entities={pastHorses}
                    setEntities={setPastHorses}
                    emptyListText="No horses."
                    title={<h2>Past Served Horses</h2>}
                    tableHeaderElements={
                        <>
                            <th>Name</th>
                            <th>Requested</th>
                            <th>Started</th>
                            <th>Ended</th>
                        </>
                    }
                    oneRowTableDataElements={(serviceEquineRelationship) =>
                        <>
                            <td>{serviceEquineRelationship.equine.nickname}</td>
                            <td>{dateToString(serviceEquineRelationship.requestedAt)}</td>
                            <td>
                                {serviceEquineRelationship.startedAt
                                    ? dateToString(serviceEquineRelationship.startedAt)
                                    : "-"}
                            </td>
                            <td>
                                {serviceEquineRelationship.endedAt
                                    ? dateToString(serviceEquineRelationship.endedAt)
                                    : "?"}
                            </td>
                        </>
                    }
                />
            </div>
        </>
    );
}