// @ts-check

import React from "react";
import { Link } from "react-router-dom";
import paths from "../paths";

export default function PrivacyPolicy() {
    const siteName = "EquineKeeper";
    const companyName = "Hasufel LLC";
    const webAddress = "equinekeeper.com";
    const effectiveDate = "14 July 2024";

    function TermsOfUseLink() {
        return (
            <Link to={paths.termsOfUse}>Terms of Use</Link>
        );
    }

    return (
        <>
            <h1>Privacy Policy</h1>

            <p>Last updated: {effectiveDate}</p>

            <p>This policy explains how we, {companyName}, collects, stores, uses 
            and discloses information related to the {siteName} website 
            located at {webAddress} (the "Site" or "Service"), and explains your choices 
            regarding the collection, modification and use of such information 
            in accordance with General Data Protection Regulation (“GDPR”). By 
            accessing or using our Service, you confirm that you have read, 
            understood and agree to the terms of this Privacy Policy and 
            the <TermsOfUseLink/>. If you do not agree to the terms of this Privacy 
            Policy, do not access or use the Service or provide us with 
            personal information.</p>

            <h2>What information do we collect?</h2>

            <p>{companyName} collects various kinds of information in order to 
            provide you with the Service. We may collect information about you 
            when you visit the Site, or subscribe for, purchase or otherwise 
            use our Service on the various available platforms. The information 
            collected may range from simple contact information you provide to 
            us when establishing an account or requesting a demo or a free 
            trial, to analytical and other information we collect through 
            technological means such as cookies and web logging. We may also 
            receive information from third parties, such as our Partners. The 
            specific information we collect in any situation depends on the 
            platform you use, the reason for the collection and the technology 
            involved.</p>

            <p>Even if you don't have an account, you may interact with or use 
            {siteName}. Then we may collect things like browser and app logs of 
            your visits to public content. Even if you don't have an account, 
            you may interact with or use {siteName}. Then we may collect things 
            like browser and app logs of your visits to public content. For 
            example, we collect information to monitor attempted attacks 
            on our systems, such as overloading our servers with traffic. Also, 
            if we see someone without an account trying to load too many pages, 
            they could be trying to scrape our site in violation of our terms. 
            Then we can take action to prevent it.</p>
            
            <h3>Information you provide</h3>

            <p><strong>Registration Information:</strong> We collect personal 
            information from you when you create a Member Account and populate 
            or update your Profile Page, which may include, but is not limited 
            to, your name, address, phone number(s), email address(es), and any 
            other information about you we consider relevant. You might also 
            choose to add other details to your account, like a profile photo 
            or payment information.</p>

            <p><strong>Information you provide:</strong> We also collect 
            information you provide when you upload images or documents, send 
            messages or write log entries, fill out a form, or contact us.</p>

            <p><strong>Metadata:</strong> Information about the content itself, 
            like the location where a photo was taken or the date a file was 
            created, or the date and time a log entry was created or shared.</p>

            <p><strong>Financial Information:</strong> We collect information 
            when you subscribe to {siteName}, such as 
            your debit or credit card information and related billing 
            information. We require such financial information in order to 
            process payment for the use of a Service you have requested. 
            By submitting your financial information, you consent to our 
            providing it to third party resellers, distributors, or, value 
            added resellers as necessary to support and process your 
            transactions as well as your credit card issuer/banking institution 
            for their purposes. We do not store your debit or credit card 
            information. All debit or
            credit card information are kept with licenced institutions or
            partners.</p>

            <p><strong>Information based on others' activity:</strong> We 
            collect information about you on {siteName} when others give us
            your contact information which may include your name, email, phone 
            number, or other information about you.</p>

            <p><strong>Information Collected by Use of the Services:</strong> 
            When you visit our Site or use our Service, we may automatically 
            collect and store certain information about your usage of and 
            interaction with the Service. We may also collect, through third 
            parties or by technical means, technical analytics 
            information about the way Users interact with and use our Service.</p>

            <p>In the course of providing online or direct technical support, 
            with your permission, we may sometimes be granted access to data 
            that is visible on your screen. This data may contain information 
            about you, your organization’s employees, customers, partners, or 
            suppliers. Any such access to information not relevant to the 
            problem being resolved is not recorded or retained by 
            {siteName}.</p>

            <p><strong>Cookies: </strong> {siteName} may use both session and 
            persistent cookies and similar technologies like pixels, web 
            beacons, and local storage to collect information about how you 
            use the Service and to provide features of the Service to you in 
            order to help {siteName} give you more personal, convenient website 
            visits. Due to Cookies {siteName} remember you, your website 
            logins, information and more. By using the Service, you agree that 
            we can use cookies in accordance with the terms described in this 
            section. A persistent cookie remains on your hard drive after you 
            close your browser. Persistent cookies may be used by your browser 
            on subsequent visits to Site and can be removed by following your 
            web browser’s directions. A session cookie is temporary and 
            disappears after you close your browser. We use cookies only 
            essential to the Service.</p>

            <p><strong>Log file Information:</strong> Log file information is 
            automatically reported by your browser each time you access a web 
            page or an app. When you use our Service, our servers automatically 
            record certain log file information regarding to keep the activity 
            on web page or an app. With the help of Log files, we improve 
            {siteName}, and fix bugs and creates new and relevant features for its 
            members. These server logs may include anonymous information such 
            as your web request, Internet Protocol (“IP”) address, browser 
            type, referring / exit pages and URLs, number of clicks and how you 
            interact with links on the Service, domain names, landing pages, 
            pages viewed and other such information.</p>

            <p><strong>Clear gifs/Web Beacons:</strong> We may use clear gifs 
            in HTML-based emails sent to our users to track which emails are 
            opened and which links are clicked by recipients. The information 
            allows for more accurate reporting and improvement of the 
            Service.</p>

            <p><strong>Device Identifiers:</strong> When you access the Service 
            by or through a mobile device (including but not limited to 
            smart-phones or tablets), we may access, collect, monitor and/or 
            remotely store one or more “device identifiers.” Device identifiers 
            are small data files or similar data structures stored on or 
            associated with your mobile device, which uniquely identify your 
            mobile device. A device identifier may be data stored in connection 
            with the device hardware, data stored in connection with the 
            device’s operating system or other software, or data sent to the 
            device by us. A device identifier may convey information to us 
            about how you browse and use the Service regarding security of your 
            account and to provide you Services. A device identifier may remain 
            persistently on your device, to help you log in faster and enhance 
            your navigation through the Service. Some features of the Service 
            may not function properly if use or availability of device 
            identifiers is impaired or disabled.</p>

            <h2>How do we use your information?</h2>

            <h3>To provide and improve {siteName}</h3>

            <p>We use information we collect to provide a personalized 
            experience to you, which may include ads, along with the other 
            purposes we explain in detail below.</p>

            <p>For some of these purposes, we use information across your 
            devices. The information we use for these purposes is automatically 
            processed by our systems. But in some cases, we also use manual 
            review to access and review your information.</p>

            <p>Here are some examples of when we use manual review:</p>

            <ul>
                <li>
                    Our reviewers help us promote safety, security and integrity 
                    in {siteName}. For example, reviewers can look for and remove 
                    content that violates our terms and policies and keep content that 
                    doesn’t break our terms and policies available. Their work 
                    supplements any technology we use that detects violations.
                </li>
                <li>
                    We also use manual review to analyze content to train our 
                    algorithms to review content the same way a person would. 
                    This improves our automatic processing, which in turn helps 
                    us improve the Service.
                </li>
            </ul>

            <p> The reviewers who conduct manual reviews work at {companyName}, or 
            for a trusted service provider. We require every reviewer who's 
            allowed access to your information to meet privacy and security 
            standards.</p>

            <p>To use less information that’s connected to individual users, in 
            some cases we de-identify or aggregate information or anonymize it 
            so that it no longer identifies you. We use this information in the 
            same ways we use your information as described in this section.</p>

            <p>Here are the ways we use your information:</p>

            <ul>
                <li>
                    <strong>To provide, personalize and improve 
                    {siteName}.</strong> We use information we have to provide 
                    and improve {siteName}. We’re always trying to make our 
                    Service better and create new ones with the features you 
                    want. Information we collect from you helps us learn how. 
                    We improve our Service by using the information we 
                    collect to 
                    <ul>
                        <li>See if our Service is working correctly, </li>
                        <li>Troubleshoot and fix it when it’s not, </li>
                        <li>Test out new products and features to see if they 
                            work, </li>
                        <li>Get feedback on our ideas for products or 
                            features,</li>
                        <li>Conduct surveys and other research about what you 
                            like about {siteName} and what we can do 
                            better.</li>
                    </ul>
                </li>
                <li>
                    <strong>To promote safety, security and integrity.</strong>
                    We use information we collect to help protect people from 
                    harm and provide safe, secure Service.
                </li>
                <li>
                    <strong>To communicate with you.</strong> We communicate 
                    with you using information you've given us, like contact 
                    information you've entered on your profile.
                </li>
            </ul>

            <h3>Other purposes</h3>

            <p><strong>Legal Requirements:</strong> We are committed to protecting 
            the Users’ privacy and only provide user data to governments in 
            response to valid and lawful requests from the governments and 
            relevant legal policies. We use collected information to o comply 
            with legal or governmental requirements or demands.</p>

            <p><strong>Communications:</strong> We use the information we 
            collect in order to communicate with you about {siteName}, to 
            respond to your requests for information and to keep Members 
            informed of important matters, such as software and security 
            updates and changes to our terms and policies. We also use it to 
            process orders and administer the financial and other aspects of 
            our relationship.</p>

            <p><strong>Sales Activities:</strong> We use information we collect 
            as part of its sales activities for processing orders, sending 
            leads to our Partners, keeping you informed of special offers and 
            changes in available products and services and general marketing 
            activities. </p>

            <p><strong>Customer Support:</strong> Information is also used to 
            provide technical and other support to registered users of 
            {siteName}. Such information helps us train our personnel and 
            creates a record of the interaction for future use and product 
            improvement purposes.</p>

            <p><strong>Data Analytics:</strong> We collect various kinds of 
            statistical and analytical information in connection with the 
            operation of {siteName}, the furnishing and operation of the 
            Service, and the operation of our Customer Support and sales 
            activities. Data we collect from these activities, such as log file 
            and traffic information, are used to evaluate the performance of 
            the Service, as well as to better understand usage patterns and the 
            needs and interests of our current and potential Members. This 
            assists us in improving {siteName}, fixing bugs and creating new 
            and relevant features for its members. We also sometimes contract 
            with outside suppliers to conduct some data analytics, particularly 
            on websites.</p>

            <h2>How is your information shared on {siteName}?</h2>

            <h3>People and accounts you share and communicate with</h3>

            <p>When you share and communicate using {siteName}, you can 
            choose the audience for what you share.</p>

            <p>When you interact with people, they can see what 
            you shared with them. For example:</p>

            <ul>
                <li>Share a log entry you’ve written</li>
                <li>Share a photo or video</li>
            </ul>

            <p>People can also see some of your activity on {siteName}. This 
            may include when you:</p>

            <ul>
                <li>Add or remove information to your profile</li>
                <li>Add or remove information to a horse profile</li>
                <li>Add or remove people's relationships with horses and their roles</li>
                <li>Delete some content you shared</li>
                <li>Delete a horse that you created</li>
            </ul>

            <h3>Content others share or reshare about you</h3>            

            <h4>Who can see or reshare your content</h4>

            <p>People in your audience can view your content and can choose to 
            share it with others outside your audience, on and off our Service. 
            For example, when you share a log entry to specific people, they 
            can download, screenshot or reshare it with anyone, on, across or 
            off {siteName}.</p>

            <p>Also note that the person who shared a log entry with you can also 
            change their audience at any time after you’ve seen the entry.</p>.

            <h4>How information about you can be shared</h4>

            <p>People who use {siteName} can share information about you with 
            the audience they choose. For example, they can:</p>

            <ul>
                <li>
                    Share a photo or video of you or your horses in a post, 
                    comment, story, reel or message
                </li>
                <li>
                    Mention you in a log entry
                </li>
                <li>
                    Tag you in a post, comment, story or location
                </li>
                <li>
                    Share details about you in a log entry
                </li>
            </ul>

            <p>If you’re uncomfortable with what others have shared about you 
            on {siteName}, you can always choose to report log entries using
            the feedback form.</p>

            <h2>Where is the information we collect stored?</h2>

            <p>Electronic information collected by {siteName} is kept on 
            servers that are owned and operated either by {companyName} or by 
            contracted suppliers or Partners. These servers may be located
            across the world.</p>

            <h2>With whom do we share information?</h2>

            <p>We may share your information as provided in this Section.</p>

            <p><strong>Third Party Providers:</strong> We may share your 
            information with third-party business partners, consultants, 
            resellers, distributors, value added resellers, and other service 
            providers that perform services on our behalf for the purpose of 
            providing the Service to you. Those business partners will be given 
            limited access to your information that is reasonably necessary to 
            deliver the Service.</p>

            <p><strong>Sale, Acquisition, Merger, Divestiture:</strong> We may 
            buy or sell, divest {companyName} or {siteName} (including any 
            shares in the {companyName}), or any combination of its products, 
            services, assets and/or businesses. Your information related to the 
            Service (as described herein) will likely be among the items 
            transferred in these types of transactions. We may also sell, 
            transfer or assign such information in the course of corporate 
            divestitures, reorganizations, mergers, acquisitions, bankruptcies, 
            dissolutions, liquidations or similar transactions or proceedings 
            involving all or a portion of {companyName} or {siteName}.</p>

            <p><strong>Legal Disclosure:</strong> We will disclose your 
            information where required to do so by law or subpoena or if we 
            reasonably believe that such action is necessary to (a) comply with 
            the law and the reasonable requests of law enforcement or 
            governmental entity; (b) to enforce our <TermsOfUseLink/>, or to 
            protect the security or integrity of our Service; and/or (c) to 
            exercise or protect the rights, property, and personal safety of 
            {siteName}, our employees, users, participating businesses and 
            others.</p>

            <p><strong>Aggregated Information:</strong> We may also share 
            information with others in an aggregated and anonymous form that 
            does not reasonably identify you directly as an individual for any 
            purpose, including for research and marketing purposes, and our 
            disclosure of such information is not subject to restriction.</p>

            <h2>How do we protect your information?</h2>

            <p>We use commercially reasonable physical, administrative, and 
            technical safeguards to preserve the integrity and security of your 
            information in our or our Partners’ premises/databases in our 
            possession. We use similar safeguards in deleting or disposing of 
            your information. However, since the internet is not a 100% secure 
            environment, despite these efforts we cannot but do our best to 
            ensure or warrant the security of your information.</p>

            <p>You are responsible for maintaining the secrecy of your unique 
            password and account information and for controlling access to your 
            email communications at all times.</p>

            <h2>What are the available choices about your information?</h2>

            <p>You may have various choices with respect to your information:</p>

            <ul>
                <li>
                    <strong>Log entries you share, including any photos, 
                    videos, or documents:</strong> You can choose the audience
                    that will be able to see your log entry when you are creating 
                    the entry or anytime after, by choosing a customized list of
                    people to share with.
                </li>
                <li>
                    <strong>Information about horses, including any photos, 
                    videos, or documents:</strong> You can choose who is related
                    to the horse and with which role, and only people who are 
                    related have access to the information you associate with
                    the horse.
                </li>
                <li>
                    <strong>Information about yourself:</strong> Your profile
                    information is visible to other members you interact with.
                </li>
            </ul>

            <p>For any requests regarding your data, you can contact us using
            the feedback tool in the Service.</p>

            <h2>How long do we keep your information?</h2>

            <p>We will retain your information, whether or not your account is 
            active, for as long as we deem it is reasonably necessary to 
            fulfill {siteName} business purposes or to comply with applicable 
            law, audit requirements, regulatory requests or orders from 
            competent courts. </p>

            <h2>What about links to other web sites and services?</h2>
        
            <p>{siteName} Service and email messages you receive from us may link 
            to websites, applications or other digital properties that have 
            privacy provisions different than the provisions of this Privacy 
            Policy. Similarly, {siteName} users may share with you content that
            include links to other websites, applications, or digital properties.
            We recommend that you review such other privacy provisions 
            carefully to assure your privacy is protected. {siteName} is not 
            responsible for the practices employed by such linked third-party 
            websites, applications or other digital properties, nor the 
            information or content contained therein.</p>

            <h2>What are California privacy rights?</h2>

            <p>Under California’s “Shine the Light” law, California residents 
            who provide “personal information” (as defined in the law) in 
            obtaining products or services for personal, family or household 
            use are entitled to request and obtain from us once a calendar year 
            information about the personal information we shared, if any, with 
            other businesses for their direct marketing uses. If applicable, 
            this information would include the categories of personal 
            information and the names and addresses of those businesses with 
            which we shared such personal information for the immediately prior 
            calendar year (e.g. requests made in 2025 will receive information 
            about 2024). To obtain this information please write to us at the 
            address listed below in the Contact Us section. Please include your 
            full name and address.</p>

            <h2>Information choices</h2>

            <p> You may choose to deactivate or otherwise manage your Member 
            Account as set forth in the <TermsOfUseLink/>. You may update your 
            information and email-communication preferences at any time by 
            using the feedback tool in the Service. You can also stop receiving 
            promotional email communications from us by clicking on the 
            “unsubscribe link” provided in such communications. You may not opt 
            out of Service-related communications (e.g., changes/updates to 
            features of the Service, technical and security notices). If you 
            have any questions about reviewing or modifying your information, 
            you can contact us using the feedback form in the Service.
            </p>

            <p> If you deactivate your Member Account, we may still retain 
            certain information associated with your account for analytical 
            purposes and record keeping integrity, as well as to prevent fraud, 
            resolve disputes, enforce our <TermsOfUseLink/> or other policies, 
            take actions we deem necessary due to technical and legal 
            requirements, and as dictated by constraints related to the 
            security, integrity and operation of our Service. In addition, if 
            certain information has already been provided to third parties as 
            described in this Privacy Policy, retention of that information 
            will be subject to those third parties' policies. We may still 
            retain your information in our files however, to resolve disputes 
            and enforce our <TermsOfUseLink/>. 
            </p>

            <h2>Are changes ever made to this Privacy Policy?</h2>

            <p>As the Service change from time to time, this Privacy Policy is 
            expected to change as well. We reserve the right to amend the 
            Privacy Policy at any time, for any reason. The date of the last 
            revision to the Privacy Policy will be indicated by the 
            "Last Updated" date on the top of the first page of this Privacy 
            Policy. Changes to this Privacy Policy are effective when they are 
            posted on this page, so please check back often for updates. Your 
            continued use of the Service after any change constitutes your 
            acceptance of the new Privacy Policy. If you do not agree to any of 
            these terms or any future Privacy Policy, you may not use or access 
            (or continue to access) the Service. 
            </p>

            <h2>How do you contact us?</h2>

            <p>If you have questions, you can contact us by using the feedback
            tool in the Service.</p>
        </>
    );
}