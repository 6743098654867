// @ts-check

/**
 * @typedef {Object} RelationshipListProps
 * @prop {Array<import("../api").FemPersonEquineRelationshipForRoleAssignmentRead>} personEquineRelationships
 * @prop {import("../api").FemRoleToDisplayNameMapping} allRelationships
 * @prop {number} equineId
 * @prop {boolean} isEquineDeleted
 * @prop {(updatedRelationships: Array<import("../api").FemPersonEquineRelationshipForRoleAssignmentRead>) => void | Promise<void>} [onPersonRelationshipsChange]
 */

import React from "react";
import { createNewArrayAndReplaceItem } from "react-base/dist/helpers/others";
import RelationshipCreateOrEditButton from "./relationship-create-or-edit-button";
import { removeAndReturnNewArrayIfRemoved } from "../helpers/others";

/**
 * 
 * @param {RelationshipListProps} param0 
 * @returns {React.JSX.Element}
 */
export default function RelationshipsList({
    personEquineRelationships,
    allRelationships,
    equineId,
    isEquineDeleted,
    onPersonRelationshipsChange: onPersonRelationshipsChange,
}) {
    /**
     * @typedef {Object} PersonEquineRelationshipRowProps
     * @prop {import("../api").FemRoleToDisplayNameMapping} roleLabelMap
     * @prop {import("../api").FemPersonEquineRelationshipForRoleAssignmentRead} personEquineRelationship
     */
    /**
     * 
     * @param {PersonEquineRelationshipRowProps} param0 
     * @returns {React.JSX.Element}
     */
    function PersonRelationshipRow({roleLabelMap, personEquineRelationship}) {
        return (
            <tr key={personEquineRelationship.person.id}>
                <td>{personEquineRelationship.person.display_name}</td>
                <td>{personEquineRelationship.roles.map((roleKey) => roleLabelMap[roleKey]).join(", ")}</td>
                <td>
                    <RelationshipCreateOrEditButton
                        additionalButtonStyles="is-small"
                        label="Edit"
                        allRelationships={allRelationships}
                        equineId={equineId}
                        onRelationshipSaved={handleRelationshipSaved}
                        onRelationshipRemoved={handleRelationshipRemoved}
                        initialPerson={({
                            ...personEquineRelationship.person,
                            relationship: {
                                equine_id: equineId,
                                roles: personEquineRelationship.roles,
                                creatable_roles: personEquineRelationship.creatable_roles,
                                removable_roles: personEquineRelationship.removable_roles,
                            },
                        })}
                        initialRolesSelection={personEquineRelationship.roles}
                        submitButtonLabel="Save"
                        allowEmptyRolesSelection={!personEquineRelationship.person.is_current_user}
                    />
                </td>
            </tr>
        );
    }

    /**
     * 
     * @param {import("../api").FemPersonEquineRelationshipForRoleAssignmentRead} savedRelationship 
     */
    function handleRelationshipSaved(savedRelationship) {
        const index = personEquineRelationships.findIndex((rel) => rel.person.id === savedRelationship.person.id);
        const newVal = createNewArrayAndReplaceItem(personEquineRelationships, savedRelationship, index);
        if (onPersonRelationshipsChange) {
            onPersonRelationshipsChange(newVal);
        }
    }

    /** @type {(personId: number) => void | Promise<void>} */
    function handleRelationshipRemoved(personId) {
        const newVal = removeAndReturnNewArrayIfRemoved((rel) => rel.person.id === personId, personEquineRelationships);
        if (newVal !== personEquineRelationships && onPersonRelationshipsChange) {
            onPersonRelationshipsChange(newVal);
        }
    }

    return (
        <table className="table is-striped is-fullwidth">
            <thead>
                <th className="pt-4">Name</th>
                <th className="pt-4">Roles</th>
                <th className="is-narrow has-text-right">
                    {isEquineDeleted
                    ? <></>
                    :
                        <RelationshipCreateOrEditButton
                            additionalButtonStyles="is-small"
                            allRelationships={allRelationships}
                            equineId={equineId}
                            onRelationshipSaved={handleRelationshipSaved}
                            onRelationshipRemoved={handleRelationshipRemoved}
                            submitButtonLabel="Save"
                        />
                    }
                </th>
            </thead>
            <tbody>
                {personEquineRelationships.map((ur) => (<PersonRelationshipRow roleLabelMap={allRelationships} personEquineRelationship={ur} />))}
            </tbody>
        </table>
    );
}
