// @ts-check

import Linkify from "linkify-react";
import React from "react";

/**
 * 
 * @param {{content: string}} param0 
 * @returns {React.JSX.Element}
 */
export default function TextContent({content}) {
    /**
     * 
     * @param {string} value 
     * @returns {string}
     */
    function formatUrl(value) {
        try {
            const valueWithProtocol = (
                value.startsWith("http://") || value.startsWith("https://")
                ? value
                : "https://" + value
            );
            const {hostname} = new URL(valueWithProtocol);
            // extract only the last two components of domain
            const hostnameParts = hostname.split(".");
            return "[Link to "
                + hostnameParts
                    .slice(hostnameParts.length-2, hostnameParts.length)
                    .join(".")
                + "]";
        } catch (err) {
            console.log(err)
            // ignore the err and use the full url
            return "[" + value + "]";
        }
    }

    const retval = [];
    for (const line of content.split("\n")) {
        if (line) {
            retval.push(
                <p>
                    <Linkify
                        options={{
                            defaultProtocol: "https",
                            format: { url: formatUrl },
                        }}
                    >
                        {line}
                    </Linkify>
                </p>);
        }
    }
    return <>{retval}</>;
}

