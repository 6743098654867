// @ts-check

import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import TableWithButtons from "../components/general-purpose/table-with-buttons";
import { userUserRelationshipAcceptRequest, userUserRelationshipIgnoreRequest } from "../api";
import ApiButton from "../components/general-purpose/api-button";
import { removeFromEntityListState } from "../helpers/others";

export default function Invitations() {
    const loadedApiData =
        /** @type {import("../api").FemUserUserRelationshipsSuccessfulInvitationsGetAllResponse} */
        (useLoaderData());
    const [inviters, setInviters] = useState(loadedApiData.collection);

    return (
        <>
            <h1 className="title">Invitations</h1>
            <TableWithButtons
                entities={inviters}
                setEntities={setInviters}
                emptyListText="You have no pending invitations."
                tableHeaderElements={
                    <th>Name</th>
                }
                oneRowTableDataElements={(entity) => 
                    <td>{entity.person.display_name}</td>
                }
                rowButtons={[
                    {
                        type: "delete",
                        elementFn: ({entity, additionalButtonStyles}) =>
                            <ApiButton
                                additionalButtonStyles={additionalButtonStyles}
                                femEntity={entity}
                                label="Accept"
                                request={(entity) => userUserRelationshipAcceptRequest(entity.id)}
                                onSuccess={(entity) => removeFromEntityListState(entity, setInviters)}
                            />
                    },
                    {
                        type: "delete",
                        elementFn: ({entity, additionalButtonStyles}) =>
                            <ApiButton
                                additionalButtonStyles={additionalButtonStyles}
                                femEntity={entity}
                                label="Ignore"
                                request={(entity) => userUserRelationshipIgnoreRequest(entity.id)}
                                onSuccess={(entity) => removeFromEntityListState(entity, setInviters)}
                            />
                    }
                ]}
            />
        </>
    );
}