// @ts-check

import React, { useState } from "react";
import { checkValue, field, setAndCheck, textAreaField } from "../../helpers/others";
import { ActionButton, BulmaLevel, useErrors } from "react-base";
import toast from "react-hot-toast";
import TextContent from "../general-purpose/text-content";

const RESPONSE_NOTE_MAX_LENGTH = 2000;
const ERROR_FIELD_RESPONSE_NOTE = "name";
const ERROR_RESPONSE_NOTE_TOO_LONG = "too-long";
const ERROR_RESPONSE_NOTE_LENGTH_MESSAGE_TOO_LONG =
    `Request message must be at most ${RESPONSE_NOTE_MAX_LENGTH} characters.`;

const ERR_API = 'api';
const ERR_API_SUBMIT_FAILED = 'failure';

/**
 * @callback ServiceProviderEquineRelationshipResponseFormSubmitHandler 
 * @param {number} serviceProviderEquineRelationshipId
 * @param {import("../../api").FemServiceProviderEquineRelationshipResponseWrite} data
 * @returns {Promise<import("../../api").FemApiErrorResponse | null>}
 */

/**
 * @typedef {Object} ServiceProviderEquineRelationshipResponseFormProps
 * @prop {import("../../api").FemServiceProviderEquineRelationshipRead} entity
 * @prop {ServiceProviderEquineRelationshipResponseFormSubmitHandler} onAccept
 * @prop {ServiceProviderEquineRelationshipResponseFormSubmitHandler} onReject
 * @prop {string} [acceptButtonLabel = "Approve"]
 * @prop {string} [rejectButtonLabel = "Reject"]
 */

/**
 *
 * @param {ServiceProviderEquineRelationshipResponseFormProps} param0
 * @returns {React.JSX.Element}
 */
export default function ServiceProviderEquineRelationshipResponseForm({
    entity,
    onAccept,
    onReject,
    acceptButtonLabel = "Approve",
    rejectButtonLabel = "Reject",
}) {
    const [responseNote, setResponseNote] = useState(entity?.response_note ?? '');
    const [isValid, setIsValid] = useState(true);
    const {setError, resetError, renderErrors} = useErrors();

   /**
     * @typedef {Object} CheckValidityProps
     * @prop {string} [currentResponseNote = responseNote]
     */
   /**
     * 
     * @param {CheckValidityProps} [param0 = {}]
     * @returns {boolean}
     */
    function checkValidity({
        currentResponseNote = responseNote,
    } = {}) {
        const checks = [
            // request note
            checkValue({
                errorCondition: currentResponseNote !== undefined && currentResponseNote.length > RESPONSE_NOTE_MAX_LENGTH,
                errFieldName: ERROR_FIELD_RESPONSE_NOTE,
                errConditionName: ERROR_RESPONSE_NOTE_TOO_LONG,
                errMessage: ERROR_RESPONSE_NOTE_LENGTH_MESSAGE_TOO_LONG
            }, setError, resetError),
        ];
        const validity = (checks.find((v) => v === false) === undefined);
        setIsValid(validity);
        return validity;
    }

    function anyChange() {
        resetError(ERR_API, ERR_API_SUBMIT_FAILED);
    }

    /**
     * 
     * @param {() => void} actionButtonCallback 
     * @param {ServiceProviderEquineRelationshipResponseFormSubmitHandler} formCallback
     * @returns {Promise<void>}
     */
    async function handleButtonClick(actionButtonCallback, formCallback) {
        if (checkValidity()) {
            let response;

            const submitData = /** @type {import("../../api").FemServiceProviderEquineRelationshipResponseWrite} */ ({
                response_note: responseNote,
            });
            response = await formCallback(entity.id, submitData);
            actionButtonCallback();
            if (response !== null) {
                const errorResponse = /** @type {import("../../api").FemApiErrorResponse} */ (response);
                toast.error("Request failed.")
                setError(ERR_API, ERR_API_SUBMIT_FAILED, errorResponse.errors);
                return;
            }
        } else {
            actionButtonCallback();
        }
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleAcceptButtonClick(cb) {
        await handleButtonClick(cb, onAccept);
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleRejectButtonClick(cb) {
        await handleButtonClick(cb, onReject);
    }

    return (
        <form className="has-text-left">
            {entity.request_note && entity.request_note.length > 0
                ?
                    field({
                        label: "Requester's message",
                        children:
                            <TextContent content={entity.request_note} />
                    })
                : <p>Requester did not provide a message with this request.</p>
            }

            {textAreaField({
                label: "Your response",
                placeholder: "Your message to the requester (optional)",
                value: responseNote,
                onChange: (event) =>
                    setAndCheck(event.target.value, setResponseNote, "currentResponseNote", checkValidity, anyChange),
            })}

            {renderErrors()}

            <BulmaLevel
                right={[
                    <ActionButton
                        disabled={!isValid}
                        additionalButtonStyles="is-success"
                        onClick={handleAcceptButtonClick}>{acceptButtonLabel}</ActionButton>
                ]}
                left={[
                    <ActionButton
                        disabled={!isValid}
                        additionalButtonStyles="is-danger"
                        onClick={handleRejectButtonClick}>{rejectButtonLabel}</ActionButton>
                ]}
            />

        </form>
    );
}