// @ts-check

import React, { useState } from "react";
import { horseNameToDisplay } from "../helpers/horse";
import { ActionButton, BulmaLevel, useApi } from "react-base";
import { removeAndReturnNewArrayIfRemoved } from "../helpers/others";
import RelationshipCheckboxes from "./relationships-checkboxes";
import { ponylogEntryUpdateSharedWithRequest } from "../api";
import toast from "react-hot-toast";

/**
 * @callback PonylogShareFormSaveHandler
 * @param {Array<import("../api").FemPersonMention>} sharedWith
 * @returns {void | Promise<void>}
 */

/**
 * @typedef {Object} PonylogShareFormProps
 * @prop {import("../api").FemPonylogEntryRead} entry
 * @prop {string} [submitButtonLabel = "Share"]
 * @prop {import("../api").FemRoleToDisplayNameMapping} allAvailableRoles
 * @prop {PonylogShareFormSaveHandler} [onSave]
 */

/**
 * 
 * @param {PonylogShareFormProps} param0
 * @returns {React.JSX.Element}
 */
export default function PonylogShareForm({
    entry,
    submitButtonLabel = "Share",
    allAvailableRoles,
    onSave,
}) {
    const fetchFromApi = useApi();

    const allRelatedPersonsIdToMentionMap = entry.equine.person_relationships
        .reduce((acc, rel) => ({...acc, [rel.person.id]: rel.person}),
        /** @type {{[key: number]: import("../api").FemPersonMention}} */ ({}));
    for (const sharedWithPerson of entry.shared_with ?? []) {
        allRelatedPersonsIdToMentionMap[sharedWithPerson.id] = sharedWithPerson;
    }
    const currentPersonId = entry.equine.person_relationships.find((rel) => rel.person.is_current_user)?.person.id;
    const nonRemovableIds = (currentPersonId ? [currentPersonId] : []);

    /**
     * 
     * @param {number} personId 
     * @returns {import("../api").FemPersonMention | null}
     */
    function lookupPerson(personId) {
        const person = allRelatedPersonsIdToMentionMap[personId] ?? null;
        if (!person) {
            console.error("Internal error: Could not found a person that we expected to find.");
        }
        return person;
    }

    /**
     * @param {Array<import("../api").FemPersonEquineRelationshipRead>} allRelationships 
     * @returns {Array<number>}
     */
    function personIdsFromRelationships(allRelationships) {
        return allRelationships.map((rel) => rel.person.id);
    }

    /**
     * 
     * @param {Array<import("../api").FemPersonEquineRelationshipRead>} allRelationships 
     * @param {Array<number>} sharedWithPersonIds
     * @returns {boolean} True if sharedWith contains everyone related to the equine in allRelationships param.
     */
    function checkIfSharedWithEveryone(allRelationships, sharedWithPersonIds) {
        const allRelatedPersonIdsThatAreNotSharedWith = personIdsFromRelationships(allRelationships)
            .filter((relatedPersonId) => !sharedWithPersonIds.includes(relatedPersonId))
        return allRelatedPersonIdsThatAreNotSharedWith.length === 0;
    }

    /**
     * 
     * @param {Array<import("../api").FemPersonEquineRelationshipRead>} allRelationships 
     * @param {import("../api").FemRole} role 
     * @returns {Array<number>}
     */
    function allPersonIdsWithRole(allRelationships, role) {
        return personIdsFromRelationships(
            allRelationships.filter((rel) => rel.roles.includes(role)));
    }

    /**
     * 
     * @param {Array<import("../api").FemPersonEquineRelationshipRead>} allRelationships 
     * @param {Array<number>} sharedWithPersonIds 
     * @param {import("../api").FemRole} role 
     * @returns {[boolean, boolean]} First element is true if shared with someone with role, and second is true if shared with everyone with role.
     */
    function sharingStatusWithRole(
        allRelationships,
        sharedWithPersonIds,
        role,
    ) {
        const allWithRole = allPersonIdsWithRole(allRelationships, role);
        const allPersonIdsWithRoleThatAreNotSharedWith =
            allWithRole.filter((personId) => !sharedWithPersonIds.includes(personId));
        const sharedWithAnyPersonWithRole =
            allWithRole.some((personId) => sharedWithPersonIds.includes(personId));
        return [
            sharedWithAnyPersonWithRole,
            allWithRole.length > 0 && allPersonIdsWithRoleThatAreNotSharedWith.length === 0,
        ];
    }

    /**
     * 
     * @param {import("../api").FemRoleToDisplayNameMapping} allAvailableRoles
     * @param {Array<import("../api").FemPersonEquineRelationshipRead>} allRelationships 
     * @param {Array<number>} sharedWithPersonIds
     * @returns {Array<import("../api").FemRole>}
     */
    function sharedWithRoles(allAvailableRoles, allRelationships, sharedWithPersonIds) {
        return /** @type {Array<import("../api").FemRole>} */ (Object.keys(allAvailableRoles))
            .filter((role) => sharingStatusWithRole(allRelationships, sharedWithPersonIds, role)[1]);
    }

    /**
     * 
     * @param {import("../api").FemRoleToDisplayNameMapping} allAvailableRoles
     * @param {Array<import("../api").FemPersonEquineRelationshipRead>} allRelationships 
     * @param {Array<number>} sharedWithPersonIds
     * @returns {Array<import("../api").FemRole>}
     */
    function onlyPartiallySharedWithRoles(allAvailableRoles, allRelationships, sharedWithPersonIds) {
        return /** @type {Array<import("../api").FemRole>} */ (Object.keys(allAvailableRoles))
            .filter((role) => {
                const [some, all] = sharingStatusWithRole(allRelationships, sharedWithPersonIds, role)
                return some && !all;
            });
    }
    
    /**
     * 
     * @param {Array<import("../api").FemPersonEquineRelationshipRead>} allRelationships 
     * @returns {Set<import("../api").FemRole>}
     */
    function rolesSomeRelatedPersonHas(allRelationships) {
        const roles = allRelationships.flatMap((rel) => rel.roles);
        return new Set(roles);
    }

    /**
     * 
     * @param {import("../api").FemRoleToDisplayNameMapping} allAvailableRoles
     * @param {Array<import("../api").FemPersonEquineRelationshipRead>} allRelationships 
     * @param {Array<number>} sharedWithPersonIds
     * @returns {import("./relationships-checkboxes").RoleStates}
     */
    function roleStatesFromSharedWith(allAvailableRoles, allRelationships, sharedWithPersonIds) {
        const sharedWithAllPersonsOfRoles =
            sharedWithRoles(allAvailableRoles, allRelationships, sharedWithPersonIds);
        const sharedWithSomePersonsOfRoles =
            onlyPartiallySharedWithRoles(allAvailableRoles, allRelationships, sharedWithPersonIds);
        const retval = /** @type {import("./relationships-checkboxes").RoleStates} */ (
            /** @type {Array<import("../api").FemRole>} */ (Object.keys(allAvailableRoles))
                .reduce((acc, role) => ({
                    ...acc,
                    [role]: sharedWithAllPersonsOfRoles.includes(role)
                        ? "checked"
                        : (sharedWithSomePersonsOfRoles.includes(role)
                            ? "indeterminate"
                            : "unchecked")
                }), {})
        );
        return retval;
    }

    // *****************************************************************************************************************
    // STATES

    // edited list of person ids to share the entry with
    const [sharedWith, setSharedWith] = useState((entry.shared_with ?? []).map((person) => person.id));

    const [rolesToIncludeAllPersonsOf, setRolesToIncludeAllPersonsOf] =
        useState(
            roleStatesFromSharedWith(
                allAvailableRoles, entry.equine.person_relationships, sharedWith));
    
    const [selectedPersonId, setSelectedPersonId] = useState(-1);

    // STATES
    // *****************************************************************************************************************

    /**
     * 
     * @param {Array<import("../api").FemPersonEquineRelationshipRead>} allRelationships 
     * @param {boolean} newSharedWithEveryoneState 
     * @returns {void}
     */
    function handleSharedWithEveryoneCheckboxChange(allRelationships, newSharedWithEveryoneState) {
        const sharedWithPersonIds = newSharedWithEveryoneState
            ? personIdsFromRelationships(allRelationships)
            : nonRemovableIds;
        setSharedWith(sharedWithPersonIds);
        setRolesToIncludeAllPersonsOf(
            roleStatesFromSharedWith(
                allAvailableRoles, entry.equine.person_relationships, sharedWithPersonIds));
    }

    /**
     * 
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
     * @param {number} personId 
     */
    function handlePersonDeleteButtonClick(event, personId) {
        event.stopPropagation();
        event.preventDefault();

        // remove person from shared with list
        if (!nonRemovableIds.includes(personId)) {
            const newSharedWith = removeAndReturnNewArrayIfRemoved(personId, sharedWith);
            setSharedWith(newSharedWith);
            setRolesToIncludeAllPersonsOf(
                roleStatesFromSharedWith(allAvailableRoles, entry.equine.person_relationships, newSharedWith));
        }
    }

    /** @type {import("./relationships-checkboxes").RoleStateChangeHandler} */
    function handleRoleStateChange(newState, role) {
        const allPersonsWithRole =
            allPersonIdsWithRole(entry.equine.person_relationships, role);
        const newSharedWith = (newState === "checked"
            ? Array.from(new Set([...sharedWith, ...allPersonsWithRole]))
            : sharedWith.filter((personId) => nonRemovableIds.includes(personId) || !allPersonsWithRole.includes(personId))
        );
        setSharedWith(newSharedWith);
        setRolesToIncludeAllPersonsOf(
            roleStatesFromSharedWith(
                allAvailableRoles, entry.equine.person_relationships, newSharedWith));
    }

    /** @type {React.MouseEventHandler<HTMLButtonElement>} */
    function handlePersonAddClick(event) {
        event.stopPropagation();
        event.preventDefault();
        if (selectedPersonId != -1) {
            setSharedWith((oldVal) => {
                const newSharedWith = [...oldVal, selectedPersonId];
                setRolesToIncludeAllPersonsOf(
                    roleStatesFromSharedWith(
                        allAvailableRoles, entry.equine.person_relationships, newSharedWith));
                return newSharedWith;
            });
        }
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleFormButtonClick(cb) {
        /** @type {import("../api").FemPonylogEntrySharedWithWrite} */
        const dataToSubmit = { sharedWith }
        const apiResponse = await fetchFromApi(
            ponylogEntryUpdateSharedWithRequest(entry.id, dataToSubmit));
        if (apiResponse.isOk) {
            if (onSave) {
                /** @type {Array<import("../api").FemPersonMention>} */
                const newListOfSharedWithPersons = sharedWith.map((personId) => {
                    const person = allRelatedPersonsIdToMentionMap[personId];
                    return {
                        id: person.id,
                        display_name: person.display_name,
                    };
                })
                await onSave(newListOfSharedWithPersons);
            }
        } else {
            toast.error("Unable to share.")
        }
        cb();
    }

    return (
        <form className="has-text-left">
            <div className="field">
                <div className="control">
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            checked={checkIfSharedWithEveryone(entry.equine.person_relationships, sharedWith)}
                            onChange={(e) => handleSharedWithEveryoneCheckboxChange(entry.equine.person_relationships, e.target.checked)}
                        />&nbsp;Share with everyone related to {horseNameToDisplay(entry.equine)}
                    </label> 
                </div>
            </div>
            <RelationshipCheckboxes
                allRelationships={allAvailableRoles}
                rolesThatCanBeChecked={rolesSomeRelatedPersonHas(entry.equine.person_relationships)}
                label="People with roles to share with:"
                roleStates={rolesToIncludeAllPersonsOf}
                onRoleStateChange={handleRoleStateChange}
            />

            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Share with</label>
                </div>
                <div className="field-body has-addons">
                    <div className="control select is-expanded is-fullwidth">
                        <select
                            value={selectedPersonId?.toString() ?? "-1"}
                            onChange={(event) => setSelectedPersonId(Number(event.target.value))}
                        >
                            <option value="-1"></option>
                            {entry.equine.person_relationships
                                .filter((rel) => !nonRemovableIds.includes(rel.person.id))
                                .map((rel) => [rel.person.id.toString(), rel.person.display_name])
                                .map(([v, element]) => (
                                    <option value={v}>{element}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="control">
                        <button
                            className="button ml-3"
                            onClick={handlePersonAddClick}
                            disabled={selectedPersonId === -1}
                        >Add</button>
                    </div>
                </div>
            </div>

            <div className="field is-grouped is-grouped-multiline">
                <label className="label">This PonyLog entry will be visible to these people:</label>
                {sharedWith.length === 0 ? (<p>Only you can see the PonyLog entry.</p>) : (
                    sharedWith.map((sharedWithPersonId) => (
                        <div className="control">
                            <div className="tags has-addons">
                                <span className="tag">{lookupPerson(sharedWithPersonId)?.display_name ?? "???"
                                    }{sharedWithPersonId === currentPersonId ? " (you)" : ""
                                    }</span>
                                {!nonRemovableIds.includes(sharedWithPersonId) ? (
                                    <button className="tag is-delete"
                                        onClick={(e) => handlePersonDeleteButtonClick(e, sharedWithPersonId)} />
                                ) : ""}
                            </div>
                        </div>
                    ))
                )}
            </div>
            <BulmaLevel
                right={[
                    <ActionButton onClick={handleFormButtonClick}>{submitButtonLabel}</ActionButton>
                ]}
            />
        </form>
    );
}