// @ts-check

import React from "react";
import DeletableItemsList from "../components/general-purpose/deletable-items-list";
import { serviceProviderCreateRequest, serviceProviderDeleteRequest, serviceProviderPurgeRequest, serviceProviderUndeleteRequest, serviceProviderUpdateRequest } from "../api";
import { useLoaderData } from "react-router-dom";
import { path } from "react-base";
import paths from "../paths";
import ManagedServiceProviderForm from "../components/managed-service-provider-profile/managed-service-provider-form";

export default function ManagedServiceProviders() {
    const apiLoaderData = /** @type {import("../api").FemServiceProviderSuccessfulGetAllResponse} */ (useLoaderData());
    /** @type {import("../api").FemServiceProviderGetAllResponseOtherData} */
    const otherData = apiLoaderData.other_data;

    /**
     * 
     * @param {number} id 
     * @returns {string}
     */
    function navigateToServiceProviderHome(id) {
        return path(paths.managedServiceProviders.profile, {serviceProviderId: id});
    }

    return (
        <>
            <h1 className="title">Your Service Providers</h1>
            <DeletableItemsList
                emptyEntityListText="There are no service providers you are currently managing."
                entities={apiLoaderData.collection}
                deletedEntities={otherData.deleted_service_providers}
                entityProfilePath={navigateToServiceProviderHome}
                createRequest={serviceProviderCreateRequest}
                updateRequest={serviceProviderUpdateRequest}
                deleteRequest={serviceProviderDeleteRequest}
                purgeRequest={serviceProviderPurgeRequest}
                undeleteRequest={serviceProviderUndeleteRequest}
                tableHeaderElements={
                    <>
                        <th className="pt-4">Name</th>
                        <th className="pt-4 is-hidden-touch">Managers</th>
                    </>
                }
                oneRowTableDataElements={(entity) => (
                    <>
                        <td>
                            {entity.name}
                        </td>
                        <td className="is-hidden-touch">
                            {(entity.managers ?? []).map((userMention) => userMention.display_name).join(", ")}
                        </td>
                    </>
                )}
                deletedEntitiesTableHeaderElements={
                    <th className="pt-4">Name</th>
                }
                oneRowDeletedEntitiesTableDataElement={(entity) => (
                    <td onClick={() => navigateToServiceProviderHome(entity.id)}>
                        {entity.name}
                    </td>
                )}
                CreateForm={ManagedServiceProviderForm}
                UpdateForm={ManagedServiceProviderForm}
            />
        </>
    );
}