// @ts-check

/**
 * @typedef {Object} RelationshipFormProps
 * @prop {string} [submitButtonLabel="Add"]
 * @prop {import("../api").FemRoleToDisplayNameMapping} allRelationships
 * @prop {Array<import("../api").FemRole>} [initialRolesSelection=[]]
 * @prop {import("../api").FemRelationshipPersonSearchResultItem | null} [initialPerson=null]
 * @prop {number} equineId
 * @prop {(savedRelationship: import("../api").FemPersonEquineRelationshipForRoleAssignmentRead) => void | Promise<void>} [onRelationshipSaved]
 * @prop {(personId: number) => void | Promise<void>} onRelationshipRemoved
 * @prop {boolean} [allowEmptyRolesSelection=false]
 */

import React, { useEffect, useState } from "react";
import { ActionButton, BulmaLevel, useApi, useErrors } from "react-base";
import RelationshipCheckboxes, { roleStatesFromRolesList } from "./relationships-checkboxes";
import { personEquineUpdateRelationshipRequest, personsSearchWithEquineRelationshipRequest } from "../api";
import toast from "react-hot-toast";
import ApiSearchInput from "./general-purpose/api-search-input";

/**
 * 
 * @param {RelationshipFormProps} param0
 * @returns {React.JSX.Element}
 */
export default function RelationshipForm({
    allRelationships,
    submitButtonLabel = "Add",
    initialRolesSelection = [],
    initialPerson = null,
    equineId,
    onRelationshipSaved,
    onRelationshipRemoved,
    allowEmptyRolesSelection = false
}) {
    const fetchFromApi = useApi();

    const ERROR_FIELD_PERSON = "person";
    const ERROR_PERSON_REQUIRED = "required";
    const ERROR_PERSON_REQUIRED_MESSAGE = "You must select a person.";
    const ERR_RELATIONSHIPS = 'relationship-checkboxes';
    const ERR_RELATIONSHIPS_AT_LEAST_ONE = 'at-least-one';
    const {setError, resetError, renderErrors, anyErrors} = useErrors();

    /**
     * 
     * @param {import("./relationships-checkboxes").RoleStates} currentRoleStates 
     * @returns {boolean}
     */
    function checkRoleStates(currentRoleStates) {
        const currentSelectedRoles =
            /** @type {Array<import("../api").FemRole>} */ (Object.keys(currentRoleStates))
                .filter((role) => currentRoleStates[role] === "checked");
        let isRelationshipsValid = true;
        if (!allowEmptyRolesSelection && currentSelectedRoles.length === 0) {
            setError(ERR_RELATIONSHIPS,
                ERR_RELATIONSHIPS_AT_LEAST_ONE,
                "You must select at least one relationship.");
            isRelationshipsValid = false;
        } else {
            resetError(ERR_RELATIONSHIPS, ERR_RELATIONSHIPS_AT_LEAST_ONE);
        }
        return isRelationshipsValid;
    }

    /**
     * 
     * @param {import("../api").FemRelationshipPersonSearchResultItem | null} person 
     * @returns {boolean}
     */
    function checkValidPerson(person) {
        if (initialPerson || person) {
            resetError(ERROR_FIELD_PERSON, ERROR_PERSON_REQUIRED);
            return true;
        } else {
            setError(ERROR_FIELD_PERSON, ERROR_PERSON_REQUIRED, ERROR_PERSON_REQUIRED_MESSAGE);
            return false;
        }
    }

    /**
     * 
     * @param {import("../api").FemRelationshipPersonSearchResultItem | null} person 
     * @param {import("./relationships-checkboxes").RoleStates} currentRoleStates 
     */
    function checkIsValid(person, currentRoleStates) {
        return [
            checkValidPerson(person),
            checkRoleStates(currentRoleStates),
        ].reduce((acc, val) => acc && val, true);
    }

    const personInitialValue =
        /** @type {import("../api").FemRelationshipPersonSearchResultItem | null} */ (initialPerson);
    const [person, setPerson] = useState(personInitialValue);

    const [roleStates, setRoleStates] =
        useState(roleStatesFromRolesList(allRelationships, initialRolesSelection));
    
    useEffect(() => {
        checkIsValid(person, roleStates);
    }, [])

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleFormButtonClick(cb) {
        if (checkIsValid(person, roleStates)) {
            /** @type {import("../api").FemPersonEquineRelationshipWriteUpsert} */
            const dataToSubmit = {
                person_id:
                    /** @type {import("../api").FemRelationshipPersonSearchResultItem} */ (initialPerson ?? person).id,
                equine_id: equineId,
                roles: /** @type {Array<import("../api").FemRole>} */ (Object.keys(roleStates))
                    .filter((role) => roleStates[role] === "checked")
            }
            const apiResponse = await fetchFromApi(
                personEquineUpdateRelationshipRequest(
                    // checkIsValid should guarantee not null
                    /** @type {import("../api").FemRelationshipPersonSearchResultItem} */ (initialPerson ?? person).id,
                    dataToSubmit))
            if (apiResponse.isOk && apiResponse.status === 200 && apiResponse.data !== undefined) {
                if (apiResponse.data !== undefined && onRelationshipSaved) {
                    const updatedRelationship =
                        /** @type {import("../api").FemPersonEquineRelationshipUpdateResponse} */ (apiResponse.data).entity;
                    await onRelationshipSaved(updatedRelationship);
                } else {
                    console.error("Update did not return updated entity.");
                }
            } else if (apiResponse.isOk && apiResponse.status === 204 && dataToSubmit.roles.length === 0) {
                await onRelationshipRemoved(dataToSubmit.person_id);
            } else {
                toast.error("Error saving relationship.");
            }
        }
        cb();
    }

    /** @type {import("./general-purpose/api-search-input").ApiSearchInputSelectHandler<import("../api").FemRelationshipPersonSearchResultItem>} */
    function handleEquineRelatedPersonInputSelect(selectedPerson) {
        if (checkValidPerson(selectedPerson)) {
            const newRelationships = selectedPerson?.relationship.roles ?? [];
            const newRoleStates = roleStatesFromRolesList(allRelationships, newRelationships);
            setRoleStates(newRoleStates);
            checkRoleStates(newRoleStates);
        }
        setPerson(selectedPerson);
    }

    /** @type {React.Dispatch<React.SetStateAction<import("./relationships-checkboxes").RoleStates>>} */
    function checkAndSetRoleStates(param) {
        setRoleStates((oldVal) => {
            const newVal = (param instanceof Function ? param(oldVal) : param);
            checkRoleStates(newVal);
            return newVal;
        });
    }

    return (
        <form className="has-text-left has-text-weight-normal">
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Person</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            {initialPerson ? (
                                <p className="pt-2">{initialPerson.display_name}</p>
                            ) : (
                                <ApiSearchInput
                                    value={person}
                                    onSelect={handleEquineRelatedPersonInputSelect}
                                    searchRequest={(searchText) =>
                                        personsSearchWithEquineRelationshipRequest({searchText, equineId})}
                                    entityToLabel={(entity) => entity.display_name}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <RelationshipCheckboxes
                allRelationships={allRelationships}
                rolesThatCanBeChecked={person?.relationship.creatable_roles
                    ? new Set(person?.relationship.creatable_roles)
                    : undefined}
                rolesThatCanBeUnchecked={person?.relationship.removable_roles
                    ? new Set(person?.relationship.removable_roles)
                    : undefined}
                roleStates={roleStates}
                setRoleStates={checkAndSetRoleStates}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {renderErrors()}
            <BulmaLevel
                right={[
                    <ActionButton disabled={anyErrors()} onClick={handleFormButtonClick}>{submitButtonLabel}</ActionButton>
                ]}
            />
        </form>
    );
}