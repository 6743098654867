// @ts-check


import React, { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { path } from "react-base";
import paths from "../paths";
import EquineEditButton from "../components/equine-edit-button";
import EquineDeleteButton from "../components/equine-delete-button";
import EquineCreateButton from "../components/equine-create-button";
import { horseNameToDisplay } from "../helpers/horse";
import EquineUndeleteButton from "../components/equine-undelete-button";

export default function Equines() {
    const navigate = useNavigate();
    const data = /** @type {import("../router").EquinesLoaderData} */ (useLoaderData());

    const {collection, other_data: otherData} = data;
    const [equines, setEquines] = useState(collection);
    const [pastEquines, setPastEquines] = useState(otherData.deleted_horses);
    const {available_roles: allRelationships} = otherData;

    /**
     * 
     * @param {number} equineId 
     */
    function navigateToProfile(equineId) {
        navigate(path(paths.equines.profile, {equineId: equineId}));
    }

    /** @type {import("../components/equine-edit-button").EquineFormModalUpdateEquineCallback} */
    function setUpdatedEquine(equine) {
        setEquines((oldVal) => {
            const index = oldVal.findIndex((e) => e.id === equine.id);
            if (index !== -1) {
                const newVal = [...oldVal];
                newVal[index] = equine;
                return newVal;
            } else {
                return oldVal;
            }
        });
    }

    /**
     * 
     * @param {number} equineId 
     * @param {React.Dispatch<React.SetStateAction<import("../api").FemEquineRead[]>>} setStateFunction 
     */
    function removeEquineFromState(equineId, setStateFunction) {
        setStateFunction((oldVal) => {
            const index = oldVal.findIndex((e) => e.id === equineId);
            if (index !== -1) {
                const newVal = oldVal.slice(0, index).concat(oldVal.slice(index+1));
                return newVal;
            } else {
                return oldVal;
            }
        });
    }

    /**
     * 
     * @param {import("../api").FemEquineRead} equine
     * @param {React.Dispatch<React.SetStateAction<import("../api").FemEquineRead[]>>} setStateFunction 
     */
    function addEquineToState(equine, setStateFunction) {
        setStateFunction((oldVal) => {
            return [equine, ...oldVal];
        })
    }

    /**
     * 
     * @param {import("../api").FemEquineRead} equine
     * @returns {void}
     */
    function equineDeleted(equine) {
        removeEquineFromState(equine.id, setEquines);
        addEquineToState(equine, setPastEquines);
    }

    /**
     * 
     * @param {import("../api").FemEquineRead} equine
     * @returns {void}
     */
    function equineUndeleted(equine) {
        removeEquineFromState(equine.id, setPastEquines);
        addEquineToState(equine, setEquines);
    }

    /**
     * 
     * @param {import("../api").FemEquineRead} equine 
     */
    function equineCreated(equine) {
        setEquines((oldVal) => {
            const newVal = [...oldVal];
            newVal.push(equine);
            return newVal;
        });
    }

    /**
     * @typedef PastHorsesTableProps
     * @prop {Array<import("../api").FemEquineRead>} pastEquines
     * @prop {(equineId: number) => void} navigateToProfile
     * @prop {(equine: import("../api").FemEquineRead) => void} handleEquineUndeletion
     */

    /**
     * 
     * @param {PastHorsesTableProps} param0 
     */
    function PastHorsesTable({pastEquines, navigateToProfile, handleEquineUndeletion}) {
        if (pastEquines.length === 0) {
            return <></>;
        } else {
            return (
                <table className="table is-striped is-fullwidth">
                    <thead>
                        <th className="pt-4">Name</th>
                        <th className="is-narrow has-text-right"></th>
                    </thead>
                    <tbody>
                        {pastEquines.map((/** @type {import("../api").FemEquineRead} */ equine) => (
                            <tr>
                                <td onClick={() => navigateToProfile(equine.id)}>
                                    {horseNameToDisplay(equine)}
                                </td>
                                <td className="is-narrow has-text-right">
                                    <div className="field has-addons">
                                        <div className="control">
                                            <button
                                                className="button"
                                                onClick={() => navigateToProfile(equine.id)}
                                            >View</button>
                                        </div>
                                        <div className="control">
                                            <EquineUndeleteButton
                                                equineId={equine.id}
                                                undeletedCallback={() => handleEquineUndeletion(equine)}/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }
    }

    return (
        <>
            <h1>Current Horses</h1>

            <table className="table is-striped is-fullwidth">
                <thead>
                    <th className="pt-4">Name</th>
                    {/* <th className="pt-4 is-hidden-touch">Other names</th> */}
                    <th className="pt-4 is-hidden-touch">Age</th>
                    <th className="is-narrow has-text-right">
                        <div className="field">
                            <div className="control">
                                <EquineCreateButton
                                    allRelationships={allRelationships}
                                    createdEquineCallback={equineCreated}
                                    additionalButtonStyles="is-fullwidth is-size-7-mobile"
                                />
                            </div>
                        </div>
                    </th>
                </thead>
                <tbody>
                    {equines.map((equine) => (
                        <tr>
                            <td onClick={() => navigateToProfile(equine.id)}>
                                {horseNameToDisplay(equine)}
                            </td>
                            <td className="is-hidden-touch" onClick={() => navigateToProfile(equine.id)} >
                                {new Date().getFullYear() - Number(equine.birth_year)}
                            </td>
                            <td className="is-narrow has-text-right">
                                <div className="field has-addons">
                                    <div className="control">
                                        <button className="button is-small" onClick={() => navigateToProfile(equine.id)}>View</button>
                                    </div>
                                    <div className="control">
                                        <EquineEditButton
                                            additionalButtonStyles="is-small"
                                            equine={equine}
                                            setEquine={setUpdatedEquine}
                                            allRelationships={allRelationships}
                                        />
                                    </div>
                                    <div className="control">
                                        <EquineDeleteButton
                                            additionalButtonStyles="is-small"
                                            equineId={equine.id}
                                            deletedCallback={() => equineDeleted(equine)}/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {pastEquines.length > 0
            ? <>
                <h1>Past Horses</h1>

                <PastHorsesTable
                    pastEquines={pastEquines}
                    navigateToProfile={navigateToProfile}
                    handleEquineUndeletion={equineUndeleted}
                />
            </>
            : <></>
            }
        </>
    );
}