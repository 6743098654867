// @ts-check

import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { horseNameToDisplay } from "../helpers/horse";
import { BulmaLevel, BulmaSelect, Pagination, useApi } from "react-base";
import PonyLogCreateButton from "../components/ponylog-create-button";
import PonyLogEditButton from "../components/ponylog-edit-button";
import { ponylogEntriesGetAllRequest, ponylogImageDownloadUrl } from "../api";
import toast from "react-hot-toast";
import PonylogDeleteButton from "../components/ponylog-delete-button";
import appConfig from "../app-config";
import PonylogShareButton from "../components/ponylog-share-button";
import { dateToString } from "../helpers/others";
import TextContent from "../components/general-purpose/text-content";

export const ITEMS_PER_PAGE = 10;

/**
 * 
 * @returns {React.JSX.Element}
 */
export default function PonyLog() {
    const dataFromApi = /** @type {import("../api").FemPonylogSuccessfulCollectionGetAllResponse} */ (useLoaderData());
    const [allHorses, setAllHorses] = useState(dataFromApi.other_data.horses);
    const [allPastRelatedHorses, setAllPastRelatedHorses] = useState(dataFromApi.other_data.past_related_horses);
    const [allAvailableRoles, setAllAvailableRoles] = useState(dataFromApi.other_data.available_roles);
    const [totalCount, setTotalCount] = useState(dataFromApi.other_data.count);
    /** @type {Array<[string, string | React.JSX.Element]>} */
    const horseSelectData = allHorses.map((horse) => [horse.id.toString(), horseNameToDisplay(horse)]);
    horseSelectData.splice(0, 0, ["-1", "All horses"]);
    horseSelectData.push(...(allPastRelatedHorses.map((horse) => 
        /** @type {[string, string]} */ ([horse.id.toString(), horseNameToDisplay(horse)]))));
    const [entries, setEntries] = useState(dataFromApi.collection);
    const [selectedHorse, setSelectedHorse] = useState(-1);
    const [listFetchAbortController, setListFetchAbortController] =
        useState(/** @type {AbortController | undefined} */ (undefined));
    const fetchFromApi = useApi();
    const [currentPage, setCurrentPage] = useState(1);

    /**
     * 
     * @param {number} equine_id 
     * @param {number} page
     */
    async function reloadData(
        equine_id,
        page
        // page, itemsPerPage, searchPhrase = ""
    ) {
        if (listFetchAbortController) {
            listFetchAbortController.abort();
        }
        try {
            const controller = new AbortController();
            const signal = controller.signal;
            setListFetchAbortController(controller);

            /** @type {import("../api").FemPonylogEntryGetAllParams} */
            const queryParams = {
                offset: (page-1) * ITEMS_PER_PAGE,
                count: ITEMS_PER_PAGE,
            };
            if (equine_id !== -1) {
                queryParams.equine_id = equine_id;
            }
            const result = await fetchFromApi(ponylogEntriesGetAllRequest(queryParams), {signal});
            setListFetchAbortController(undefined);
            if (result.isOk) {
                /** @type {import("../api").FemPonylogSuccessfulCollectionGetAllResponse} */
                const data = result.data;
                setEntries(data.collection);
                setAllHorses(data.other_data.horses);
                setAllPastRelatedHorses(data.other_data.past_related_horses);
                setAllAvailableRoles(data.other_data.available_roles);
                setTotalCount(data.other_data.count);
                setCurrentPage(page);
                window.scrollTo(0, 0);
            } else {
                toast.error("Error fetching data from server.");
            }
        } catch (/** @type {any} */ err) {
            if (err.message) {
                console.error(err.message);
            }
            toast.error("Error fetching data  from server.");
        }
    }

    /** @type {React.ChangeEventHandler<HTMLSelectElement>} */
    function selectedHorseChangeHandler(event) {
        const newSelectedHorseId = Number(event.target.value);
        setSelectedHorse(newSelectedHorseId);
        reloadData(newSelectedHorseId, 1);
    }

    /** @type {import("../components/ponylog-create-button").PonyLogCreateButtonCreateHandler} */
    function createHandler(createdEntity) {
        if (selectedHorse === -1 || createdEntity.equine.id === selectedHorse) {
            setEntries((currentEntries) => {
                const createdEntityDate = new Date(createdEntity.date);
                const position = currentEntries.findIndex((entry) => new Date(entry.date) <= createdEntityDate);
                const newValue = [...currentEntries]
                newValue.splice((position === -1 ? newValue.length : position), 0, createdEntity);
                return newValue;
            });
        }
    }

    /** @type {import("../components/ponylog-edit-button").PonyLogEditButtonUpdateHandler} */
    function updateHandler(updatedEntity) {
        if (selectedHorse === -1 || updatedEntity.equine.id === selectedHorse) {
            setEntries((currentEntries) => {
                const position = currentEntries.findIndex((entry) => entry.id === updatedEntity.id);
                if (position !== -1) {
                    const newValue = [...currentEntries];
                    newValue[position] = updatedEntity;
                    return newValue;
                } else {
                    return currentEntries;
                }
            });
        } else {
            deleteHandler(updatedEntity.id);
        }
    }

    /**
     * 
     * @param {number} entryId 
     * @returns {void}
     */
    function deleteHandler(entryId) {
        setEntries((currentEntries) => {
            const position = currentEntries.findIndex((entry) => entry.id === entryId);
            if (position !== -1) {
                const newValue = currentEntries.filter((entry) => entry.id !== entryId);
                return newValue;
            } else {
                return currentEntries;
            }
        });
    }

    /**
     * 
     * @param {{images: Array<import("../api").FemImageId>}} param0 
     * @returns {React.JSX.Element}
     */
    function ImageContent({images}) {
        const columnWidthClassName = (
            images.length === 2
            ? "is-half-desktop is-full-tablet"
            : images.length > 2
                ? "is-one-third-desktop is-half-tablet"
                : ""
        );
        const retval = images.map((image) => (
            <div className={`column ${columnWidthClassName}`}>
                <div className="columns mt-1 is-vcentered is-fullwidth" style={{"height": "100%"}}>
                    <div className="column has-text-centered">
                        <figure className="image is-inline-block">
                            <img className="image" src={ponylogImageDownloadUrl(image)} />
                        </figure>
                    </div>
                </div>
            </div>
        ));
        return (
            <div className="columns is-multiline is-centered">
                {retval}
            </div>
        );
    }

    /**
     * 
     * @param {{entry: import("../api").FemPonylogEntryRead}} param0 
     */
    function Entry({entry}) {
        /**
         * 
         * @param {Array<import("../api").FemPersonMention>} newSharedWith 
         * @returns {void}
         */
        function sharingChangeHandler(newSharedWith) {
            entry.shared_with = newSharedWith;
            setEntries((oldVal) => [...oldVal]); // this bumps the state - value updated in the previous line
        }

        return (
            <>
                <BulmaLevel
                    className="is-mobile"
                    left={[
                        <p><strong>Date: </strong>{dateToString(entry.date)}
                        <br />
                        <strong>By: </strong>{
                            entry.created_by ? entry.created_by.display_name : appConfig.appVisibleName
                        }
                        </p>
                    ]}
                    right={[
                        <div className="field has-addons">
                            <PonylogShareButton
                                additionalButtonStyles="is-small"
                                entry={entry}
                                disabled={!entry.is_created_by_current_user}
                                allAvailableRoles={allAvailableRoles}
                                onSave={sharingChangeHandler}
                            />
                            <PonyLogEditButton
                                entry={entry}
                                additionalButtonStyles="is-small"
                                allHorses={allHorses}
                                allDeletedHorses={allPastRelatedHorses}
                                onUpdate={updateHandler}
                                disabled={!entry.is_created_by_current_user}
                            />
                            <PonylogDeleteButton
                                additionalButtonStyles="is-small"
                                ponylogEntryId={entry.id}
                                onDelete={deleteHandler}
                                disabled={!entry.is_created_by_current_user}
                            />
                        </div>
                    ]}
                />
                <TextContent content={entry.content} />
                <ImageContent images={entry.images} />
            </>
        );
    }

    return (
        <>
            <BulmaLevel 
                className="is-mobile"
                left={[
                    <BulmaSelect value={selectedHorse.toString()}
                        onChange={selectedHorseChangeHandler}
                        values={horseSelectData} />
                ]}
                right={[
                    <PonyLogCreateButton
                        allHorses={allHorses}
                        allDeletedHorses={allPastRelatedHorses}
                        initialSelectedHorse={selectedHorse === -1 ? undefined : selectedHorse}
                        label="Write New Entry"
                        onCreate={createHandler} />
                ]}
            />

            {entries.length > 0
                ?  entries.map((/** @type {import("../api").FemPonylogEntryRead} */ entry) =>
                    <div key={entry.id} className="box">
                        <Entry entry={entry}/>
                    </div>
                )
                : <div className="notification has-text-centered">
                    No ponylog entries found.
                </div> 
            }

            <Pagination
                currentPage={currentPage}
                itemsPerPage={ITEMS_PER_PAGE}
                count={totalCount}
                onPageChange={(_fromPage, toPage, _itemsPerPage) => reloadData(selectedHorse, toPage)}
            />
        </>
    );
}