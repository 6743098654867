// @ts-check

import React, { useState } from "react";
import { checkValue, setAndCheck, textField } from "../helpers/others";
import { ActionButton, BulmaLevel, useErrors } from "react-base";
import toast from "react-hot-toast";

const NAME_MAX_LENGTH = 50;
const NAME_MIN_LENGTH = 2;
const ERROR_FIELD_NAME = "name";
const ERROR_NAME_TOO_LONG = "too-long";
const ERROR_NAME_LENGTH_MESSAGE_TOO_LONG = `Name must be at most ${NAME_MAX_LENGTH} characters.`;
const ERROR_NAME_TOO_SHORT = "too-short";
const ERROR_NAME_LENGTH_MESSAGE_TOO_SHORT = `Name must be at least ${NAME_MIN_LENGTH} characters.`;

const DESCRIPTION_MAX_LENGTH = 2000;
const ERROR_FIELD_DESCRIPTION = "description";
const ERROR_DESCRIPTION_TOO_LONG = "too-long";
const ERROR_DESCRIPTION_LENGTH_MESSAGE_TOO_LONG = `Description must be at most ${DESCRIPTION_MAX_LENGTH} characters.`;

const ERR_API = 'api';
const ERR_API_SUBMIT_FAILED = 'failure';

/**
 * @callback ManagedServiceProviderServiceFormSubmitHandler 
 * @param {import("../api").FemServiceRead | null | undefined} original
 * @param {import("../api").FemServiceWrite} edited
 * @returns {Promise<import("../api").FemApiErrorResponse | null>}
 */

/**
 * @typedef {Object} ManagedServiceProviderServiceFormProps
 * @prop {import("../api").FemServiceRead} [entity]
 * @prop {ManagedServiceProviderServiceFormSubmitHandler} handleSubmit
 * @prop {string} [submitButtonLabel = "Save"]
 */

/**
 *
 * @param {ManagedServiceProviderServiceFormProps} param0
 * @returns {React.JSX.Element}
 */
export default function ManagedServiceProviderServiceForm({
    entity,
    handleSubmit,
    submitButtonLabel = "Save",
}) {
    const [name, setName] = useState(entity?.name ?? '');
    const [description, setDescription] = useState(entity?.description ?? '');

    const [isValid, setIsValid] = useState(true);
    const {setError, resetError, renderErrors} = useErrors();

   /**
     * @typedef {Object} CheckValidityProps
     * @prop {string} [currentName = name]
     * @prop {string} [currentDescription = description]
     */
   /**
     * 
     * @param {CheckValidityProps} [param0 = {}]
     * @returns {boolean}
     */
    function checkValidity({
        currentName = name,
        currentDescription = description,
    } = {}) {
        const checks = [
            // name
            checkValue([
                {
                    errorCondition: currentName !== undefined && currentName.length > NAME_MAX_LENGTH,
                    errFieldName: ERROR_FIELD_NAME,
                    errConditionName: ERROR_NAME_TOO_LONG,
                    errMessage: ERROR_NAME_LENGTH_MESSAGE_TOO_LONG
                },
                {
                    errorCondition: currentName === undefined || currentName.length < NAME_MIN_LENGTH,
                    errFieldName: ERROR_FIELD_NAME,
                    errConditionName: ERROR_NAME_TOO_SHORT,
                    errMessage: ERROR_NAME_LENGTH_MESSAGE_TOO_SHORT
                }
            ], setError, resetError),

            // address
            checkValue({
                errorCondition: currentDescription !== undefined && currentDescription.length > DESCRIPTION_MAX_LENGTH,
                errFieldName: ERROR_FIELD_DESCRIPTION,
                errConditionName: ERROR_DESCRIPTION_TOO_LONG,
                errMessage: ERROR_DESCRIPTION_LENGTH_MESSAGE_TOO_LONG
            }, setError, resetError),
        ];
        const validity = (checks.find((v) => v === false) === undefined);
        setIsValid(validity);
        return validity;
    }

    function anyChange() {
        resetError(ERR_API, ERR_API_SUBMIT_FAILED);
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleFormButtonClick(cb) {
        if (checkValidity()) {
            /** @type {import("../api").FemServiceWrite} */
            const newEntity = {
                name: name.trim(),
                description: description.trim(),
            }

            const response = await handleSubmit(entity, newEntity);
            cb();
            if (response !== null) {
                const errorResponse = /** @type {import("../api").FemApiErrorResponse} */ (response);
                toast.error("Error saving.")
                setError(ERR_API, ERR_API_SUBMIT_FAILED, errorResponse.errors);
                return;
            }
        } else {
            cb();
        }
    }

    return (
        <form className="has-text-left">
            {textField({
                label: "Name",
                placeholder: "Service name",
                value: name,
                onChange: (event) =>
                    setAndCheck(event.target.value, setName, "currentName", checkValidity, anyChange),
            })}

            {textField({
                label: "Description",
                placeholder: "Description of the service",
                value: description,
                onChange: (event) =>
                    setAndCheck(event.target.value, setDescription, "currentDescription", checkValidity, anyChange),
            })}

            {renderErrors()}

            <BulmaLevel
                right={[
                    <ActionButton disabled={!isValid} onClick={handleFormButtonClick}>{submitButtonLabel}</ActionButton>
                ]}
            />

        </form>
    );
}