// @ts-check

import { baseUrl, buildUrl, httpQueryType, postRequest, request } from "react-base";

/** @typedef {import("../../api/src/fem-types").FemEquineRead} FemEquineRead */
/** @typedef {import("../../api/src/fem-types").FemEquineMentionWithUserRelationshipsRead} FemEquineMentionWithUserRelationshipsRead */
/** @typedef {import("../../api/src/fem-types").FemEquineWrite} FemEquineWrite */
/** @typedef {import("../../api/src/fem-types").FemEquineMentionRead} FemEquineMentionRead */
/** @typedef {import("../../api/src/fem-types").FemEquinesCollectionGetAllResponse} FemEquinesCollectionGetAllResponse */
/** @typedef {import("../../api/src/fem-types").FemEquinesCollectionOtherData} FemEquinesCollectionOtherData */

/** @typedef {import("../../api/src/fem-types").FemPonylogEntryRead} FemPonylogEntryRead */
/** @typedef {import("../../api/src/fem-types").FemPonylogEntryWrite} FemPonylogEntryWrite */
/** @typedef {import("../../api/src/fem-types").FemImageId} FemImageId */
/** @typedef {import("../../api/src/fem-types").FemPonylogEntrySharingStatus} FemPonylogEntrySharingStatus */
/** @typedef {import("../../api/src/fem-types").FemPonylogEntryGetAllParams} FemPonylogEntryGetAllParams */
/** @typedef {import("../../api/src/fem-types").FemPonylogSuccessfulCollectionGetAllResponse} FemPonylogSuccessfulCollectionGetAllResponse */
/** @typedef {import("../../api/src/fem-types").FemPonylogEntrySharedWithWrite} FemPonylogEntrySharedWithWrite */

/** @typedef {import("../../api/src/fem-types").FemProfileRead} FemProfileRead */
/** @typedef {import("../../api/src/fem-types").FemProfileWrite} FemProfileWrite */
/** @typedef {import("../../api/src/fem-types").FemProfileSuccessfulUpdateResponse} FemProfileSuccessfulUpdateResponse */

/** @typedef {import("../../api/src/fem-types").FemPersonMention} FemPersonMention */

/** @typedef {import("../../api/src/fem-types").FemPersonEquineRelationshipRead} FemPersonEquineRelationshipRead */
/** @typedef {import("../../api/src/fem-types").FemPersonEquineRelationshipForRoleAssignmentRead} FemPersonEquineRelationshipForRoleAssignmentRead */
/** @typedef {import("../../api/src/fem-types").FemPersonEquineRelationshipWriteUpsert} FemPersonEquineRelationshipWriteUpsert */

/** @typedef {import("../../api/src/fem-types").FemRole} FemRole */
/** @typedef {import("../../api/src/fem-types").FemRoleToDisplayNameMapping} FemRoleToDisplayNameMapping */
/** @typedef {import("../../api/src/fem-types").FemRelationshipPersonSearchResultItem} FemRelationshipPersonSearchResultItem */

/** @typedef {import("../../api/src/fem-types").FemUsersSuccessfulSearchResponse} FemUsersSuccessfulSearchResponse */

/** @typedef {import("../../api/src/fem-types").FemApiErrorResponse} FemApiErrorResponse */
/** 
 * @template FEM_TYPE
 * @typedef {import("../../api/src/fem-types").FemApiSuccessfulEntityResponseWithoutOtherData<FEM_TYPE>} FemApiSuccessfulEntityResponseWithoutOtherData
 */
/**
 * @template FEM_TYPE, OTHER_DATA_TYPE
 * @typedef {import("../../api/src/fem-types").FemApiSuccessfulCollectionResponseWithOtherData<FEM_TYPE, OTHER_DATA_TYPE>} FemApiSuccessfulCollectionResponseWithOtherData
 */
/**
 * @template FEM_TYPE
 * @typedef {import("../../api/src/fem-types").FemApiSuccessfulCollectionResponseWithoutOtherData<FEM_TYPE>} FemApiSuccessfulCollectionResponseWithoutOtherData
 */
/** @typedef {import("../../api/src/fem-types").FemEquineProfileGetResponse} FemEquineProfileGetResponse */
/** @typedef {import("../../api/src/fem-types").FemPersonEquineRelationshipUpdateResponse} FemPersonEquineRelationshipUpdateResponse */

// ---------------------------------------------------------------------------------------------------------------------
// Service provider

/** @typedef {import("../../api/src/fem-types").FemServiceProviderRead} FemServiceProviderRead */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderWrite} FemServiceProviderWrite */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderSuccessfulGetAllResponse} FemServiceProviderSuccessfulGetAllResponse */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderGetAllResponseOtherData} FemServiceProviderGetAllResponseOtherData */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderSuccessfulGetResponse} FemServiceProviderSuccessfulGetResponse */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderGetResponseOtherData} FemServiceProviderGetResponseOtherData */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderSuccessfulCreateResponse} FemServiceProviderSuccessfulCreateResponse */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderSuccessfulSearchResponse} FemServiceProviderSuccessfulSearchResponse */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderMentionRead} FemServiceProviderMentionRead */

// Service provider
// ---------------------------------------------------------------------------------------------------------------------
// Service provider (connection)

/** @typedef {import("../../api/src/fem-types").FemServiceProviderConnectionRead} FemServiceProviderConnectionRead */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderConnectionWriteCreate} FemServiceProviderConnectionWriteCreate */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderConnectionWriteUpdate} FemServiceProviderConnectionWriteUpdate */

/** @typedef {import("../../api/src/fem-types").FemServiceProviderConnectionsSuccessfulGetAllResponse} FemServiceProviderConnectionsSuccessfulGetAllResponse */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderConnectionsSuccessfulGetResponse} FemServiceProviderConnectionsSuccessfulGetResponse */

/** @typedef {import("../../api/src/fem-types").FemServiceProviderEquineSuccessfulConnectionCreateResponse} FemServiceProviderEquineSuccessfulConnectionCreateResponse */

/** @typedef {import("../../api/src/fem-types").FemServiceProviderEquineSuccessfulConnectionSearchResponse} FemServiceProviderEquineSuccessfulConnectionSearchResponse */

// Service provider (connection)
// ---------------------------------------------------------------------------------------------------------------------
// Service

/** @typedef {import("../../api/src/fem-types").FemServiceRead} FemServiceRead */
/** @typedef {import("../../api/src/fem-types").FemServiceWrite} FemServiceWrite */
/** @typedef {import("../../api/src/fem-types").FemServiceSuccessfulGetAllResponse} FemServiceSuccessfulGetAllResponse */
/** @typedef {import("../../api/src/fem-types").FemServiceGetAllResponseOtherData} FemServiceGetAllResponseOtherData */
/** @typedef {import("../../api/src/fem-types").FemServiceSuccessfulGetResponse} FemServiceSuccessfulGetResponse */
/** @typedef {import("../../api/src/fem-types").FemServiceGetResponseOtherData} FemServiceGetResponseOtherData */
/** @typedef {import("../../api/src/fem-types").FemServiceSuccessfulCreateResponse} FemServiceSuccessfulCreateResponse */

// Service
// ---------------------------------------------------------------------------------------------------------------------
// Service provider - equine relationship

/** @typedef {import("../../api/src/fem-types").FemServiceProviderEquineRelationshipRead} FemServiceProviderEquineRelationshipRead */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderEquineRelationshipRequestWrite} FemServiceProviderEquineRelationshipRequestWrite */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderEquineRelationshipResponseWrite} FemServiceProviderEquineRelationshipResponseWrite */
/** @typedef {import("../../api/src/fem-types").FemServiceProviderEquineRelationshipConnectionWriteCreate} FemServiceProviderEquineRelationshipConnectionWriteCreate */

/** @typedef {import("../../api/src/fem-types").FemServiceProviderEquineSuccessfulGetResponse} FemServiceProviderEquineSuccessfulGetResponse */

// Service provider - equine relationship
// ---------------------------------------------------------------------------------------------------------------------
// Service - equine relationship

/** @typedef {import("../../api/src/fem-types").FemServiceEquineRelationshipRead} FemServiceEquineRelationshipRead */
/** @typedef {import("../../api/src/fem-types").FemServiceEquineRelationshipWriteCreate} FemServiceEquineRelationshipWriteCreate */

/** @typedef {import("../../api/src/fem-types").FemServiceEquineRelationshipSuccessfulAcceptResponse} FemServiceEquineRelationshipSuccessfulAcceptResponse */

// Service - equine relationship
// ---------------------------------------------------------------------------------------------------------------------
// Person (Contact)

/** @typedef {import("../../api/src/fem-types").FemPersonRead} FemPersonRead */
/** @typedef {import("../../api/src/fem-types").FemPersonWriteUpdate} FemPersonWriteUpdate */
/** @typedef {import("../../api/src/fem-types").FemPersonWriteCreate} FemPersonWriteCreate */

/** @typedef {import("../../api/src/fem-types").FemConnectionsSuccessfulGetAllResponse} FemConnectionsSuccessfulGetAllResponse */
/** @typedef {import("../../api/src/fem-types").FemPersonsSuccessfulGetResponse} FemPersonsSuccessfulGetResponse */
/** @typedef {import("../../api/src/fem-types").FemPersonsSuccessfulUpdateResponse} FemPersonsSuccessfulUpdateResponse */

// Person (Contact)
// ---------------------------------------------------------------------------------------------------------------------
// User

/** @typedef {import("../../api/src/fem-types").FemUserMention} FemUserMention */
/** @typedef {import("../../api/src/fem-types").FemUserUserRelationshipsSuccessfulInvitationsGetAllResponse} FemUserUserRelationshipsSuccessfulInvitationsGetAllResponse */

// User
// ---------------------------------------------------------------------------------------------------------------------

const apiPath="/api";

// THIS MUST BE THE SAME AS ../../../api/src/paths.js
// Build complains for imports outside src/, therefore it's copied here for now, until we fix the dependency.
const paths = {
    equines: {
        path: `${apiPath}/equines`,
        undelete: `/:equineId/undelete`,
    },
    equine: {
        path: `${apiPath}/equines/:equineId`,
    },
    ponylogEntries: {
        path: `${apiPath}/ponylog-entries`,
        sharedWith: `/:ponylogEntryId/shared-with`
    },
    profile: `${apiPath}/profile`,
    persons: {
        path: `${apiPath}/persons`,
        searchWithEquineRelationship: "/search-with-equine-relationship",
        search: "/search",
        updateRelationship: "/:personId/update-relationship",
    },
    users: {
        path: `${apiPath}/users`,
        search: "/search",
    },
    ponylogImage: {
        upload: `/ponylog-image`,
        download: `/ponylog-image`,
    },
    serviceProviders: {
        path: `${apiPath}/service-providers`,
        undelete: "/:serviceProviderId/undelete",
        purge: "/:serviceProviderId/purge",
        search: "/search",
        services: {
            path: `${apiPath}/service-providers/:serviceProviderId/services`,
            undelete: "/:serviceId/undelete",
            purge: "/:serviceId/purge",
        },
    },
    serviceProviderConnections: {
        path: `${apiPath}/service-provider-connections`,
        search: "/search",
    },
    serviceProviderEquineRelationships: {
        path: `${apiPath}/service-provider-equine-relationships`,
        undelete: "/:id/undelete",
        approve: "/:id/approve",
        reject: "/:id/reject",
        request: "/request",
    },
    serviceEquineRelationships: {
        path: `${apiPath}/service-equine-relationships`,
        accept: "/:id/accept",
        reject: "/:id/reject",
    },
    connections: {
        path: `${apiPath}/connections`,
    },
    userUserRelationships: {
        path: `${apiPath}/user-user-relationships`,
        invite: `/invite/:inviteeUserId`,
        invitations: "/invitations",
        accept: `/accept/:inviterUserId`,
        ignore: `/ignore/:inviterUserId`,
    },
};

/**
 * Returns a get-all request for given collection url.
 * 
 * @param {string} collectionUrl 
 * @param {Object} [queryParams={}]
 * @returns {Request}
 */
function getAllRequest(collectionUrl, queryParams = {}) {
    return request({url: buildUrl(collectionUrl, queryParams)});
}

/**
 * Returns a get request for an element of a collection.
 * 
 * @param {string} collectionUrl 
 * @param {string | number} id 
 * @param {Object} [queryParams={}]
 * @returns {Request}
 */
function getRequest(collectionUrl, id, queryParams = {}) {
    return request({url: buildUrl(`${collectionUrl}/${id}`, queryParams)});
}

/**
 * Return a create requst.
 * 
 * @template T
 * @param {string} collectionUrl
 * @param {T} data 
 * @returns {Request}
 */
function createRequest(collectionUrl, data) {
    return request({url: collectionUrl, method: httpQueryType.post, body: data});
}

/**
 * Return an update request for an element of a collection.
 * 
 * @template T
 * @param {string} collectionUrl
 * @param {string | number} id
 * @param {T} data 
 * @returns 
 */
function updateRequest(collectionUrl, id, data) {
    return request({url: `${collectionUrl}/${id}`, method: httpQueryType.put, body: data});
}

/**
 * Return a delete request for an element of a collection.
 * 
 * @param {string} collectionUrl 
 * @param {string | number} id 
 * @returns 
 */
function deleteRequest(collectionUrl, id) {
    return request({url: `${collectionUrl}/${id}`, method: httpQueryType.delete});
}

// *********************************************************************************************************************
// *********************************************************************************************************************
// URL

const equinesUrl = `${baseUrl}${paths.equines.path}`;
/** @type {(equineId: number) => string} */
function equineUndeleteUrl(equineId) {
    return `${equinesUrl}${paths.equines.undelete}`.replace(":equineId", equineId.toString());
}

const ponylogEntriesUrl = `${baseUrl}${paths.ponylogEntries.path}`;

/** @type {(ponylogEntryId: number) => string} */
function ponylogEntryUpdateSharedWithUrl(ponylogEntryId) {
    return `${ponylogEntriesUrl}${paths.ponylogEntries.sharedWith}`
        .replace(":ponylogEntryId", ponylogEntryId.toString());
}

const profileUrl = `${baseUrl}${paths.profile}`;

const usersUrl = `${baseUrl}${paths.users.path}`;
const usersSearchUrl = `${usersUrl}${paths.users.search}`

const personsUrl = `${baseUrl}${paths.persons.path}`;
const personsSearchWithEquineRelationshipUrl = `${personsUrl}${paths.persons.searchWithEquineRelationship}`;
const personsSearchUrl = `${personsUrl}${paths.persons.search}`;

/** @type {(personId: number) => string} */
function personEquineUpdateRelationshipUrl(personId) {
    return `${personsUrl}${paths.persons.updateRelationship}`.replace(":personId", personId.toString());
}
/** @type {(fileName: string) => string} */
function ponylogImageUploadUrl(fileName) { return `${baseUrl}${paths.ponylogImage.upload}/${fileName}`; }

/** @type {(fileName: string) => string} */
export function ponylogImageDownloadUrl(fileName) { return `${baseUrl}${paths.ponylogImage.download}/${fileName}`; }

const serviceProvidersUrl = `${baseUrl}${paths.serviceProviders.path}`;
const serviceProvidersSearchUrl = `${serviceProvidersUrl}${paths.serviceProviders.search}`;
/** @type {(serviceProviderId: number) => string} */
function serviceProviderUndeleteUrl(serviceProviderId) {
    return `${serviceProvidersUrl}${paths.serviceProviders.undelete}`.replace(":serviceProviderId", serviceProviderId.toString());
}
/** @type {(serviceProviderId: number) => string} */
function serviceProviderPurgeUrl(serviceProviderId) {
    return `${serviceProvidersUrl}${paths.serviceProviders.purge}`.replace(":serviceProviderId", serviceProviderId.toString());
}

const serviceProviderConnectionsUrl = `${baseUrl}${paths.serviceProviderConnections.path}`;
const serviceProviderConnectionsSearchUrl = `${serviceProviderConnectionsUrl}${paths.serviceProviderConnections.search}`;

/** @type {(serviceProviderId: number) => string} */
function serviceProviderServicesUrl(serviceProviderId) {
    return `${baseUrl}${paths.serviceProviders.services.path}`.replace(":serviceProviderId", serviceProviderId.toString());
}

/** @type {(serviceProviderId: number, serviceId: number) => string} */
function serviceProviderServiceUndeleteUrl(serviceProviderId, serviceId) {
    return `${serviceProviderServicesUrl(serviceProviderId)}${paths.serviceProviders.services.undelete}`
        .replace(":serviceId", serviceId.toString());
}
/** @type {(serviceProviderId: number, serviceId: number) => string} */
function serviceProviderServicePurgeUrl(serviceProviderId, serviceId) {
    return `${serviceProviderServicesUrl(serviceProviderId)}${paths.serviceProviders.services.purge}`
        .replace(":serviceId", serviceId.toString());
}

const serviceProviderEquineRelationshipsUrl = `${baseUrl}${paths.serviceProviderEquineRelationships.path}`;
const serviceProviderEquineRelationshipsRequestUrl =
    `${serviceProviderEquineRelationshipsUrl}${paths.serviceProviderEquineRelationships.request}`;
/** @type {(serviceProviderEquineId: number) => string} */
function serviceProviderEquineRelationshipUndeleteUrl(serviceProviderEquineId) {
    return `${serviceProviderEquineRelationshipsUrl}${paths.serviceProviderEquineRelationships.undelete}`
        .replace(":id", serviceProviderEquineId.toString());
}
/** @type {(serviceProviderEquineId: number) => string} */
function serviceProviderEquineRelationshipApproveUrl(serviceProviderEquineId) {
    return `${serviceProviderEquineRelationshipsUrl}${paths.serviceProviderEquineRelationships.approve}`
        .replace(":id", serviceProviderEquineId.toString());
}
/** @type {(serviceProviderEquineId: number) => string} */
function serviceProviderEquineRelationshipRejectUrl(serviceProviderEquineId) {
    return `${serviceProviderEquineRelationshipsUrl}${paths.serviceProviderEquineRelationships.reject}`
        .replace(":id", serviceProviderEquineId.toString());
}

const serviceEquineRelationshipsUrl = `${baseUrl}${paths.serviceEquineRelationships.path}`;
/** @type {(serviceEquineRelationshipId: number) => string} */
function serviceEquineRelationshipAcceptUrl(serviceEquineRelationshipId) {
    return `${serviceEquineRelationshipsUrl}${paths.serviceEquineRelationships.accept}`
        .replace(":id", serviceEquineRelationshipId.toString());
}
/** @type {(serviceEquineRelationshipId: number) => string} */
function serviceEquineRelationshipRejectUrl(serviceEquineRelationshipId) {
    return `${serviceEquineRelationshipsUrl}${paths.serviceEquineRelationships.reject}`
        .replace(":id", serviceEquineRelationshipId.toString());
}

const contactsUrl = `${baseUrl}${paths.connections.path}`;
const userUserRelationshipsUrl = `${baseUrl}${paths.userUserRelationships.path}`;
const userUserRelationshipsInvitationsUrl = `${userUserRelationshipsUrl}${paths.userUserRelationships.invitations}`;

/** @type {(inviteeUserId: number) => string} */
function userUserRelationshipsInviteUrl(inviteeUserId) {
    return `${userUserRelationshipsUrl}${paths.userUserRelationships.invite}`
        .replace(":inviteeUserId", inviteeUserId.toString());
}

/** @type {(inviterUserId: number) => string} */
function userUserRelationshipsAcceptUrl(inviterUserId) {
    return `${userUserRelationshipsUrl}${paths.userUserRelationships.accept}`
        .replace(":inviterUserId", inviterUserId.toString());
}

/** @type {(inviterUserId: number) => string} */
function userUserRelationshipsIgnoreUrl(inviterUserId) {
    return `${userUserRelationshipsUrl}${paths.userUserRelationships.ignore}`
        .replace(":inviterUserId", inviterUserId.toString());
}

// *********************************************************************************************************************
// *********************************************************************************************************************
// Request builders

// *********************************************************************************************************************
// Equines (horses)
export function equinesGetAllRequest() { return getAllRequest(equinesUrl) }
/** @type {(data: FemEquineWrite) => Request} */
export function equineCreateRequest(data) { return createRequest(equinesUrl, data); }
/** @type {(equineId: number, data: FemEquineWrite) => Request} */
export function equineUpdateRequest(equineId, data) { return updateRequest(equinesUrl, equineId, data); }
/** @type {(equineId: number) => Request} */
export function equineDeleteRequest(equineId) { return deleteRequest(equinesUrl, equineId); }
/** @type {(equineId: number) => Request} */
export function equineUndeleteRequest(equineId) { return postRequest(equineUndeleteUrl(equineId)); }
/** @type {(equineId: number) => Request} */
export function equineGetRequest(equineId) { return getRequest(equinesUrl, equineId); }

// *********************************************************************************************************************
// PonyLog Entries
export function ponylogEntriesGetAllRequest(queryParams = {}) { return getAllRequest(ponylogEntriesUrl, queryParams) }
/** @type {(data: FemPonylogEntryWrite) => Request} */
export function ponylogEntryCreateRequest(data) { return createRequest(ponylogEntriesUrl, data); }
/** @type {(ponylogEntryId: number, data: FemPonylogEntryWrite) => Request} */
export function ponylogEntryUpdateRequest(ponylogEntryId, data) { return updateRequest(ponylogEntriesUrl, ponylogEntryId, data); }
/** @type {(ponylogEntryId: number) => Request} */
export function ponylogEntryDeleteRequest(ponylogEntryId) { return deleteRequest(ponylogEntriesUrl, ponylogEntryId); }

/** @type {(ponylogEntryId: number, data: FemPonylogEntrySharedWithWrite) => Request} */
export function ponylogEntryUpdateSharedWithRequest(ponylogEntryId, data) {
    return request({
        url: ponylogEntryUpdateSharedWithUrl(ponylogEntryId),
        method: httpQueryType.put,
        body: data,
    });
}

// *********************************************************************************************************************
// Profile
export function profileGetRequest() { return request({url: profileUrl}); }

/** @type {(data: FemProfileWrite) => Request} */
export function profileUpdateRequest(data) {
    return request({url: profileUrl, method: httpQueryType.post, body: data});
}

// *********************************************************************************************************************
// PonylogImage
/** @type {(fileName: string, file: File) => Request} */
export function ponylogImageUploadRequest(fileName, file) {
    const url = ponylogImageUploadUrl(fileName);
    const requestParams = {
        method: 'PUT',
        body: file,
    };
    return new Request(url, requestParams);
}

// *********************************************************************************************************************
// *********************************************************************************************************************
// ServiceProvider

/** @type {() => Request} */
export function serviceProviderManagedGetAllRequest() {
    return request({url: buildUrl(serviceProvidersUrl, {managedOnly: true})});
}

/** @type {(serviceProviderId: number) => Request} */
export function serviceProviderGetRequest(serviceProviderId) {
    return getRequest(serviceProvidersUrl, serviceProviderId);
}

/** @type {(data: FemServiceProviderWrite) => Request} */
export function serviceProviderCreateRequest(data) { return postRequest(serviceProvidersUrl, data); }

/** @type {(serviceProviderId: number, data: FemServiceProviderWrite) => Request} */
export function serviceProviderUpdateRequest(serviceProviderId, data) {
    return updateRequest(serviceProvidersUrl, serviceProviderId, data);
}

/** @type {(serviceProviderId: number) => Request} */
export function serviceProviderDeleteRequest(serviceProviderId) {
    return deleteRequest(serviceProvidersUrl, serviceProviderId);
}

/** @type {(serviceProviderId: number) => Request} */
export function serviceProviderUndeleteRequest(serviceProviderId) {
    return postRequest(serviceProviderUndeleteUrl(serviceProviderId));
}

/** @type {(serviceProviderId: number) => Request} */
export function serviceProviderPurgeRequest(serviceProviderId) {
    return postRequest(serviceProviderPurgeUrl(serviceProviderId));
}

/** @type {(param0: {nameContains: string}) => Request} */
export function serviceProviderSearchRequest(searchQuery) {
    return getAllRequest(buildUrl(serviceProvidersSearchUrl, searchQuery));
}

// ServiceProvider
// *********************************************************************************************************************
// *********************************************************************************************************************

// *********************************************************************************************************************
// *********************************************************************************************************************
// ServiceProviderConnection

/** @type {() => Request} */
export function serviceProviderConnectionsGetAllRequest() { return getAllRequest(serviceProviderConnectionsUrl); }


/** @type {(serviceProviderId: number) => Request} */
export function serviceProviderConnectionGetRequest(serviceProviderId) {
    return getRequest(serviceProviderConnectionsUrl, serviceProviderId);
}

/** @type {(data: FemServiceProviderConnectionWriteCreate) => Request} */
export function serviceProviderConnectionCreateRequest(data) { return postRequest(serviceProviderConnectionsUrl, data); }

/** @type {(serviceProviderId: number, data: FemServiceProviderConnectionWriteUpdate) => Request} */
export function serviceProviderConnectionUpdateRequest(serviceProviderId, data) {
    return updateRequest(serviceProviderConnectionsUrl, serviceProviderId, data);
}

/** @type {(serviceProviderId: number) => Request} */
export function serviceProviderConnectionDeleteRequest(serviceProviderId) {
    return deleteRequest(serviceProviderConnectionsUrl, serviceProviderId);
}

/** @type {(param0: {nameContains: string}) => Request} */
export function serviceProviderConnectionSearchRequest(searchQuery) {
    return getAllRequest(buildUrl(serviceProviderConnectionsSearchUrl, searchQuery));
}

// ServiceProviderConnection
// *********************************************************************************************************************
// *********************************************************************************************************************

// *********************************************************************************************************************
// *********************************************************************************************************************
// ServiceProviderService

/** @type {(serviceProviderId: number, serviceId: number) => Request} */
export function serviceProviderServiceGetRequest(serviceProviderId, serviceId) {
    return getRequest(serviceProviderServicesUrl(serviceProviderId), serviceId);
}

/** @type {(serviceProviderId: number, data: FemServiceWrite) => Request} */
export function serviceProviderServiceCreateRequest(serviceProviderId, data) {
    return postRequest(serviceProviderServicesUrl(serviceProviderId), data);
}

/** @type {(serviceProviderId: number, serviceId: number, data: FemServiceWrite) => Request} */
export function serviceProviderServiceUpdateRequest(serviceProviderId, serviceId, data) {
    return updateRequest(serviceProviderServicesUrl(serviceProviderId), serviceId, data);
}

/** @type {(serviceProviderId: number, serviceId: number) => Request} */
export function serviceProviderServiceDeleteRequest(serviceProviderId, serviceId) {
    return deleteRequest(serviceProviderServicesUrl(serviceProviderId), serviceId);
}

/** @type {(serviceProviderId: number, serviceId: number) => Request} */
export function serviceProviderServiceUndeleteRequest(serviceProviderId, serviceId) {
    return postRequest(serviceProviderServiceUndeleteUrl(serviceProviderId, serviceId));
}

/** @type {(serviceProviderId: number, serviceId: number) => Request} */
export function serviceProviderServicePurgeRequest(serviceProviderId, serviceId) {
    return postRequest(serviceProviderServicePurgeUrl(serviceProviderId, serviceId));
}

// ServiceProviderService
// *********************************************************************************************************************
// *********************************************************************************************************************

// *********************************************************************************************************************
// *********************************************************************************************************************
// ServiceProviderEquineRelationship

/** @type {(id: number) => Request} */
export function serviceProviderEquineRelationshipGetRequest(id) {
    return getRequest(serviceProviderEquineRelationshipsUrl, id);
}

/** @type {(data: FemServiceProviderEquineRelationshipRequestWrite) => Request} */
export function serviceProviderEquineRelationshipRequestRequest(data) {
    return postRequest(serviceProviderEquineRelationshipsRequestUrl, data);
}

// /** @type {(serviceProviderEquineRelationshipId: number, data: FemServiceProviderEquineRelationshipWriteUpdate) => Request} */
// export function serviceProviderEquineRelationshipUpdateRequest(serviceProviderEquineRelationshipId, data) {
//     return updateRequest(serviceProviderEquineRelationshipsUrl, serviceProviderEquineRelationshipId, data);
// }

/** @type {(serviceProviderEquineRelationshipId: number) => Request} */
export function serviceProviderEquineRelationshipDeleteRequest(serviceProviderEquineRelationshipId) {
    return deleteRequest(serviceProviderEquineRelationshipsUrl, serviceProviderEquineRelationshipId);
}

/** @type {(serviceProviderEquineRelationshipId: number) => Request} */
export function serviceProviderEquineRelationshipUndeleteRequest(serviceProviderEquineRelationshipId) {
    return postRequest(serviceProviderEquineRelationshipUndeleteUrl(serviceProviderEquineRelationshipId));
}

/** @type {(serviceProviderEquineRelationshipId: number, data: FemServiceProviderEquineRelationshipResponseWrite) => Request} */
export function serviceProviderEquineRelationshipApproveRequest(serviceProviderEquineRelationshipId, data) {
    return postRequest(serviceProviderEquineRelationshipApproveUrl(serviceProviderEquineRelationshipId), data);
}

/** @type {(serviceProviderEquineRelationshipId: number, data: FemServiceProviderEquineRelationshipResponseWrite) => Request} */
export function serviceProviderEquineRelationshipRejectRequest(serviceProviderEquineRelationshipId, data) {
    return postRequest(serviceProviderEquineRelationshipRejectUrl(serviceProviderEquineRelationshipId), data);
}

/** @type {(data: FemServiceProviderEquineRelationshipConnectionWriteCreate) => Request} */
export function serviceProviderEquineRelationshipCreateRequest(data) {
    return postRequest(serviceProviderEquineRelationshipsUrl, data)
}

// ServiceProviderEquineRelationship
// *********************************************************************************************************************
// *********************************************************************************************************************


// *********************************************************************************************************************
// *********************************************************************************************************************
// ServiceEquineRelationship defs

/** @type {(data: FemServiceEquineRelationshipWriteCreate) => Request} */
export function serviceEquineRelationshipCreateRequest(data) {
    return createRequest(serviceEquineRelationshipsUrl, data);
}

/** @type {(id: number) => Request} */
export function serviceEquineRelationshipDeleteRequest(id) {
    return deleteRequest(serviceEquineRelationshipsUrl, id);
}

/** @type {(id: number) => Request} */
export function serviceEquineRelationshipAcceptRequest(id) {
    return postRequest(serviceEquineRelationshipAcceptUrl(id));
}

/** @type {(id: number) => Request} */
export function serviceEquineRelationshipRejectRequest(id) {
    return postRequest(serviceEquineRelationshipRejectUrl(id));
}

// ServiceEquineRelationship defs
// *********************************************************************************************************************
// *********************************************************************************************************************


// *********************************************************************************************************************
// *********************************************************************************************************************
// Person defs

/** @type {(queryParams: {searchText: string, equineId: number}) => Request} */
export function personsSearchWithEquineRelationshipRequest(queryParams) {
    return request({
        url: buildUrl(personsSearchWithEquineRelationshipUrl, queryParams),
        method: httpQueryType.get,
    });
}

/** @type {(queryParams: {searchText: string}) => Request} */
export function personsSearchRequest(queryParams) {
    return request({ url: buildUrl(personsSearchUrl, queryParams), });
}

/** @type {(personId: number, data: FemPersonEquineRelationshipWriteUpsert) => Request} */
export function personEquineUpdateRelationshipRequest(personId, data) {
    return postRequest(personEquineUpdateRelationshipUrl(personId), data);
}

export function contactsGetAllRequest() { return getAllRequest(contactsUrl); }
/** @type {(personId: number) => Request} */
export function personGetRequest(personId) { return getRequest(personsUrl, personId); }
/** @type {(personId: number, data: FemPersonWriteUpdate) => Request} */
export function personUpdateRequest(personId, data) { return updateRequest(personsUrl, personId, data); }
/** @type {(data: FemPersonWriteCreate) => Request} */
export function personCreateRequest(data) { return createRequest(personsUrl, data); }
/** @type {(personId: number) => Request} */
export function personDeleteRequest(personId) { return deleteRequest(personsUrl, personId); }

// Person defs
// *********************************************************************************************************************
// *********************************************************************************************************************

// *********************************************************************************************************************
// *********************************************************************************************************************
// User defs

/** @type {(queryParams: {searchText: string}) => Request} */
export function usersSearchRequest(queryParams) {
    return request({ url: buildUrl(usersSearchUrl, queryParams), });
}

// User
// *********************************************************************************************************************
// *********************************************************************************************************************

// *********************************************************************************************************************
// *********************************************************************************************************************
// UserUserRelationship defs

/** @type {(inviteeUserId: number) => Request} */
export function userUserRelationshipInviteRequest(inviteeUserId) {
    return postRequest(userUserRelationshipsInviteUrl(inviteeUserId), {});
}

/** @type {(inviterUserId: number) => Request} */
export function userUserRelationshipAcceptRequest(inviterUserId) {
    return postRequest(userUserRelationshipsAcceptUrl(inviterUserId), {});
}

/** @type {(inviterUserId: number) => Request} */
export function userUserRelationshipIgnoreRequest(inviterUserId) {
    return postRequest(userUserRelationshipsIgnoreUrl(inviterUserId), {});
}

/** @type {() => Request} */
export function userUserRelationshipsInvitationsRequest() {
    return getAllRequest(userUserRelationshipsInvitationsUrl);
}

// UserUserRelationship
// *********************************************************************************************************************
// *********************************************************************************************************************
