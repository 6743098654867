// @ts-check

import paths from "./paths.js";

/** @type {import("react-base").Menu} */
const menu = [
    {
        items: [
            { label: "Horses", target: paths.equines.all, },
            {
                label: "Connections",
                items: [
                    { label: "Invitations", target: paths.invitations.all, },
                    { label: "People", target: paths.connections.all, },
                    { label: "Service Providers", target: paths.contactServiceProviders.all, },
                ],
            },
            { label: "PonyLog", target: paths.ponylog, },
            // { label: "Message Hub", isSoon: true, },
            { label: "Profile", target: paths.profile, },
            {},
            {
                label: "Settings",
                items: [
                    { label: 'Change Password', target: paths.base.changePassword, },
                    { label: 'Service Providers', target: paths.managedServiceProviders.all },
                ],
            },
            { label: 'Feedback & Requests', target: paths.base.feedback, },
            { label: 'About', isSoon: true, },
        ]
    }
];

export default menu;