// @ts-check

import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import ProfileProperties from "../components/profile-properties";
import ApiButtonWithModal from "../components/general-purpose/api-button-with-modal";
import { serviceEquineRelationshipAcceptRequest, serviceEquineRelationshipRejectRequest, serviceProviderEquineRelationshipDeleteRequest, serviceProviderServiceCreateRequest,
    serviceProviderServiceDeleteRequest,
    serviceProviderServicePurgeRequest,
    serviceProviderServiceUndeleteRequest,
    serviceProviderServiceUpdateRequest,
    serviceProviderUpdateRequest } from "../api";
import ManagedServiceProviderForm from "../components/managed-service-provider-profile/managed-service-provider-form";
import { path } from "react-base";
import paths from "../paths";
import DeletableItemsList from "../components/general-purpose/deletable-items-list";
import ManagedServiceProviderServiceForm from "../components/managed-service-provider-service-form";
import { addToEntityListState, dateToString, removeAndReturnNewArrayIfRemoved } from "../helpers/others";
import RespondButton from "../components/managed-service-provider-profile/respond-button";
import TableWithButtons from "../components/general-purpose/table-with-buttons";
import LinkWithModal from "../components/general-purpose/link-with-modal";
import TextContent from "../components/general-purpose/text-content";
import ApiButton from "../components/general-purpose/api-button";

/** @type {import("../components/profile-properties").ProfilePropertiesFieldsInfo<import("../api").FemServiceProviderRead>} */
const FIELDS_INFO = {
    id: null,
    short_name: { label: "Short name" },
    name: { label: "Name" },
    company_name: { label: "Company name"},
    managers: { label: "Managers", type: "user list" },
    email: { label: "Email"},
    phone: { label: "Phone" },
    address: { label: "Address" },
};

/** @type {import("../components/profile-properties").ProfilePropertiesFieldDisplaySequence<import("../api").FemServiceProviderRead>} */
const FIELD_DISPLAY_SEQUENCE = [
    "short_name", "name", "company_name", "email", "phone", "address", "managers"
];

export default function ManagedServiceProviderProfile() {
    const apiLoaderData = /** @type {import("../api").FemServiceProviderSuccessfulGetResponse} */ (useLoaderData());
    const otherData = /** @type {FemServiceProviderGetResponseOtherData} */ apiLoaderData.other_data;

    const [entity, setEntity] = useState(apiLoaderData.entity)
    const [approvedHorses, setApprovedHorses] = useState(otherData.approved_horses);
    const [notYetApprovedHorses, setNotYetApprovedHorses] = useState(otherData.not_yet_approved_horses);
    const [pastRelatedHorses, setPastRelatedHorses] = useState(otherData.past_related_horses);
    const [serviceRequests, setServiceRequests] = useState(otherData.service_requests);

    /**
     * 
     * @param {number} serviceProviderId 
     * @param {number} serviceId 
     * @returns {string}
     */
    function navigateToServiceHome(serviceProviderId, serviceId) {
        return path(paths.managedServiceProviders.services.profile, {serviceProviderId, serviceId});
    }

    /**
     * 
     * @param {import("../api").FemServiceProviderEquineRelationshipRead} serviceProviderEquine 
     */
    function handleServiceProviderEquineApproval(serviceProviderEquine) {
        setNotYetApprovedHorses((oldValue) =>
            removeAndReturnNewArrayIfRemoved((spe) => spe.id === serviceProviderEquine.id, oldValue)
        );
        setApprovedHorses((oldValue) => [serviceProviderEquine, ...oldValue]);
    }

    /**
     * 
     * @param {import("../api").FemServiceProviderEquineRelationshipRead} serviceProviderEquine 
     */
    function handleServiceProviderEquineRejection(serviceProviderEquine) {
        setNotYetApprovedHorses((oldValue) =>
            removeAndReturnNewArrayIfRemoved((spe) => spe.id === serviceProviderEquine.id, oldValue)
        );
    }

    return (
        <>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">{entity.name}</h1>
                    </div>
                </div>
                <div className="level-right">
                    <div className="field has-addons">
                        <ApiButtonWithModal
                            femEntity={entity}
                            request={(/** @type {import("../api").FemServiceProviderWrite} */ data) => 
                                serviceProviderUpdateRequest(entity.id, data)}
                            label="Edit"
                            onSuccess={setEntity}
                            modal={(submitHandler) => (
                                <ManagedServiceProviderForm
                                    entity={entity}
                                    handleSubmit={(_original, edited) => submitHandler(edited)} />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="box">
                <ProfileProperties entity={entity} fieldsToDisplay={FIELD_DISPLAY_SEQUENCE} fieldsInfo={FIELDS_INFO} />
            </div>

            {notYetApprovedHorses.length === 0
                ? <></>
                :
                    <div className="box">
                        <TableWithButtons
                            title={<h2>Onboarding Requests</h2>}
                            emptyListText="No onboarding requests."
                            entities={notYetApprovedHorses}
                            setEntities={setNotYetApprovedHorses}
                            tableHeaderElements={
                                <>
                                    <th>Name</th>
                                    <th>Requested by</th>
                                </>
                            }
                            oneRowTableDataElements={(serviceProviderEquine) =>
                                <>
                                    <td>
                                        {serviceProviderEquine.equine.nickname}
                                    </td>
                                    <td>
                                        {serviceProviderEquine.customerApproval?.user.display_name ?? "???"}
                                    </td>
                                </>
                            }
                            viewButton={{
                                label: "Details",
                                entityProfilePath: (id) => path(paths.serviceProviderEquineRelationships.profile, {id}),
                            }}
                            rowButtons={[
                                {
                                    type: "view",
                                    elementFn: ({entity: serviceProviderEquine, additionalButtonStyles}) => 
                                        <RespondButton
                                            additionalButtonStyles={additionalButtonStyles}
                                            femEntity={serviceProviderEquine}
                                            onApprove={handleServiceProviderEquineApproval}
                                            onReject={handleServiceProviderEquineRejection}
                                        />
                                }
                            ]}
                        />
                        
                    </div>
            }

            {serviceRequests.length > 0
                ?
                    <div className="box">
                        <TableWithButtons
                            entities={serviceRequests}
                            setEntities={setServiceRequests}
                            emptyListText="No service requests."
                            title={<h2>Service Requests</h2>}
                            tableHeaderElements={(
                                <>
                                    <th>Horse</th>
                                    <th>Service</th>
                                    <th>Requested</th>
                                </>
                            )}
                            oneRowTableDataElements={(serviceEquineRelationship) => (
                                <>
                                    <td>{serviceEquineRelationship.equine.nickname}</td>
                                    <td>{serviceEquineRelationship.service.name}</td>
                                    <td>{dateToString(serviceEquineRelationship.requestedAt)}</td>
                                </>
                            )}
                            rowButtons={[
                                {
                                    type: "delete",
                                    elementFn: ({entity: serviceProviderEquine, onClickHandler, additionalButtonStyles}) =>
                                        <ApiButton
                                            additionalButtonStyles={additionalButtonStyles}
                                            femEntity={{}}
                                            label="Accept"
                                            request={() => serviceEquineRelationshipAcceptRequest(serviceProviderEquine.id)}
                                            onSuccess={() => { onClickHandler(serviceProviderEquine); }}
                                        />
                                },
                                {
                                    type: "delete",
                                    elementFn: ({entity: serviceProviderEquine, onClickHandler, additionalButtonStyles}) =>
                                        <ApiButton
                                            additionalButtonStyles={additionalButtonStyles}
                                            femEntity={{}}
                                            label="Reject"
                                            request={() => serviceEquineRelationshipRejectRequest(serviceProviderEquine.id)}
                                            onSuccess={() => { onClickHandler(serviceProviderEquine); }}
                                        />
                                },
                            ]}
                        />
                    </div>
                : <></>
            }

            <div className="box">
                <DeletableItemsList
                    title={<h2>Services</h2>}
                    emptyEntityListText="There are no services for this provider."
                    entities={otherData.services}
                    deletedEntities={otherData.deleted_services}
                    entityProfilePath={(serviceId) => navigateToServiceHome(entity.id, serviceId)}
                    createRequest={(data) => serviceProviderServiceCreateRequest(entity.id, data)}
                    updateRequest={(serviceId, data) => serviceProviderServiceUpdateRequest(entity.id, serviceId, data)}
                    deleteRequest={(serviceId) => serviceProviderServiceDeleteRequest(entity.id, serviceId)}
                    purgeRequest={(serviceId) => serviceProviderServicePurgeRequest(entity.id, serviceId)}
                    undeleteRequest={(serviceId) => serviceProviderServiceUndeleteRequest(entity.id, serviceId)}
                    tableHeaderElements={
                        <>
                            <th className="pt-4">Name</th>
                            <th className="pt-4 is-hidden-touch">Description</th>
                        </>
                    }
                    oneRowTableDataElements={(entity) => (
                        <>
                            <td>
                                {entity.name}
                            </td>
                            <td className="is-hidden-touch">
                                {entity.description}
                            </td>
                        </>
                    )}
                    deletedEntitiesTableHeaderElements={
                        <>
                            <th className="pt-4">Name</th>
                            <th className="pt-4 is-hidden-touch">Description</th>
                        </>
                    }
                    oneRowDeletedEntitiesTableDataElement={(entity) => (
                        <>
                            <td>
                                {entity.name}
                            </td>
                            <td className="is-hidden-touch">
                                {entity.description}
                            </td>
                        </>
                    )}
                    CreateForm={ManagedServiceProviderServiceForm}
                    UpdateForm={ManagedServiceProviderServiceForm}
                />
            </div>

            {approvedHorses.length === 0
                ? <></>
                :
                    <div className="box">
                        <TableWithButtons
                            title={<h2>Current Horses</h2>}
                            emptyListText="No onboarded horses."
                            entities={approvedHorses}
                            setEntities={setApprovedHorses}
                            tableHeaderElements={
                                <>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Since
                                    </th>
                                    <th>
                                        Signed up by
                                    </th>
                                </>
                            }
                            oneRowTableDataElements={(serviceProviderEquine) =>
                                <>
                                    <td>
                                        {serviceProviderEquine.equine.nickname}
                                    </td>
                                    <td>
                                        {serviceProviderEquine.customerApproval || serviceProviderEquine.serviceProviderApproval
                                            ? dateToString(
                                                Math.max(
                                                    serviceProviderEquine.customerApproval?.approvedAt ?? 0,
                                                    serviceProviderEquine.serviceProviderApproval?.approvedAt ?? 0
                                                ))
                                            : "???"
                                        }
                                    </td>
                                    <td>
                                        {serviceProviderEquine.customerApproval?.user.display_name ?? "???"}
                                    </td>
                                </>
                            }
                            deleteButton={{
                                label: "Remove",
                                deleteRequest: serviceProviderEquineRelationshipDeleteRequest,
                                onDelete: (entity) => addToEntityListState(
                                    /** @type {import("../api").FemServiceProviderEquineRelationshipRead} */
                                    ({
                                        ...entity,
                                        deleted: {
                                            deletedAt: (new Date()).getTime(),
                                            user: {id: -1, display_name: "you"}
                                        }
                                    }), setPastRelatedHorses, {side: "front", limit: 10}),
                            }}
                            viewButton={{
                                label: "Details",
                                entityProfilePath: (id) => path(paths.serviceProviderEquineRelationships.profile, {id}),
                            }}
                    />
                </div>
            }

            {pastRelatedHorses.length === 0
                ? <></>
                :
                    <div className="box">
                        <TableWithButtons
                            title={<h2>Past Associated Horses</h2>}
                            emptyListText="No past horses."
                            entities={pastRelatedHorses}
                            setEntities={setPastRelatedHorses}
                            tableHeaderElements={
                                <>
                                    <th>Name</th>
                                    <th>Status</th>
                                </>
                            }
                            oneRowTableDataElements={(serviceProviderEquine) =>
                                <>
                                    <td>{serviceProviderEquine.equine.nickname}</td>
                                    <td>{serviceProviderEquine.serviceProviderRejection === null
                                            ? (serviceProviderEquine.serviceProviderApproval === null
                                                ? `request retracted${serviceProviderEquine.deleted ? ` on ${dateToString(serviceProviderEquine.deleted.deletedAt)}` : ""}`
                                                : `service provider removed${serviceProviderEquine.deleted ? ` on ${dateToString(serviceProviderEquine.deleted.deletedAt)}` : ""}`
                                            )
                                            : <>
                                                service provider rejected on {dateToString(serviceProviderEquine.serviceProviderRejection.rejectedAt)}
                                                {serviceProviderEquine.response_note && serviceProviderEquine.response_note.length > 0
                                                    ? 
                                                        <>&nbsp;(<LinkWithModal
                                                            content={
                                                                <> 
                                                                    <p><strong>Service provider's response:</strong></p>
                                                                    <TextContent content={serviceProviderEquine.response_note} />
                                                                </>
                                                            }
                                                            linkContent="note"
                                                        />)</>
                                                    : ""
                                                }
                                            </>}
                                    </td>
                                </>
                            }
                            viewButton={{
                                label: "Details",
                                entityProfilePath: (id) => path(paths.serviceProviderEquineRelationships.profile, {id}),
                            }}
                        />
                    </div>
            }
        </>
    );
}