// @ts-check

import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import TableWithButtons from "../components/general-purpose/table-with-buttons";
import paths from "../paths";
import { personCreateRequest, personDeleteRequest, personUpdateRequest, userUserRelationshipInviteRequest } from "../api";
import PersonForm from "../components/person-form";
import ApiButtonWithModal from "../components/general-purpose/api-button-with-modal";
import toast from "react-hot-toast";
import UserSearchForm from "../components/user-search-form";

export default function Contacts() {
    const dataLoadedFromApi = /** @type {import("../api").FemConnectionsSuccessfulGetAllResponse} */ (useLoaderData());
    const [persons, setPersons] = useState(dataLoadedFromApi.collection);

    return (
        <>
            <h1 className="title">Connections</h1>
            <TableWithButtons
                entities={persons}
                setEntities={setPersons}
                emptyListText="You don't have any contacts."
                tableHeaderElements={
                    <>
                        <th>Display name</th>
                        <th>Name</th>
                    </>
                }
                oneRowTableDataElements={(entity) =>
                    <>
                        <td>{entity.display_name}</td>
                        <td>{entity.full_name}</td>
                    </>
                }
                tableButtons={[
                    ({createHandler, additionalButtonStyles}) => 
                        <ApiButtonWithModal
                            additionalButtonStyles={additionalButtonStyles}
                            label="Create"
                            request={personCreateRequest}
                            modal={(submitHandler) =>
                                <PersonForm onSubmit={(_original, edited) => submitHandler(edited)} />
                            }
                            onSuccess={(femEntity) => createHandler(femEntity, "to top")}
                        />,
                    ({additionalButtonStyles}) =>
                        <ApiButtonWithModal
                            additionalButtonStyles={additionalButtonStyles}
                            label="Connect"
                            modal={(submitHandler) => <UserSearchForm onSelect={submitHandler} />}
                            request={({invitee}) => userUserRelationshipInviteRequest(invitee.id)}
                            femEntity={
                                /** @type {{invitee: import("../api").FemUserMention | null}} */ ({invitee: null})
                            }
                            onSuccess={() => {
                                toast.success("Invitation sent. You will be connected once they accept.");
                            }}
                        />
                ]}
                editButton={{
                    updateRequest: personUpdateRequest,
                    UpdateForm: ({entity, handleSubmit}) =>
                        <PersonForm
                            onSubmit={handleSubmit}
                            person={entity}
                        />,
                    isShown: (entity) => entity.is_non_user,
                }}
                deleteButton={{
                    deleteRequest: personDeleteRequest,
                    isShown: (entity) => entity.is_non_user,
                }}
                viewButton={{
                    entityProfilePath: (id) => paths.persons.profile.replace(":id", id.toString()),
                }}
            />
        </>
    );
}