// @ts-check

import React, { useState } from "react";
import SearchInput from "./search-input";
import { useApi } from "react-base";
import toast from "react-hot-toast";

/**
 * @template ENTITY
 * @callback ApiSearchInputSelectHandler
 * @param {ENTITY | null} selectedValue
 * @returns {void | Promise<void>}
 */

/**
 * @template ENTITY
 * @typedef {Object} SearchInputProps
 * @prop {ENTITY | null} value
 * @prop {ApiSearchInputSelectHandler<ENTITY>} onSelect
 * @prop {(entity: ENTITY) => string} entityToLabel
 * @prop {(searchText: string) => Request} searchRequest
 * @prop {string} [notFoundText="Not found."]
 */

/**
 * 
 * @template ENTITY 
 * @param {SearchInputProps<ENTITY>} param0 
 * @returns 
 */
export default function ApiSearchInput({
    value,
    onSelect,
    entityToLabel,
    searchRequest,
    notFoundText = "Not found."
}) {
    const fetchFromApi = useApi();
    const [_toastId, setToastId] = useState(/** @type {string | null} */ (null));

    /** @type {import("./search-input").SearchInputSearchHandler<ENTITY>} */
    async function handleSearch(searchText) {
        setToastId((oldVal) => {
            if (oldVal !== null) {
                toast.dismiss(oldVal);
            }
            return null;
        })
        const response = await fetchFromApi(searchRequest(searchText));
        if (response.isOk) {
            const responseData = 
                /** @type {import("../../api").FemApiSuccessfulCollectionResponseWithoutOtherData<ENTITY>} */
                (response.data);
            const searchResults = responseData.collection;
            if (searchResults.length === 0) {
                setToastId((oldVal) => {
                    if (oldVal !== null) {
                        toast.dismiss(oldVal);
                    }
                    return toast(notFoundText);
                });
            }
            return searchResults;
        } else {
            toast.error("Server error");
            return [];
        }

    }

    return (
        <SearchInput
            entityToLabel={entityToLabel}
            onSearch={handleSearch}
            onSelect={onSelect}
            value={value}
        />
    );
}