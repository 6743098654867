// @ts-check

import React, { useState } from "react";
import TriStateCheckbox from "./general-purpose/tri-state-checkbox";

/**
 * @typedef {{[K in import("../api").FemRole]: import("./general-purpose/tri-state-checkbox").RoleCheckboxState}} RoleStates
 */

/**
 * @callback RoleStateChangeHandler
 * @param {import("./general-purpose/tri-state-checkbox").RoleCheckboxDeterminateState} newState 
 * @param {import("../api").FemRole} role 
 * @return {void}
 */

/**
 * 
 * @param {import("../api").FemRoleToDisplayNameMapping} allRelationships 
 * @param {Array<import("../api").FemRole>} rolesList 
 * @returns {import("./relationships-checkboxes").RoleStates}
 */
export function roleStatesFromRolesList(allRelationships, rolesList) {
    return /** @type {import("./relationships-checkboxes").RoleStates} */ (
        /** @type {Array<import("../api").FemRole>} */ (Object.keys(allRelationships))
            .reduce((acc, role) => ({
                ...acc,
                [role]: rolesList.includes(role) ? "checked" : "unchecked"
            }), {}));
}

/**
 * @typedef {Object} RelationshipCheckboxesProps
 * @prop {import("../api").FemRoleToDisplayNameMapping} allRelationships
 * @prop {Set<import("../api").FemRole>} [rolesThatCanBeChecked]
 * @prop {Set<import("../api").FemRole>} [rolesThatCanBeUnchecked]
 * @prop {string} [label="Roles"]
 * @prop {RoleStates} roleStates
 * @prop {React.Dispatch<React.SetStateAction<RoleStates>>} [setRoleStates]
 * @prop {RoleStateChangeHandler} [onRoleStateChange]
 */

/**
 * 
 * @param {RelationshipCheckboxesProps} param0 
 * @returns 
 */
export default function RelationshipCheckboxes({
    allRelationships,
    rolesThatCanBeChecked,
    rolesThatCanBeUnchecked,
    label="Roles",
    roleStates,
    setRoleStates,
    onRoleStateChange,
}) {
    const allRoles = /** @type {Array<import("../api").FemRole>} */ (Object.keys(allRelationships));
    const rolesThatCanBeCheckedValue =
        (rolesThatCanBeChecked !== undefined ? rolesThatCanBeChecked : new Set(allRoles));
    const rolesThatCanBeUncheckedValue =
        (rolesThatCanBeUnchecked !== undefined ? rolesThatCanBeUnchecked : new Set(allRoles));
    const [personSetRoles, setPersonSetRoles] = useState(new Set());
    const [personRemovedRoles, setPersonRemovedRoles] = useState(new Set());

    /**
     * 
     * @param {import("./general-purpose/tri-state-checkbox").RoleCheckboxDeterminateState} newState 
     * @param {import("../api").FemRole} role 
     */
    function handleRoleCheckboxChange(newState, role) {
        if (setRoleStates) {
            setRoleStates((oldVal) => {
                const newVal = {...oldVal};
                if (newState === "checked") {
                    newVal[role] = "checked";
                    // add to person set roles
                    setPersonSetRoles((oldVal) => {
                        const newVal = new Set(oldVal);
                        newVal.add(role);
                        return newVal;
                    });
                    // remove from person set roles 
                    setPersonRemovedRoles((oldVal) => {
                        const newVal = new Set(oldVal);
                        newVal.delete(role);
                        return newVal;
                    });
                } else {
                    newVal[role] = "unchecked";
                    // remove from person set roles
                    setPersonSetRoles((oldVal) => {
                        const newVal = new Set(oldVal);
                        newVal.delete(role);
                        return newVal;
                    });
                    // add to person set roles 
                    setPersonRemovedRoles((oldVal) => {
                        const newVal = new Set(oldVal);
                        newVal.add(role);
                        return newVal;
                    });
                }
                return newVal;
            });
        }
        if (onRoleStateChange) {
            onRoleStateChange(newState, role);
        }
    }

    /**
     * 
     * @param {import("../api").FemRole} role 
     * @returns {boolean}
     */
    function isCheckboxDisabled(role) {
        // we compute "should not be disabled" below, then negate, as it's more understandable
        const shouldNotBeDisabled =
            // role is currently selected
            (
                roleStates[role] === "checked"
                && (
                    personSetRoles.has(role) // it's person who set the role while editing, so they should be able to remove
                    || rolesThatCanBeUncheckedValue.has(role) // or role is in the removable roles list from API
                )
            )

            // role is currently not selected
            || (
                (roleStates[role] === undefined || roleStates[role] === "unchecked")
                && (
                    personRemovedRoles.has(role) // person removed this role while editing, so they should be able to add back
                    || rolesThatCanBeCheckedValue.has(role) // or role in the creatable roles list from API
                )
            )

            // role is currently indeterminate
            || (
                roleStates[role] === "indeterminate"
            );
        return !shouldNotBeDisabled;
    }

    return (
        <div className="field">
            <div className="control">
                <label className="label">{label}</label>
                <div className="grid">
                    {allRoles.map((role) => {
                        return (
                            <div className="cell">
                                <label className="checkbox">
                                    <TriStateCheckbox
                                        state={roleStates[role] ?? "unchecked"}
                                        disabled={isCheckboxDisabled(role)}
                                        onChange={(newState) => handleRoleCheckboxChange(newState, role)}
                                    />&nbsp;{allRelationships[role]}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}