// @ts-check

import React, { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import Property from "../components/general-purpose/property";
import ApiButtonWithModal from "../components/general-purpose/api-button-with-modal";
import { personUpdateRequest } from "../api";
import PersonForm from "../components/person-form";
import paths from "../paths";

export default function PersonProfile() {
    const apiData =
        /** @type {import("../api").FemApiSuccessfulEntityResponseWithoutOtherData<import("../api").FemPersonRead>} */
        (useLoaderData());
    const [person, setPerson] = useState(/** @type {import("../api").FemPersonRead} */ (apiData.entity));

    return (
        <>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">{person.display_name}</h1>
                    </div>
                </div>
                <div className="level-right">
                    {person.is_non_user
                        ?
                            <div className="field">
                                <div className="control">
                                    <ApiButtonWithModal
                                        label="Edit"
                                        request={(data) => personUpdateRequest(person.id, data)}
                                        modal={(submitHandler) =>
                                            <PersonForm
                                                onSubmit={(_original, edited) => submitHandler(edited)}
                                                person={person} />
                                        }
                                        onSuccess={setPerson}
                                    />
                                </div>
                            </div>
                        : <></>
                    }
                </div>
            </div>
            <div className="box">
                <Property label="Full Name" value={person.full_name ?? ""} />
                <Property label="Display Name" value={person.display_name} />
                <Property label="Email" value={person.email} />
                <Property label="Phone" value={person.phone} />
                <Property label="Emergency Contacts">
                    {person.emergency_contacts.length > 0
                        ? person.emergency_contacts.map((contact, index) => 
                            <>
                                <Link to={paths.persons.profile.replace(":id", contact.id.toString())}>{contact.display_name}</Link>
                                {index < person.emergency_contacts.length - 1 ? ", " : ""}
                            </>
                        )
                        : "None specified."
                    }
                </Property>
                <Property label="Status"
                    value={
                        person.amateur_professional_status === "amateur"
                            ? "Amateur"
                            : (person.amateur_professional_status === "professional" ? "Professional" : undefined)} />
                <Property label="USEF Number" value={person.usef_number ?? ""} />
                <Property label="USHJA Number" value={person.ushja_number ?? ""} />
                <Property label="FEI Id" value={person.fei_id ?? ""} />
                <Property label="Allergies" value={person.allergies ?? ""} />
                <Property label="Medical Insurance Company" value={person.medical_insurance_company ?? ""} />
                <Property label="Medical Insurance Id" value={person.medical_insurance_id ?? ""} />
            </div>
        </>
    );
}