// @ts-check

import React, { useState } from "react";
import PonyLogFormModal from "./ponylog-form-modal";
import { useApi } from "react-base";
import { ponylogEntryUpdateRequest } from "../api";

/**
 * @callback PonyLogEditButtonUpdateHandler
 * @param {import("../api").FemPonylogEntryRead} entity Entity that was just updated
 * @returns {Promise<void> | void}
 */

/**
 * @typedef {Object} PonyLogCreateButtonProps
 * @prop {Array<import("../api").FemEquineMentionWithUserRelationshipsRead>} allHorses
 * @prop {Array<import("../api").FemEquineMentionWithUserRelationshipsRead>} [allDeletedHorses=[]]
 * @prop {string} [label="New"]
 * @prop {string} [additionalButtonStyles]
 * @prop {import("../api").FemPonylogEntryRead} entry
 * @prop {PonyLogEditButtonUpdateHandler} [onUpdate]
 * @prop {boolean} [disabled = false]
 */

/**
 * 
 * @param {PonyLogCreateButtonProps} param0 
 * @returns {React.JSX.Element}
 */
export default function PonyLogEditButton({
    allHorses,
    allDeletedHorses = [],
    label = "Edit",
    additionalButtonStyles,
    entry,
    onUpdate,
    disabled = false,
}) {
    const [isModalShowing, setIsModalShowing] = useState(false);
    const fetchFromApi = useApi();

    /**
     * 
     * @param {number} id
     * @param {import("../api").FemPonylogEntryWrite} editedData
     * @returns {Promise<import("../../../api/src/fem-types").FemApiErrorResponse | null>}
     */
    async function updatePonyLogEntry(id, editedData) {
        const response = await fetchFromApi(ponylogEntryUpdateRequest(id, editedData));
        if (response.isOk) {
            if (response.data?.entity && onUpdate) {
                onUpdate(response.data.entity);
            }
            setIsModalShowing(false);
            return null;
        } else {
            return response.data;
        }

        return null;
    }
    
    /** @type {import("./ponylog-form").PonyLogFormSubmitHandler} */
    async function handleSubmit(original, edited) {
        if (original) {
            return updatePonyLogEntry(original?.id, edited);
        } else {
            return {errors: ["Unexpected error."]};
        }
    }

    return (
        <>
            <PonyLogFormModal
                allHorses={allHorses}
                allDeletedHorses={allDeletedHorses}
                entry={entry}
                handleSubmit={handleSubmit}
                submitButtonLabel="Save"
                isShowing={isModalShowing}
                setIsShowing={setIsModalShowing}
            />
            <div className="control">
                <button
                    className={["button", additionalButtonStyles].join(" ")}
                    onClick={() => setIsModalShowing(true)}
                    disabled={disabled}
                >{label}</button>
            </div>
        </>
    );
}
