// @ts-check

/**
 * @template ENTITY
 * @callback SearchInputSearchHandler
 * @param {string} prompt
 * @returns {Promise<Array<ENTITY>> | Array<ENTITY>}
 */

/**
 * @template ENTITY
 * @callback SearchInputSelectHandler
 * @param {ENTITY | null} selectedValue
 * @returns {void | Promise<void>}
 */

/**
 * @template ENTITY
 * @typedef {Object} SearchInputProps
 * @prop {ENTITY | null} value
 * @prop {SearchInputSelectHandler<ENTITY>} onSelect
 * @prop {SearchInputSearchHandler<ENTITY>} onSearch
 * @prop {(entity: ENTITY) => string} entityToLabel
 */

import React, { useState } from "react";

/**
 * 
 * @template ENTITY 
 * @param {SearchInputProps<ENTITY>} param0 
 * @returns 
 */
export default function SearchInput({value, onSelect, onSearch, entityToLabel}) {
    // duration to reset timer after
    // (400ms wait, calculated based on 23wps for slow and 35wps for moderate typing, with 4.7 chars/word (English))
    const KEYPRESS_WAIT_DURATION_MS = 400;

    const [dropdownContent, setDropdownContent] = useState(/** @type {Array<ENTITY>} */ ([]));
    const [_timerId, setTimerId] = useState(/** @type {number | undefined} */ (undefined));
    const [searchText, setSearchText] = useState("");

    /**
     * Search and display the results in a dropdown.
     * 
     * @param {string} searchText
     * @return {Promise<void>}
     */
    async function handleKeypressTimeout(searchText) {
        const response = await onSearch(searchText);
        setDropdownContent(response);
    }

    /** @type {React.ChangeEventHandler<HTMLInputElement>} */
    function handleTextChange(event) {
        // if a user is set, reset user selection
        onSelect(null);

        const newSearchText = event.target.value;
        setTimerId((oldTimerId) => {
            if (oldTimerId) {
                clearTimeout(oldTimerId);
            }
            if (newSearchText.length >=3) {
                return window.setTimeout(
                    async () => {
                        await handleKeypressTimeout(newSearchText);
                    },
                    KEYPRESS_WAIT_DURATION_MS);
            } else {
                return undefined;
            }
        })
        setSearchText(newSearchText);
    }

    /**
     * 
     * @param {React.MouseEvent} event 
     * @param {ENTITY} entity
     */
    function handleItemClick(event, entity) {
        event.stopPropagation();
        setSearchText(entityToLabel(entity));
        onSelect(entity);
        setDropdownContent([]); // closes dropdown
    }

    return (
        <div className={"dropdown is-fullwidth" + (dropdownContent.length > 0 ? " is-active" : "")}>
            <div className="dropdown-trigger">
                <input 
                    className="input"
                    type="text"
                    placeholder={"Search"}
                    value={value ? entityToLabel(value) : searchText}
                    onChange={handleTextChange}/>
            </div>
            <div className="dropdown-menu" role="menu">
                <div className="dropdown-content has-text-left">
                    {dropdownContent.map((entity) => (
                        <div className="dropdown-item"
                                onClick={(e) => handleItemClick(e, entity)}>
                            {entityToLabel(entity)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}