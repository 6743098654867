// @ts-check

import React, { useState } from "react";
import { checkValue, field } from "../helpers/others";
import { ActionButton, BulmaLevel, useErrors } from "react-base";
import toast from "react-hot-toast";
import { serviceProviderConnectionSearchRequest } from "../api";
import ApiSearchInput from "./general-purpose/api-search-input";

const ERROR_FIELD_SERVICE_PROVIDER = "service-provider";
const ERROR_SERVICE_PROVIDER_REQUIRED = "required";
const ERROR_SERVICE_PROVIDER_NOT_PRESENT = `You must choose a service provider to request from.`;

const ERR_API = 'api';
const ERR_API_SUBMIT_FAILED = 'failure';

/**
 * @callback ServiceProviderConnectionEquineRelationshipFormSubmitHandler 
 * @param {import("../api").FemServiceProviderEquineRelationshipRead | null | undefined} original
 * @param {import("../api").FemServiceProviderEquineRelationshipRequestWrite} edited
 * @returns {Promise<import("../api").FemApiErrorResponse | null>}
 */

/**
 * @typedef {Object} ServiceProviderConnectionEquineRelationshipFormProps
 * @prop {number} equineId
 * @prop {import("../api").FemServiceProviderEquineRelationshipRead} [entity]
 * @prop {ServiceProviderConnectionEquineRelationshipFormSubmitHandler} handleSubmit
 * @prop {string} [submitButtonLabel = "Request"]
 */

/**
 *
 * @param {ServiceProviderConnectionEquineRelationshipFormProps} param0
 * @returns {React.JSX.Element}
 */
export default function ServiceProviderConnectionEquineRelationshipForm({
    equineId,
    entity,
    handleSubmit,
    submitButtonLabel = "Add",
}) {
    const [serviceProvider, setServiceProvider] =
        useState(/** @type {import("../api").FemServiceProviderMentionRead | null} */ (null))

    const [isValid, setIsValid] = useState(true);
    const {setError, resetError, renderErrors} = useErrors();

   /**
     * @typedef {Object} CheckValidityProps
     * @prop {import("../api").FemServiceProviderMentionRead | null} [currentServiceProvider = serviceProvider]
     */
   /**
     * 
     * @param {CheckValidityProps} [param0 = {}]
     * @returns {boolean}
     */
    function checkValidity({
        currentServiceProvider = serviceProvider,
    } = {}) {
        const checks = [
            // service provider
            checkValue({
                errorCondition: currentServiceProvider === null || currentServiceProvider === undefined,
                errFieldName: ERROR_FIELD_SERVICE_PROVIDER,
                errConditionName: ERROR_SERVICE_PROVIDER_NOT_PRESENT,
                errMessage: ERROR_SERVICE_PROVIDER_REQUIRED,
            }, setError, resetError),
        ];
        const validity = (checks.find((v) => v === false) === undefined);
        setIsValid(validity);
        return validity;
    }

    function anyChange() {
        resetError(ERR_API, ERR_API_SUBMIT_FAILED);
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleFormButtonClick(cb) {
        if (checkValidity()) {
            let response;

            // this will always hold, as checkValidity should fail otherwise
            if (serviceProvider) {
                const newEntity = /** @type {import("../api").FemServiceProviderEquineRelationshipRequestWrite} */ ({
                    equine_id: equineId,
                    service_provider_id: serviceProvider.id,
                });
                response = await handleSubmit(entity, newEntity);

                cb();
                if (response !== null) {
                    const errorResponse = /** @type {import("../api").FemApiErrorResponse} */ (response);
                    toast.error("Request failed.")
                    setError(ERR_API, ERR_API_SUBMIT_FAILED, errorResponse.errors);
                    return;
                }
            }
        } else {
            cb();
        }
    }

    return (
        <form className="has-text-left">
            {field({
                label: "Service provider",
                children: 
                    <ApiSearchInput
                        searchRequest={(searchText) => serviceProviderConnectionSearchRequest({nameContains: searchText})}
                        entityToLabel={(serviceProvider) => serviceProvider.name}
                        onSelect={(selectedValue) => {anyChange(); setServiceProvider(selectedValue);}}
                        value={serviceProvider ?? null}
                        notFoundText="No service provider found."
                    />
            })}

            {renderErrors()}

            <BulmaLevel
                right={[
                    <ActionButton disabled={!isValid} onClick={handleFormButtonClick}>{submitButtonLabel}</ActionButton>
                ]}
            />

        </form>
    );
}