// @ts-check

/** @typedef {(equineId: number) => void} EquineUndeleteButtonDeletedCallback */

/**
 * @typedef {Object} EquineDeleteButtonProps
 * @prop {number} equineId
 * @prop {EquineUndeleteButtonDeletedCallback} undeletedCallback
 */

import React from "react";
import { ActionButton, useApi } from "react-base";
import { equineUndeleteRequest } from "../api";
import toast from "react-hot-toast";

/**
 * 
 * @param {EquineDeleteButtonProps} param0 
 * @returns 
 */
export default function EquineUndeleteButton({equineId, undeletedCallback}) {
    const fetchFromApi = useApi();

    /**
     * 
     * @param {number} id 
     * @param {import("react-base/dist/components/action-button").ActionButtonClickHandlerCallback} callback 
     */
    async function undeleteEquine(id, callback) {
        const response = await fetchFromApi(equineUndeleteRequest(id));
        if (response.isOk) {
            undeletedCallback(id);
        } else {
            toast.error(
                <>
                    <ul>
                        {(response.data?.errors ?? []).map((/** @type {string} */ error) => (
                            <li>{error}</li>
                        ))}
                    </ul>
                </>
            );
        }
        callback();
    }

    return (
        <ActionButton
            onClick={(callback) => undeleteEquine(equineId, callback)}
        >Undelete</ActionButton>
    );
}