// @ts-check

/** @typedef {(equine: import("../api").FemEquineRead) => void} EquineFormModalCreateEquineCallback */
/**
 * @typedef {Object} EquineCreateButtonProps
 * @prop {EquineFormModalCreateEquineCallback} createdEquineCallback
 * @prop {import("../api").FemRoleToDisplayNameMapping} allRelationships
 * @prop {string} [additionalButtonStyles='']
 */

import React, { useState } from "react";
import { useApi } from "react-base";
import { equineCreateRequest } from "../api";
import EquineFormModal from "./equine-form-modal";

/**
 * 
 * @param {EquineCreateButtonProps} param0 
 * @returns 
 */
export default function EquineCreateButton({createdEquineCallback, allRelationships, additionalButtonStyles = ''}) {
    const [isModalShowing, setIsModalShowing] = useState(false);
    const fetchFromApi = useApi();

    /**
     * 
     * @param {import("../api").FemEquineWrite} data
     * @returns {Promise<import("../../../api/src/fem-types").FemApiErrorResponse | null>}
     */
    async function createEquine(data) {
        const response = await fetchFromApi(equineCreateRequest(data));
        if (response.isOk) {
            if (response.data?.entity) {
                createdEquineCallback(response.data.entity);
            }
            setIsModalShowing(false);
            return null;
        } else {
            return response.data;
        }
    }

    /** @type {import("./equine-form").EquineFormSubmitHandler} */
    async function handleSubmit(original, edited) {
        if (!original) {
            return createEquine(edited);
        } else {
            return {errors: ["Unexpected error."]};
        }
    }

    return (
        <>
            <EquineFormModal
                isShowing={isModalShowing}
                setIsShowing={setIsModalShowing}
                handleSubmit={handleSubmit}
                submitButtonLabel="Save"
                allRelationships={allRelationships}
            />
            <button className={["button", additionalButtonStyles].join(" ")} onClick={() => setIsModalShowing(true)}>New</button>
        </>
    );
}