// @ts-check

import React, { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import UserEditButton from "../components/user-edit-button";
import Property from "../components/general-purpose/property";
import paths from "../paths";

/**
 * Displays the profile of the user.
 * 
 * @returns {React.JSX.Element}
 */
export default function UserProfile() {
    const apiData = /** @type {import("../api").FemApiSuccessfulEntityResponseWithoutOtherData<import("../api").FemProfileRead>} */ (useLoaderData());
    const [profile, setProfile] = useState(/** @type {import("../api").FemProfileRead} */ (apiData.entity));

    return (
        <>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">{profile.display_name}</h1>
                    </div>
                </div>
                <div className="level-right">
                    <div className="field">
                        <div className="control">
                            <UserEditButton
                                profile={profile}
                                setProfile={setProfile}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="box">
                <Property label="Username" value={profile.user_name} />
                <Property label="Full Name" value={profile.full_name} />
                <Property label="Display Name" value={profile.display_name} />
                <Property label="Email" value={profile.email} />
                <Property label="Phone" value={profile.phone} />
                <Property label="Emergency Contacts">
                    {profile.emergency_contacts.length > 0
                        ? profile.emergency_contacts.map((contact, index) => 
                            <>
                                <Link to={paths.persons.profile.replace(":id", contact.id.toString())}>{contact.display_name}</Link>
                                {index < profile.emergency_contacts.length - 1 ? ", " : ""}
                            </>
                        )
                        : "None specified."
                    }
                </Property>
                <Property label="Status"
                    value={
                        profile.amateur_professional_status === "amateur"
                            ? "Amateur"
                            : (profile.amateur_professional_status === "professional" ? "Professional" : undefined)} />
                <Property label="USEF Number" value={profile.usef_number} />
                <Property label="USHJA Number" value={profile.ushja_number} />
                <Property label="FEI Id" value={profile.fei_id} />
                <Property label="Allergies" value={profile.allergies} />
                <Property label="Medical Insurance Company" value={profile.medical_insurance_company} />
                <Property label="Medical Insurance Id" value={profile.medical_insurance_id} />
            </div>
        </>
    );
}