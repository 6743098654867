// @ts-check

import React from "react";
import { Link, useLoaderData } from "react-router-dom";
import Property from "../components/general-purpose/property";
import paths from "../paths";
import { path } from "react-base";
import { dateToString } from "../helpers/others";
import TextContent from "../components/general-purpose/text-content";
import LinkWithModal from "../components/general-purpose/link-with-modal";

export default function ServiceProviderEquineRelationship() {
    const loadedData = /** @type {import("../api").FemServiceProviderEquineSuccessfulGetResponse} */ (useLoaderData());
    const entity = /** @type {import("../api").FemServiceProviderEquineRelationshipRead} */ (loadedData.entity);

    console.log(`loaded data: ${JSON.stringify(loadedData, null, 4)}`);

    return (
        <>
            <h1>{entity.serviceProviderRejection || entity.deleted ? "Past " : ""}Service Provider - Horse Relationship</h1>
            <Property label="Service Provider">{entity.service_provider.name}</Property>
            <Property label="Horse">
                <Link to={path(paths.equines.profile, {equineId: entity.equine.id})}>{entity.equine.nickname}</Link>
            </Property>
            <Property label="Onboarding requested:">
                {entity.customerApproval ? `On ${dateToString(entity.customerApproval.approvedAt)} by ${entity.customerApproval.user.display_name}` : "-"}
                {entity.request_note && entity.request_note.length > 0
                    ? 
                        <>&nbsp;(<LinkWithModal
                            content={
                                <> 
                                    <p><strong>Request message:</strong></p>
                                    <TextContent content={entity.request_note} />
                                </>
                            }
                            linkContent="note"
                        />)</>
                    : ""
                }
            </Property>
            {entity.serviceProviderApproval
                ?
                    <>
                        <Property label="Service provider approved:">
                            On {dateToString(entity.serviceProviderApproval.approvedAt)} by {entity.serviceProviderApproval.user.display_name}
                            {entity.response_note && entity.response_note.length > 0
                                ? 
                                    <>&nbsp;(<LinkWithModal
                                        content={
                                            <> 
                                                <p><strong>Service provider's response:</strong></p>
                                                <TextContent content={entity.response_note} />
                                            </>
                                        }
                                        linkContent="note"
                                    />)</>
                                : ""
                            }
                        </Property>
                    </>
                : <></>
            }
            {entity.serviceProviderRejection
                ?
                    <>
                        <Property label="Service provider rejected:">
                            On {dateToString(entity.serviceProviderRejection.rejectedAt)} by {entity.serviceProviderRejection.user.display_name}
                        </Property>
                        <Property label="Service provider's note:">
                            <TextContent content={entity.response_note ?? "Service provider shared no note."}/>
                        </Property>
                    </>
                : <></>
            }
            {!entity.serviceProviderRejection && entity.deleted
                ?
                    <>
                        <Property label="Ended on:">
                            {dateToString(entity.deleted.deletedAt)} by {entity.deleted.user.display_name}
                        </Property>
                    </>
                : <></>
            }

        </>
    );
}