// @ts-check

import React, { useRef, useState } from "react";
import { checkValue, setAndCheck, textField } from "../../helpers/others";
import { ActionButton, BulmaLevel, useErrors } from "react-base";
import toast from "react-hot-toast";

const SHORT_NAME_MAX_LENGTH = 20;
const SHORT_NAME_MIN_LENGTH = 2;
const ERROR_FIELD_SHORT_NAME = "short-name";
const ERROR_SHORT_NAME_TOO_LONG = "too-long";
const ERROR_SHORT_NAME_LENGTH_MESSAGE_TOO_LONG = `Short name must be at most ${SHORT_NAME_MAX_LENGTH} characters.`;
const ERROR_SHORT_NAME_TOO_SHORT = "too-short";
const ERROR_SHORT_NAME_LENGTH_MESSAGE_TOO_SHORT = `Short name must be at least ${SHORT_NAME_MIN_LENGTH} characters.`;

const NAME_MAX_LENGTH = 1000;
const NAME_MIN_LENGTH = 2;
const ERROR_FIELD_NAME = "name";
const ERROR_NAME_TOO_LONG = "too-long";
const ERROR_NAME_LENGTH_MESSAGE_TOO_LONG = `Name must be at most ${NAME_MAX_LENGTH} characters.`;
const ERROR_NAME_TOO_SHORT = "too-short";
const ERROR_NAME_LENGTH_MESSAGE_TOO_SHORT = `Name must be at least ${NAME_MIN_LENGTH} characters.`;

const ADDRESS_MAX_LENGTH = 1000;
const ERROR_FIELD_ADDRESS = "address";
const ERROR_ADDRESS_TOO_LONG = "too-long";
const ERROR_ADDRESS_LENGTH_MESSAGE_TOO_LONG = `Address must be at most ${ADDRESS_MAX_LENGTH} characters.`;

const COMPANY_NAME_MAX_LENGTH = 1000;
const ERROR_FIELD_COMPANY_NAME = "company-name";
const ERROR_COMPANY_NAME_TOO_LONG = "too-long";
const ERROR_COMPANY_NAME_LENGTH_MESSAGE_TOO_LONG =
    `Company name must be at most ${COMPANY_NAME_MAX_LENGTH} characters.`;

const ERROR_FIELD_EMAIL = 'email';
const ERROR_EMAIL_FORMAT = 'format';
const ERROR_EMAIL_FORMAT_MESSAGE_INVALID = 'Invalid email format.';

const PHONE_REGEX_STR = "^[+().\\d\\- ]*$";
const PHONE_REGEX = new RegExp(PHONE_REGEX_STR); // /^[\+()\.\d- ]*$/;
const ERROR_FIELD_PHONE = 'phone';
const ERROR_PHONE_FORMAT = 'format';
const ERROR_PHONE_FORMAT_MESSAGE_INVALID = 'Only +, (, ), ., -, and digits are allowed in phone numbers.';

const ERR_API = 'api';
const ERR_API_SUBMIT_FAILED = 'failure';

/**
 * @callback ManagedServiceProviderFormSubmitHandler 
 * @param {import("../../api").FemServiceProviderRead | null | undefined} original
 * @param {import("../../api").FemServiceProviderWrite} edited
 * @returns {Promise<import("../../api").FemApiErrorResponse | null>}
 */

/**
 * @typedef {Object} ManagedServiceProviderFormProps
 * @prop {import("../../api").FemServiceProviderRead} [entity]
 * @prop {ManagedServiceProviderFormSubmitHandler} handleSubmit
 * @prop {string} [submitButtonLabel = "Save"]
 */

/**
 *
 * @param {ManagedServiceProviderFormProps} param0
 * @returns {React.JSX.Element}
 */
export default function ManagedServiceProviderForm({
    entity,
    handleSubmit,
    submitButtonLabel = "Save",
}) {
    // const [managers, setManagers] = useState(serviceProvider?.managers ?? []);
    const [shortName, setShortName] = useState(entity?.short_name ?? '');
    const [name, setName] = useState(entity?.name ?? '');
    const [address, setAddress] = useState(entity?.address ?? '');
    const [companyName, setCompanyName] = useState(entity?.company_name ?? '');
    const [email, setEmail] = useState(entity?.email ?? '');
    const emailFieldRef = useRef(/** @type {HTMLInputElement | null} */ (null));
    const [phone, setPhone] = useState(entity?.phone ?? '');

    const [isValid, setIsValid] = useState(true);
    const {setError, resetError, renderErrors} = useErrors();

   /**
     * @typedef {Object} CheckValidityProps
     * @prop {string} [currentShortName = shortName]
     * @prop {string} [currentName = name]
     * @prop {string} [currentAddress = address]
     * @prop {string} [currentCompanyName = companyName]
     * @prop {string} [currentPhone = phone]
     * @prop {React.MutableRefObject<HTMLInputElement | null>} [currentEmailFieldRef = emailFieldRef]
     */
   /**
     * 
     * @param {CheckValidityProps} [param0 = {}]
     * @returns {boolean}
     */
    function checkValidity({
        currentShortName = shortName,
        currentName = name,
        currentAddress = address,
        currentCompanyName = companyName,
        currentPhone = phone,
        currentEmailFieldRef = emailFieldRef,
    } = {}) {
        const checks = [
            // shortName
            checkValue([
                {
                    errorCondition: currentShortName !== undefined && currentShortName.length > SHORT_NAME_MAX_LENGTH,
                    errFieldName: ERROR_FIELD_SHORT_NAME,
                    errConditionName: ERROR_SHORT_NAME_TOO_LONG,
                    errMessage: ERROR_SHORT_NAME_LENGTH_MESSAGE_TOO_LONG
                },
                {
                    errorCondition: currentShortName === undefined || currentShortName.length < SHORT_NAME_MIN_LENGTH,
                    errFieldName: ERROR_FIELD_SHORT_NAME,
                    errConditionName: ERROR_SHORT_NAME_TOO_SHORT,
                    errMessage: ERROR_SHORT_NAME_LENGTH_MESSAGE_TOO_SHORT
                }
            ], setError, resetError),

            // name
            checkValue([
                {
                    errorCondition: currentName !== undefined && currentName.length > NAME_MAX_LENGTH,
                    errFieldName: ERROR_FIELD_NAME,
                    errConditionName: ERROR_NAME_TOO_LONG,
                    errMessage: ERROR_NAME_LENGTH_MESSAGE_TOO_LONG
                },
                {
                    errorCondition: currentName === undefined || currentName.length < NAME_MIN_LENGTH,
                    errFieldName: ERROR_FIELD_NAME,
                    errConditionName: ERROR_NAME_TOO_SHORT,
                    errMessage: ERROR_NAME_LENGTH_MESSAGE_TOO_SHORT
                }
            ], setError, resetError),

            // email
            checkValue({
                successCondition: currentEmailFieldRef.current?.validity.valid ?? false,
                errFieldName: ERROR_FIELD_EMAIL,
                errConditionName: ERROR_EMAIL_FORMAT,
                errMessage: ERROR_EMAIL_FORMAT_MESSAGE_INVALID,
            }, setError, resetError),

            // address
            checkValue({
                errorCondition: currentAddress !== undefined && currentAddress.length > ADDRESS_MAX_LENGTH,
                errFieldName: ERROR_FIELD_ADDRESS,
                errConditionName: ERROR_ADDRESS_TOO_LONG,
                errMessage: ERROR_ADDRESS_LENGTH_MESSAGE_TOO_LONG
            }, setError, resetError),

            // companyName
            checkValue({
                errorCondition: currentCompanyName !== undefined && currentCompanyName.length > COMPANY_NAME_MAX_LENGTH,
                errFieldName: ERROR_FIELD_COMPANY_NAME,
                errConditionName: ERROR_COMPANY_NAME_TOO_LONG,
                errMessage: ERROR_COMPANY_NAME_LENGTH_MESSAGE_TOO_LONG
            }, setError, resetError),

            // phone
            checkValue({
                value: currentPhone,
                successCondition: PHONE_REGEX,
                errFieldName: ERROR_FIELD_PHONE,
                errConditionName: ERROR_PHONE_FORMAT,
                errMessage: ERROR_PHONE_FORMAT_MESSAGE_INVALID,
            }, setError, resetError),
        ];
        const validity = (checks.find((v) => v === false) === undefined);
        setIsValid(validity);
        return validity;
    }

    function anyChange() {
        resetError(ERR_API, ERR_API_SUBMIT_FAILED);
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleFormButtonClick(cb) {
        if (checkValidity()) {
            /** @type {import("../../api").FemServiceProviderWrite} */
            const newEntity = {
                short_name: shortName.trim(),
                name: name.trim(),
                address: address.trim(),
                company_name: companyName.trim(),
                email: email.trim(),
                phone: phone.trim(),
            }

            const response = await handleSubmit(entity, newEntity);
            cb();
            if (response !== null) {
                const errorResponse = /** @type {import("../../api").FemApiErrorResponse} */ (response);
                toast.error("Error saving.")
                setError(ERR_API, ERR_API_SUBMIT_FAILED, errorResponse.errors);
                return;
            }
        } else {
            cb();
        }
    }

    return (
        <form className="has-text-left">
            {textField({
                label: "Short name",
                placeholder: `A short name or abbreviation - max ${SHORT_NAME_MAX_LENGTH} characters.`,
                value: shortName,
                onChange: (event) =>
                    setAndCheck(event.target.value, setShortName, "currentShortName", checkValidity, anyChange),
            })}

            {textField({
                label: "Name",
                placeholder: "Service provider name",
                value: name,
                onChange: (event) =>
                    setAndCheck(event.target.value, setName, "currentName", checkValidity, anyChange),
            })}

            {textField({
                label: "Company Name",
                placeholder: "Official company name",
                value: companyName,
                onChange: (event) =>
                    setAndCheck(event.target.value, setCompanyName, "currentCompanyName", checkValidity, anyChange),
            })}

            {textField({
                ref: emailFieldRef,
                type: "email",
                label: "Email",
                placeholder: "Email",
                value: email,
                onChange: (event) =>
                    setAndCheck(event.target.value, setEmail, undefined, checkValidity, anyChange),
            })}

            {textField({
                label: "Address",
                placeholder: "Address",
                value: address,
                onChange: (event) =>
                    setAndCheck(event.target.value, setAddress, "currentAddress", checkValidity, anyChange),
            })}

            {textField({
                type: "tel",
                label: "Phone",
                placeholder: "Phone",
                value: phone,
                pattern: PHONE_REGEX_STR, // "^\+?[\d ]*(?:\([\d ]+\))?[\d*- .]*\d+$",
                onChange: (event) =>
                    setAndCheck(event.target.value, setPhone, "currentPhone", checkValidity, anyChange),
            })}

            {renderErrors()}

            <BulmaLevel
                right={[
                    <ActionButton disabled={!isValid} onClick={handleFormButtonClick}>{submitButtonLabel}</ActionButton>
                ]}
            />

        </form>
    );
}