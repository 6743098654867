// @ts-check

import React, { useState } from "react";
import { checkValue, field, setAndCheck, textAreaField } from "../helpers/others";
import { ActionButton, BulmaLevel, useApi, useErrors } from "react-base";
import toast from "react-hot-toast";
import SearchInput from "./general-purpose/search-input";
import { serviceProviderSearchRequest } from "../api";

const REQUEST_NOTE_MAX_LENGTH = 2000;
const ERROR_FIELD_REQUEST_NOTE = "name";
const ERROR_REQUEST_NOTE_TOO_LONG = "too-long";
const ERROR_REQUEST_NOTE_LENGTH_MESSAGE_TOO_LONG =
    `Request message must be at most ${REQUEST_NOTE_MAX_LENGTH} characters.`;

const ERROR_FIELD_SERVICE_PROVIDER = "service-provider";
const ERROR_SERVICE_PROVIDER_REQUIRED = "required";
const ERROR_SERVICE_PROVIDER_NOT_PRESENT = `You must choose a service provider to request from.`;

const ERR_API = 'api';
const ERR_API_SUBMIT_FAILED = 'failure';

/**
 * @callback ServiceProviderEquineRelationshipRequestFormSubmitHandler 
 * @param {import("../api").FemServiceProviderEquineRelationshipRead | null | undefined} original
 * @param {import("../api").FemServiceProviderEquineRelationshipRequestWrite} edited
 * @returns {Promise<import("../api").FemApiErrorResponse | null>}
 */

/**
 * @typedef {Object} ServiceProviderEquineRelationshipRequestFormProps
 * @prop {number} equineId
 * @prop {import("../api").FemServiceProviderEquineRelationshipRead} [entity]
 * @prop {ServiceProviderEquineRelationshipRequestFormSubmitHandler} handleSubmit
 * @prop {string} [submitButtonLabel = "Request"]
 */

/**
 *
 * @param {ServiceProviderEquineRelationshipRequestFormProps} param0
 * @returns {React.JSX.Element}
 */
export default function ServiceProviderEquineRelationshipRequestForm({
    equineId,
    entity,
    handleSubmit,
    submitButtonLabel = "Save",
}) {
    const fetchFromApi = useApi();

    const [requestNote, setRequestNote] = useState(entity?.request_note ?? '');
    const [serviceProvider, setServiceProvider] =
        useState(/** @type {import("../api").FemServiceProviderMentionRead | null} */ (null))

    const [isValid, setIsValid] = useState(true);
    const {setError, resetError, renderErrors} = useErrors();

   /**
     * @typedef {Object} CheckValidityProps
     * @prop {string} [currentRequestNote = requestNote]
     */
   /**
     * 
     * @param {CheckValidityProps} [param0 = {}]
     * @returns {boolean}
     */
    function checkValidity({
        currentRequestNote = requestNote,
    } = {}) {
        const checks = [
            // request note
            checkValue({
                errorCondition: currentRequestNote !== undefined && currentRequestNote.length > REQUEST_NOTE_MAX_LENGTH,
                errFieldName: ERROR_FIELD_REQUEST_NOTE,
                errConditionName: ERROR_REQUEST_NOTE_TOO_LONG,
                errMessage: ERROR_REQUEST_NOTE_LENGTH_MESSAGE_TOO_LONG
            }, setError, resetError),

            // service provider
            checkValue({
                errorCondition: serviceProvider === null || serviceProvider === undefined,
                errFieldName: ERROR_FIELD_SERVICE_PROVIDER,
                errConditionName: ERROR_SERVICE_PROVIDER_NOT_PRESENT,
                errMessage: ERROR_SERVICE_PROVIDER_REQUIRED,
            }, setError, resetError),
        ];
        const validity = (checks.find((v) => v === false) === undefined);
        setIsValid(validity);
        return validity;
    }

    function anyChange() {
        resetError(ERR_API, ERR_API_SUBMIT_FAILED);
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function handleFormButtonClick(cb) {
        if (checkValidity()) {
            let response;

            // this will always hold, as checkValidity should fail otherwise
            if (serviceProvider) {
                const newEntity = /** @type {import("../api").FemServiceProviderEquineRelationshipRequestWrite} */ ({
                    equine_id: equineId,
                    service_provider_id: serviceProvider.id,
                    request_note: requestNote,
                });
                response = await handleSubmit(entity, newEntity);

                cb();
                if (response !== null) {
                    const errorResponse = /** @type {import("../api").FemApiErrorResponse} */ (response);
                    toast.error("Request failed.")
                    setError(ERR_API, ERR_API_SUBMIT_FAILED, errorResponse.errors);
                    return;
                }
            }
        } else {
            cb();
        }
    }

    /**
     * 
     * @param {string} searchText 
     * @returns {Promise<Array<import("../api").FemServiceProviderMentionRead>>}
     */
    async function handleServiceProviderSearch(searchText) {
        /** @type {import("react-base").ApiResponse} */
        const response = await fetchFromApi(serviceProviderSearchRequest({nameContains: searchText}));
        if (response.isOk) {
            return /** @type {import("../api").FemServiceProviderSuccessfulSearchResponse} */ (response.data).collection;
        } else {
            toast("No service providers found.");
            return [];
        }
    }

    return (
        <form className="has-text-left">
            {field({
                label: "Service provider",
                children: 
                    <SearchInput
                        onSearch={handleServiceProviderSearch}
                        entityToLabel={(serviceProvider) => serviceProvider.name}
                        onSelect={setServiceProvider}
                        value={serviceProvider ?? null}
                    />
            })}

            {textAreaField({
                label: "Request message",
                placeholder: "Message to the service provider about this request",
                value: requestNote,
                onChange: (event) =>
                    setAndCheck(event.target.value, setRequestNote, "currentRequestNote", checkValidity, anyChange),
            })}

            {renderErrors()}

            <BulmaLevel
                right={[
                    <ActionButton disabled={!isValid} onClick={handleFormButtonClick}>{submitButtonLabel}</ActionButton>
                ]}
            />

        </form>
    );
}