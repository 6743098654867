// @ts-check

import React, { useState } from "react";
import { BulmaLevel } from "react-base";
import ApiSearchInput from "./general-purpose/api-search-input";
import { usersSearchRequest } from "../api";

/**
 * @typedef {Object} UserSearchFormProps
 * @prop {(selectedValue: {invitee: import("../api").FemUserMention}) => Promise<import("../api").FemApiErrorResponse | null>} onSelect
 */
/**
 * 
 * @param {UserSearchFormProps} param0 
 * @returns 
 */
export default function UserSearchForm({
    onSelect
}) {
    const [selectedUser, setSelectedUser] = useState(/** @type {import("../api").FemUserMention | null} */ (null));
    return (
        <>
            <ApiSearchInput
                entityToLabel={(userMention) => userMention.person.display_name}
                searchRequest={(searchText) => usersSearchRequest({searchText})}
                value={/** @type {import("../api").FemUserMention | null} */ (null)}
                onSelect={setSelectedUser}
            />
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <BulmaLevel
                right={[
                    <button
                        className="button"
                        disabled={selectedUser === null}
                        onClick={() => {
                            if (selectedUser !== null) {
                                onSelect({invitee: selectedUser});
                            }
                        }}
                    >Connect</button>
               ]}
            />
        </>
    );
}